import React from 'react';
import classnames from 'classnames';

type Props = {
  value: number | string;
  variant?: 'success' | 'danger' | 'warning';
};

const Badge = ({ value, variant = 'danger' }: Props) => {
  return <span className={classnames('badge', 'body-2', variant)}>{value}</span>;
};

export default Badge;
