import classNames from 'classnames';
import React from 'react';

interface Props {
  icon: React.ReactNode;
  message: string;
  type?: 'success' | 'warning' | 'danger';
  size?: 'small' | 'medium';
}

const Alert = ({ icon, message, type, size = 'small' }: Props) => {
  return (
    <div
      className={classNames('alert', {
        success: type === 'success',
        warning: type === 'warning',
        danger: type === 'danger',
        'body-2': size === 'small',
        body: size === 'medium'
      })}
    >
      {icon}
      <span>{message}</span>
    </div>
  );
};

export default Alert;
