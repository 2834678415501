import { toZonedTime, format } from 'date-fns-tz';
import { DateTime } from 'luxon';

export const utcDate = (dateTime: Date | string | number) => {
  return toZonedTime(dateTime, 'UTC');
};

export function removeTimeZone(date: Date) {
  const pattern = "yyyy-MM-dd'T'HH:mm:ss";
  return format(date, pattern);
}

export function onlyDate(date: string) {
  return date.slice(0, 10);
}

export function getTime(datetime: string) {
  return datetime.substring(11, 16);
}

export const convertToTimeZone = (dateStr: string, timezone: string) => {
  const date = DateTime.fromISO(dateStr, { zone: 'utc' }).setZone(timezone);
  return date.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");
};

export const dateToIsoString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const changeTimezoneAndConvertToUTC = (date: Date, timezone: string) => {
  const dateString = dateToIsoString(date);
  const dateWithTimezone = DateTime.fromISO(dateString, { zone: timezone });
  const dateInUTC = dateWithTimezone.toUTC();

  return dateInUTC;
};
