/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { changeTimezoneAndConvertToUTC } from 'helpers/date';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';
import endpoints from 'constants/endpoints';

const CreationEventModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    const { data } = formHandler;
    const { id, timezone } = data.venue;
    const processedData = {
      ...data,
      venueId: id,
      allowRegistrationsAt: changeTimezoneAndConvertToUTC(data.allowRegistrationsAt, timezone).toISO(),
      heldOn: changeTimezoneAndConvertToUTC(data.heldOn, timezone).toISO(),
      reportTimeAt: data.reportTimeAt && changeTimezoneAndConvertToUTC(data.reportTimeAt, timezone).toISO(),
      employeeCapacity: data.employeeCapacity ? +data.employeeCapacity : null,
      buffer: data.buffer ? +data.buffer : null,
      automateRoster: data.automateRoster,
      templateId: +data.templateId ? +data.templateId : null
    };

    delete processedData.venue;
    onClose(processedData);
  };

  useEffect(() => {
    const venueId = formHandler.data.venue.id;

    const getTemplates = async () => {
      const resp = api.fetchData(endpoints.SEARCH_TEMPLATES.get({ venueId }));
      return resp;
    };

    if (venueId) {
      getTemplates().then(res => {
        const data = { ...formHandler.data, templateId: '' };
        const newSchema = {
          ...formHandler.schema,
          templateId: { ...formHandler.schema.templateId, options: res.items }
        };
        formHandler.setFullSchema(newSchema, data);
      });
    }
  }, [formHandler.data.venue.id]);

  return (
    <Base
      header="Create event"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <div className="modal-create-event">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationEventModal;
