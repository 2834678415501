import React, { useEffect, useState } from 'react';
import { Alert, Button, DynamicForm } from 'components';
import { Warning2 } from 'iconsax-react';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const CreationStandPositionModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const [isExceeded, setIsExceeded] = useState(false);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({ position: +formHandler.data.position, total: formHandler.data.total });
  };

  useEffect(() => {
    formHandler.setSchema({
      ...schema,
      position: {
        ...schema.position,
        disabled: false,
        options: [...params.positions.map((item: any) => ({ label: item.name, value: item.id }))]
      }
    });
  }, []);

  useEffect(() => {
    const diff = params.positionCapacity - formHandler.data.total;

    if (Math.sign(diff) === -1) {
      setIsExceeded(true);
    } else {
      setIsExceeded(false);
    }
  }, [formHandler.data.total, params.positionCapacity]);

  return (
    <Base
      header="Create position"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <div className="modal-create-position">
        <DynamicForm handler={formHandler} />
        {isExceeded && (
          <Alert
            type="warning"
            icon={<Warning2 size={20} />}
            message="The total number of positions exceeds the number of positions available"
          />
        )}
      </div>
    </Base>
  );
};

export default CreationStandPositionModal;
