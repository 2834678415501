import React, { useEffect, useState, useCallback } from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AlternativeTable, Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import { columns } from './columns';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import classNames from 'classnames';

const TerminationWarningsModal = ({ closing, onClose, params: { id } }: Props) => {
  const api = useApi();
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    const res = await api.fetchData(endpoints.GET_TERMINATION_WARNINGS.get({ id }));
    return res;
  }, []);

  useEffect(() => {
    fetchData().then(res => {
      if (res) {
        setData(res.data);
      }
    });
  }, [fetchData]);

  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <Base
      header="Termination Warnings"
      closing={closing}
      onClose={() => onClose(false)}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose(false)} />}
    >
      <div className="termination-warnings">
        <div
          className={classNames('table-container', {
            'is-loading': api.isLoading
          })}
        >
          {api.isLoading ? (
            <div>Loading...</div>
          ) : (
            <AlternativeTable className="custom-table" config={tableInstance} layout="auto" />
          )}
        </div>
      </div>
    </Base>
  );
};

export default TerminationWarningsModal;
