import React, { useEffect, useState } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import { salariedSchema, hourlySchema, contractorSchema } from './schema';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { PayrollAdminTypes, PayrollSchedule, PayrollAdmineEnum } from 'types/payrollAdmin';
import { onlyDate, removeTimeZone, utcDate } from 'helpers/date';

const payrollActions: Record<PayrollAdminTypes, any> = {
  [PayrollAdminTypes.SALARIED]: {
    schema: salariedSchema
  },
  [PayrollAdminTypes.HOURLY]: {
    schema: hourlySchema
  },
  [PayrollAdminTypes.CONTRACTOR]: {
    schema: contractorSchema
  }
};

const EditionAdminPayrollModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(salariedSchema);
  const api = useApi();
  const [nextPaydays, setNextPaydays] = useState<any[]>([]);
  const isContractor = params.type === PayrollAdminTypes.CONTRACTOR;

  const handleSubmit = async () => {
    formHandler.setIsSubmitting(true);
    const { name, payday, periodStart, periodEnd } = formHandler.data;

    onClose({
      id: params.row.id,
      name: name,
      payday: payday,
      period_start: periodStart,
      period_end: periodEnd
    });
  };

  const transformNextPaydays = (nextPaydays: PayrollSchedule[]) => {
    return nextPaydays.map(item => utcDate(item.payday));
  };

  const getNextPaydays = async (type: string) => {
    const response = await api.fetchData(endpoints.GET_NEXT_PAYDAY.get({ type }));
    setNextPaydays(response.nextPaydays);
  };

  useEffect(() => {
    const { schema } = payrollActions[params.type as PayrollAdminTypes];

    if (!isContractor) {
      getNextPaydays(PayrollAdmineEnum[params.type as PayrollAdminTypes]);
    }

    formHandler.changeSchema(schema, {
      type: params.type,
      name: params.row.name,
      payday: utcDate(params.row.payday),
      periodStart: utcDate(params.row.period_start),
      periodEnd: utcDate(params.row.period_end)
    });
  }, [params.type]);

  useEffect(() => {
    if (nextPaydays.length > 0 && !isContractor) {
      const updatedSchema = {
        ...formHandler.schema,
        payday: {
          ...formHandler.schema.payday,
          allowedDates: transformNextPaydays([...nextPaydays]),
          disabled: false
        }
      };

      formHandler.setSchema(updatedSchema);
    }
  }, [nextPaydays]);

  useEffect(() => {
    if (!isContractor) {
      if (!formHandler.data.payday) return;

      const item = nextPaydays.find(item => item.payday === onlyDate(removeTimeZone(formHandler.data.payday)));

      if (item) {
        formHandler.setFullForm({
          ...formHandler.data,
          periodStart: utcDate(item.period_start),
          periodEnd: utcDate(item.period_end)
        });
      }
    }
  }, [formHandler.data.payday]);

  return (
    <Base
      header="Edit admin payroll"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <div className="modal-edit-payroll">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default EditionAdminPayrollModal;
