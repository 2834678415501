/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditTemplatePosition = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose(formHandler.data);
  };

  const handleRemove = () => {
    onClose({ remove: true });
  };

  useEffect(() => {
    formHandler.setFullForm({
      position: String(params.position.id),
      total: params.total
    });
  }, [formHandler.schema]);

  useEffect(() => {
    formHandler.setSchema({
      ...schema,
      position: {
        ...schema.position,
        defaultLabel: params.position.name
      }
    });
  }, []);

  return (
    <Base
      header="Edit Position"
      className="edit-template-position"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <div className="buttons">
          <Button
            text="Remove"
            variant="secondary"
            disabled={!formHandler.valid || formHandler.isSubmitting}
            onClick={handleRemove}
          />
          <Button
            text="Save Changes"
            disabled={!formHandler.valid || formHandler.isSubmitting}
            onClick={handleSubmit}
          />
        </div>
      }
    >
      <div>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default EditTemplatePosition;
