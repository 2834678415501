import joi from 'joi';
import { moneyMask } from 'constants/masks';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  totalTips: {
    value: 0,
    label: 'Total tips',
    input: InputsEnum.MASKED,
    mask: moneyMask,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.INVALID_NUMBER
    })
  }
};
