import React from 'react';
import classnames from 'classnames';
import { alertBannerType } from 'types/alertBanner';

type Props = {
  message: string;
  type: alertBannerType;
};

const AlertBanner = ({ message, type }: Props) => (
  <div className={classnames('alert-banner', 'body-1', type)}>
    <div>{message}</div>
  </div>
);

export default AlertBanner;
