import joi from 'joi';
import formErrors from 'constants/formErrors';
import { allowedFileTypes } from 'constants/fileTypes';
import { emptyRichText, excludeTagsRegex } from 'constants/regex';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  content: {
    value: '',
    label: 'Content',
    limit: 100,
    input: InputsEnum.RICH_TEXT,
    joi: joi
      .string()
      .regex(RegExp(emptyRichText), { invert: true })
      .replace(excludeTagsRegex, '')
      .max(600)
      .required()
      .messages({
        'string.pattern.invert.base': formErrors.REQUIRED,
        'string.max': formErrors.INVALID_LENGTH
      })
  },
  state: {
    value: '',
    label: 'State',
    input: InputsEnum.SELECT,
    disabled: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  files: {
    value: {},
    label: 'File',
    disabled: false,
    input: InputsEnum.INPUT_FILE,
    joi: joi
      .object({
        name: joi.string(),
        type: joi
          .string()
          .valid(...allowedFileTypes)
          .required(),
        size: joi.number()
      })
      .empty({})
      .messages({
        'any.required': formErrors.REQUIRED,
        'any.only': formErrors.INVALID_EXTENSION
      })
  },
  active: {
    value: false,
    label: 'Active',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  }
};
