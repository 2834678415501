import React from 'react';
import Tag from '../tag';

type Props = {
  label: string;
  url: string;
};

const OpenUrl = ({ label, url }: Props) => {
  const handleOpenUrl = () => {
    window.open(url);
  };

  return (
    <Tag variant="primary" onClick={handleOpenUrl}>
      {label}
    </Tag>
  );
};

export default OpenUrl;
