import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import { ACCESS_LEVELS } from 'constants/accessLevels';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 30,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  description: {
    value: '',
    label: 'Description',
    limit: 150,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  levelAccess: {
    value: '',
    label: 'Level of Access',
    input: InputsEnum.SELECT,
    options: [
      { label: 'Level 1', value: ACCESS_LEVELS.LEVEL_1 },
      { label: 'Level 2', value: ACCESS_LEVELS.LEVEL_2 },
      { label: 'Level 3', value: ACCESS_LEVELS.LEVEL_3 }
    ],
    disabled: false,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
