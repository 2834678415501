import joi from 'joi';
import endpoints from 'constants/endpoints';
import formErrors from 'constants/formErrors';
import { DateInputTypes, InputsEnum } from 'types/dynamicInputs';

export default {
  eventId: {
    value: '',
    label: 'Event',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_EVENT,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  periodStart: {
    value: '',
    label: 'Period start',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  },
  periodEnd: {
    value: '',
    label: 'Period end',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi
      .date()
      .required()
      .min(joi.ref('periodStart'))
      .messages({
        'date.empty': formErrors.REQUIRED,
        'date.base': formErrors.REQUIRED,
        'any.ref': formErrors.DATE_MUST_BE_GREATER_OR_EQUAL_THAN('period start'),
        'date.min': formErrors.DATE_MUST_BE_GREATER_OR_EQUAL_THAN('period start')
      })
  },
  payday: {
    value: '',
    label: 'Payday',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi
      .date()
      .required()
      .greater(joi.ref('periodEnd'))
      .messages({
        'date.empty': formErrors.REQUIRED,
        'date.base': formErrors.REQUIRED,
        'any.ref': formErrors.DATE_MUST_BE_GREATER('period end'),
        'date.greater': formErrors.DATE_MUST_BE_GREATER('period end')
      })
  }
};
