import joi from 'joi';
import formErrors from 'constants/formErrors';
import { DateInputTypes, InputsEnum } from 'types/dynamicInputs';

export default {
  endedAt: {
    value: '',
    label: 'Datetime',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE_TIME,
    required: true,
    joi: joi.date().iso().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED,
      'date.format': formErrors.INVALID_DATE
    })
  }
};
