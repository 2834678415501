import React, { useEffect } from 'react';
import { useSelector, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import useWindowSize from '@revolt-digital/use-window-size';
import { Toaster } from 'sonner';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Capacitor } from '@capacitor/core';
import { SafeAreaController } from '@aashu-dubey/capacitor-statusbar-safe-area';
import RefsProvider from 'hooks/useRefs';
import ModalProvider from 'hooks/useModal';
import JobsProvider from 'hooks/useJobs';
import { persistor, store } from 'store';
import { SideMenu, Toolbar } from 'components';
import { RootState } from 'types/store';
import { Platform } from 'types/platforms';
import Router from './router';

const injectSafeAreaVariables = () => {
  SafeAreaController.injectCSSVariables();
};

const customHistory = createBrowserHistory();

const Content = () => {
  const windowSize = useWindowSize();
  const { isLogged } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [windowSize.height]);

  useEffect(() => {
    if (Capacitor.getPlatform() === Platform.IOS) {
      injectSafeAreaVariables();
    }
  }, []);

  return (
    <HistoryRouter history={customHistory as any}>
      <Toaster theme="dark" position="top-center" />
      <div className="app">
        {isLogged && (
          <>
            <Toolbar />
            <SideMenu />
          </>
        )}
        <Router />
      </div>
    </HistoryRouter>
  );
};

const App = () => (
  <RefsProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ModalProvider>
          <JobsProvider>
            <Content />
          </JobsProvider>
        </ModalProvider>
      </PersistGate>
    </Provider>
  </RefsProvider>
);

export default App;
