import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const ssnMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

//US phone number with the format "+1 (XXX) XXX-XXXX".
export const phoneNumberMask = [
  '+',
  '1',
  ' ',
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const moneyMask = createNumberMask({
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 6,
  allowNegative: false,
  allowLeadingZeroes: false
});

export const percentageMask = createNumberMask({
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 3,
  allowNegative: false,
  allowLeadingZeroes: false
});

export const hoursMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  integerLimit: 3,
  allowNegative: false,
  allowLeadingZeroes: false
});
