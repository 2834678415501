import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  checkIn: {
    value: '',
    label: 'Check In',
    input: InputsEnum.DATE_TIME_PICKER,
    required: false,
    joi: joi.optional()
  },
  checkOut: {
    value: '',
    label: 'Check Out',
    input: InputsEnum.DATE_TIME_PICKER,
    required: false,
    joi: joi.optional()
  }
};
