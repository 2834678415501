import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  backgroundCheck: {
    value: 'ALL',
    label: 'Background Check Completed',
    input: InputsEnum.SELECT,
    options: [
      { label: 'All', value: 'ALL' },
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' }
    ],
    disabled: false,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
