import React from 'react';

type Props = {
  value: string | number;
};

const TextCopy = ({ value }: Props) => {
  return <div className="primary-text body strong">{value ? value : '-'}</div>;
};

export default TextCopy;
