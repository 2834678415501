import React from 'react';
import { useModal } from 'hooks/useModal';
import { Tag } from 'components';
import { ModalsList } from 'types/modals';
import { ViewList } from 'types/visualizer';

type Props = {
  value: {
    id: string | number;
    name: string;
  };
};

const Account = ({ value }: Props) => {
  const modal = useModal();

  const handleClick = () => {
    modal.open(ModalsList.VIEW_MODAL, () => {}, { viewer: ViewList.ACCOUNT, id: value.id });
  };

  return <Tag onClick={handleClick}>{value.name}</Tag>;
};

export default Account;
