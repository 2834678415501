import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';
import { UniformStatusTypes } from 'types/uniform';

export default {
  uniformStatus: {
    value: '',
    label: 'Uniform Approval',
    input: InputsEnum.SELECT,
    options: [
      { label: 'Approved', value: UniformStatusTypes.APPROVED },
      { label: 'Rejected', value: UniformStatusTypes.REJECTED }
    ],
    disabled: false,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
