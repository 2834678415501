import React from 'react';
import { OpenUrl } from 'components';

const NotionLink = ({ value }: { value: string }) => {
  if (!value) {
    return null;
  }

  return <OpenUrl label="Notion URL" url={value} />;
};

export default NotionLink;
