import joi from 'joi';
import formErrors from 'constants/formErrors';
// import endpoints from 'constants/endpoints';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  leaders: {
    value: [],
    label: 'Site Leader',
    input: InputsEnum.ASSIGN_LEADER_INPUT,
    limit: 3,
    avoidFirstLoad: true,
    joi: joi.array().messages({
      'array.base': formErrors.REQUIRED,
      'array.min': formErrors.REQUIRED
    })
  }
};
