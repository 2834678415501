import React from 'react';

type Props = {
  children: React.ReactNode;
};

const TextWithBreaks = ({ children }: Props) => {
  return <span className="text-with-breaks">{children}</span>;
};

export default TextWithBreaks;
