import React from 'react';
import TabsSwitcher from '../tabsSwitcher';
import Summary from './summary';
import EventStats from './tabs/event';
import StandStats from './tabs/stand';
import PositionStats from './tabs/position';

type Props = {
  employeeId: number;
};

const EmployeeStats = ({ employeeId }: Props) => {
  return (
    <div className="employee-stats">
      <div className="stats-container">
        <Summary employeeId={employeeId} />
        <div className="tabs-container">
          <TabsSwitcher
            defaultIndex={0}
            data={[
              {
                title: 'Stats per Event',
                component: <EventStats employeeId={employeeId} />
              },
              {
                title: 'Stand Stats',
                component: <StandStats employeeId={employeeId} />
              },
              {
                title: 'Position Stats',
                component: <PositionStats employeeId={employeeId} />
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeStats;
