import { UnknownAction } from 'redux';

/** Constants **/

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const SET_TOKEN = 'SET_TOKEN';
export const CONTRACT_SIDE_MENU = 'CONTRACT_SIDE_MENU';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const SET_CHANNEL_URL = 'SET_CHANNEL_URL';
export const SET_HAS_CHATS = 'SET_HAS_CHATS';
export const TOGGLE_COLUMN = 'TOGGLE_COLUMN';
export const INITIALIZE_TABLE = 'INITIALIZE_TABLE';
export const RESET_TABLES = 'RESET_TABLES';

/** States **/

export interface GeneralState {
  mobileSideMenuOpen: boolean;
}

export interface UserState {
  isLogged: boolean;
  token: string;
  refreshToken: string;
  data: any;
  channelUrl: string;
  hasChats: boolean;
}

interface TableColumn {
  id: string;
  header: string;
  type: string;
  width: string;
  sortable: boolean;
  hidden?: boolean;
}
interface TableState {
  columns: TableColumn[];
}
export interface TablesState {
  [key: string]: TableState;
}

export interface RootState {
  user: UserState;
  general: GeneralState;
  tables: TablesState;
  channelUrl: string;
  hasChats: boolean;
}

/** Actions **/

interface SetChannelUrlAction extends UnknownAction {
  type: typeof SET_CHANNEL_URL;
  channelUrl: string | undefined;
}

interface SetHasChatsAction extends UnknownAction {
  type: typeof SET_HAS_CHATS;
  hasChats: boolean;
}

interface ContractSideMenuAction extends UnknownAction {
  type: typeof CONTRACT_SIDE_MENU;
}

interface ToggleSideMenuAction extends UnknownAction {
  type: typeof TOGGLE_SIDE_MENU;
}

interface LogInAction extends UnknownAction {
  type: typeof LOG_IN;
  data: any;
  token: string;
  refreshToken: string;
}

interface LogOutAction extends UnknownAction {
  type: typeof LOG_OUT;
}

interface SetTokenAction extends UnknownAction {
  type: typeof SET_TOKEN;
  token: string;
}

interface ToggleColumnAction extends UnknownAction {
  type: typeof TOGGLE_COLUMN;
  payload: {
    tableName: string;
    columnId: string;
  };
}

interface InitializeTableAction extends UnknownAction {
  type: typeof INITIALIZE_TABLE;
  payload: {
    tableName: string;
    columns: TableColumn[];
  };
}

export type UserActionTypes = LogInAction | LogOutAction | SetTokenAction | SetChannelUrlAction | SetHasChatsAction;
export type GeneralActionTypes = ToggleSideMenuAction | ContractSideMenuAction;
export type TablesActionTypes = ToggleColumnAction;
export type AppActionTypes = InitializeTableAction;
