import React from 'react';
import { Badge } from 'components';

type Props = {
  value: string;
};

type Variant = 'success' | 'danger' | 'warning';

const attendanceStatuses = {
  NO_RESPONSE: 'warning',
  CONFIRM_ATTENDANCE: 'success',
  CONFIRM_NONATTENDANCE: 'danger'
};

const ConfirmationStatus = ({ value }: Props) => {
  return <Badge variant={(attendanceStatuses as any)[value] as Variant} value={value} />;
};

export default ConfirmationStatus;
