import React from 'react';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';

const ViewVideo = ({ closing, onClose, params }: Props) => {
  const url = params.videoUrl;

  return (
    <Base
      header="Presentation Video"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose()} />}
    >
      <div className="video-container">
        <video className="responsive-video" controls>
          <source src={url} type="video/mp4" />
          <track default kind="captions" srcLang="en" src="path/to/captions.vtt" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Base>
  );
};

export default ViewVideo;
