/* eslint-disable @typescript-eslint/no-unused-vars */
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import TagsInput from 'react-tagsinput';
import { TagsInputProps } from 'types/dynamicInputs';

const TagsInputComponent = ({
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  required,
  disabled,
  pristine,
  error
}: TagsInputProps) => {
  const handleChange = (newTags: string[]) => {
    onChange(newTags);
    onBlur && onBlur();
  };

  return (
    <div className="tags-input">
      <div className={classnames('wrapper', { error: !pristine && error })}>
        {!!label && (
          <label className="label">
            {label}
            {required ? ' *' : ''}
          </label>
        )}
        <TagsInput value={value} onChange={handleChange} inputProps={{ placeholder, disabled }} />
      </div>
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default TagsInputComponent;
