import React from 'react';
import classnames from 'classnames';

type Props = {
  value: boolean;
};

const GoodOrBad = ({ value }: Props) => {
  return <div className={classnames('indicator', 'body-2', value ? 'success' : 'danger')} />;
};

export default GoodOrBad;
