import joi from 'joi';
import formErrors from 'constants/formErrors';
import endpoints from 'constants/endpoints';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  venue: {
    value: '',
    label: 'Venue',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_VENUE,
    joi: joi.alternatives().try(
      joi.string().required().empty('').messages({
        'string.empty': formErrors.REQUIRED,
        'any.required': formErrors.REQUIRED
      }),
      joi.object().unknown(true)
    )
  }
};
