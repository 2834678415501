import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import endpoints from 'constants/endpoints';
import { timeZones } from 'constants/timeZones';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionVenueModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  useEffect(() => {
    api.fetchData(endpoints.GET_VENUE.get(params));
  }, []);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    const { lineOne, lineTwo, state, postalCode } = formHandler.data;
    onClose({
      ...formHandler.data,
      poolableTipPercentage: +formHandler.data.poolableTipPercentage.replace('%', ''),
      positions: formHandler.data.positions.map((item: any) => item.value),
      address: {
        lineOne,
        lineTwo,
        state,
        postalCode
      }
    });
  };

  useEffect(() => {
    if (api.loaded) {
      const data = {
        name: api.response.data.name,
        isActive: !!api.response.data.is_active,
        accountId: api.response.data.account.id,
        lineOne: api.response.data.address.line_one,
        lineTwo: api.response.data.address.line_two,
        postalCode: api.response.data.address.postal_code,
        timezone: api.response.data.timezone,
        poolableTipPercentage: `${api.response.data.poolable_tip_percentage || 0}%`,
        alcoholCertification: api.response.data.alcohol_cert_required,
        uniformRequired: api.response.data.uniform_required,
        positions: api.response.data.positions,
        siteUrl: api.response.data.site_url
      };
      formHandler.setFullForm(data);
    }
  }, [api.loaded, formHandler.schema]);

  useEffect(() => {
    if (api.loaded) {
      formHandler.setSchema({
        ...schema,
        accountId: {
          ...schema.accountId,
          defaultLabel: api.response.data.account.name
        },
        timezone: {
          ...schema.timezone,
          options: [...timeZones.map((item: any) => ({ label: item, value: item }))]
        }
      });
    }
  }, [api.loaded]);

  return (
    <Base
      header="Edit venue"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionVenueModal;
