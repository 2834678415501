import React from 'react';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { extendedDateFormat } from 'constants/dates';
import { localTimezone } from 'constants/timeZones';
import { utcDate } from 'helpers/date';

type Props = {
  value: string;
  timezone?: string;
};

const DateFormat = ({ value, timezone }: Props) => {
  if (!value) {
    return <span>no date</span>;
  }

  if (timezone === localTimezone) {
    return <span>{format(new Date(value), extendedDateFormat)}</span>;
  }

  if (timezone) {
    const zonedDate = toZonedTime(value, timezone);
    return <span>{format(zonedDate, extendedDateFormat)}</span>;
  }

  return <span>{format(utcDate(value), extendedDateFormat)}</span>;
};

export default DateFormat;
