import React from 'react';
import { Button, EmployeeStats } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';

const StatsModal = ({ closing, onClose, params }: Props) => {
  return (
    <Base
      header="Employee Stats"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose(false)} />}
    >
      <EmployeeStats employeeId={Number(params?.employeeId)} />
    </Base>
  );
};

export default StatsModal;
