import React from 'react';

type Props = {
  children: React.ReactNode;
  value: string | string[];
  fixed?: boolean;
  disabled?: boolean;
  variant?: 'dark' | 'primary';
  hidden?: boolean;
};

const Tooltip = ({ children, value, variant = 'dark', hidden = false }: Props) => {
  console.log(value, variant, hidden);

  return children;
};

export default Tooltip;
