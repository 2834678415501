import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View position',
  endpoint: endpoints.GET_POSITION,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Name', key: 'name', type: ComponentList.TEXT },
    { label: 'Description', key: 'description', type: ComponentList.TEXT },
    { label: 'Leaders App Access Level', key: 'level_access', type: ComponentList.TEXT }
  ]
};
