import formErrors from 'constants/formErrors';
import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import endpoints from 'constants/endpoints';

export default {
  recipientId: {
    value: '',
    label: 'Recipient',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_USER,
    timezone: true,
    joi: joi.alternatives().try(
      joi.string().required().empty('').messages({
        'string.empty': formErrors.REQUIRED,
        'any.required': formErrors.REQUIRED
      }),
      joi.object().unknown(true)
    )
  },
  warningType: {
    value: '',
    label: 'Warning Type',
    input: InputsEnum.SELECT,
    options: [
      { label: 'Uniform', value: 'Uniform' },
      { label: 'Alcohol Violation', value: 'Alcohol Violation' },
      { label: 'Behavioral Incident', value: 'Behavioral Incident' },
      { label: 'Venue Complaint', value: 'Venue Complaint' },
      { label: 'Drop Out', value: 'Drop Out' },
      { label: 'Tardy', value: 'Tardy' },
      { label: 'No Show', value: 'No Show' },
      { label: 'Other', value: 'Other' }
    ],
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  warning: {
    value: '',
    label: 'Warning',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  eventId: {
    value: '',
    label: 'Event',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_EVENT,
    timezone: true,
    joi: joi.alternatives().try(
      joi.string().required().empty('').messages({
        'string.empty': formErrors.REQUIRED,
        'any.required': formErrors.REQUIRED
      }),
      joi.object().unknown(true)
    )
  }
};
