import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const DownloadEmployeeOnboardingDataModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler({
    statuses: {
      ...schema.statuses
    }
  });

  const downloadData = () => {
    params.onDownload(formHandler.data.statuses.map((s: { value: string }) => s.value));
    onClose();
  };

  return (
    <Base
      header="Download employee onboarding data"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Download" onClick={downloadData} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default DownloadEmployeeOnboardingDataModal;
