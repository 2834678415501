import React from 'react';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modals';

type Props = {
  value: string;
};

const ViewVideo = ({ value }: Props) => {
  const modal = useModal();

  const handleOpenModal = () => {
    modal.open(ModalsList.VIEW_VIDEO_MODAL, () => {}, {
      videoUrl: value
    });
  };

  return (
    <div className="view-video" onClick={handleOpenModal}>
      View video
    </div>
  );
};

export default ViewVideo;
