import joi from 'joi';
import formErrors from 'constants/formErrors';
import endpoints from 'constants/endpoints';
import { onlyNumbersRegex } from 'constants/regex';
import { DateInputTypes, InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  venue: {
    value: '',
    label: 'Venue',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_VENUE,
    timezone: true,
    joi: joi.alternatives().try(
      joi.string().required().empty('').messages({
        'string.empty': formErrors.REQUIRED,
        'any.required': formErrors.REQUIRED
      }),
      joi.object().unknown(true)
    )
  },
  templateId: {
    value: '',
    label: 'Template',
    input: InputsEnum.SELECT,
    options: [],
    joi: joi.optional()
  },
  heldOn: {
    value: '',
    label: 'Held on',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE_TIME,
    joi: joi.date().iso().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED,
      'date.format': formErrors.INVALID_DATE,
      'date.before': formErrors.BEFORE_DATE
    })
  },
  allowRegistrationsAt: {
    value: '',
    label: 'Allow registrations at',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE_TIME,
    joi: joi.date().iso().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED,
      'date.format': formErrors.INVALID_DATE
    })
  },
  employeeCapacity: {
    value: '',
    label: 'Staff # Needed',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().regex(onlyNumbersRegex).allow('').messages({
      'string.pattern.base': formErrors.INVALID_NUMBER
    })
  },
  buffer: {
    value: '',
    label: 'Buffer',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().regex(onlyNumbersRegex).allow('').messages({
      'string.pattern.base': formErrors.INVALID_NUMBER
    })
  },
  reportTimeAt: {
    value: '',
    label: 'Report time at',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE_TIME,
    joi: joi
      .date()
      .iso()
      .allow(null)
      .allow('')
      .optional()
      .custom((value, helpers) => {
        const {
          state: {
            ancestors: [{ heldOn }]
          }
        } = helpers;

        if (heldOn && Math.abs(heldOn - value) / 3600000 > 6) {
          return helpers.error('any.invalid');
        }

        return value;
      })
      .messages({
        'any.invalid': formErrors.REPORT_TIME_TOO_FAR_FROM_HELD_ON_DATE
      })
  },
  payModelUrl: {
    value: '',
    label: 'Pay Model URL',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().uri().allow(null).allow('').messages({
      'string.uri': formErrors.INVALID_URL
    })
  },
  eventLogisticsUrl: {
    value: '',
    label: 'Event Logistics URL',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().uri().allow(null).allow('').optional().messages({
      'string.uri': formErrors.INVALID_URL
    })
  },
  eventTrainingUrl: {
    value: '',
    label: 'Event Training URL',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().uri().allow(null).allow('').optional().messages({
      'string.uri': formErrors.INVALID_URL
    })
  },
  groupMeUrl: {
    value: '',
    label: 'GroupMe URL',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().uri().allow(null).allow('').optional().messages({
      'string.uri': formErrors.INVALID_URL
    })
  },
  tags: {
    value: [],
    label: 'Tags',
    limit: 1,
    input: InputsEnum.MULTI_SELECT,
    endpoint: endpoints.SEARCH_TAGS,
    joi: joi.array()
  },
  automateRoster: {
    value: false,
    label: 'Automate Roster Active',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  }
};
