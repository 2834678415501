/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import joi from 'joi';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import useFormHandler from 'hooks/useFormHandler';
import Base from '../../base';
import schema, { triggers } from './schema';

const CreationNotificationModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    const { data, schema } = formHandler;
    const formData = { ...data };

    if (schema.account.hide) {
      delete formData.account;
    }
    if (schema.venue.hide) {
      delete formData.venue;
    }

    onClose(formData);
  };

  useEffect(() => {
    const { data, setFullSchema } = formHandler;

    switch (data.trigger) {
      case triggers.ALL_EMPLOYEES:
        setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: true,
              joi: joi.allow('').optional()
            },
            venue: {
              ...schema.venue,
              hide: true,
              joi: joi.allow('').optional()
            }
          },
          {
            ...data,
            account: '',
            venue: ''
          }
        );
        break;
      case triggers.ONLY_EMPLOYEES_FROM_ACCOUNT:
        setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: false
            },
            venue: {
              ...schema.venue,
              hide: true,
              joi: joi.allow('').optional()
            }
          },
          {
            ...data,
            venue: ''
          }
        );
        break;
      case triggers.ONLY_EMPLOYEES_FROM_VENUE:
        setFullSchema(
          {
            ...schema,
            account: {
              ...schema.account,
              hide: true,
              joi: joi.allow('').optional()
            },
            venue: {
              ...schema.venue,
              hide: false
            }
          },
          {
            ...data,
            account: ''
          }
        );
        break;
      default:
        break;
    }
  }, [formHandler.data.trigger]);

  return (
    <Base
      header="Create Notification"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default CreationNotificationModal;
