import formErrors from 'constants/formErrors';
import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';
import endpoints from 'constants/endpoints';

export default {
  userId: {
    value: '',
    label: 'User',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_USER,
    timezone: true,
    joi: joi.alternatives().try(
      joi.string().required().empty('').messages({
        'string.empty': formErrors.REQUIRED,
        'any.required': formErrors.REQUIRED
      }),
      joi.object().unknown(true)
    )
  }
};
