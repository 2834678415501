import React from 'react';
import { capitalize } from 'helpers/text';
import { Tag } from 'components';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modals';
import { UniformStatusTypes } from 'types/uniform';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';

type Props = {
  value: {
    status: UniformStatusTypes;
    employee_id: number;
  };
  handleRefresh: () => void;
};

const options = {
  [UniformStatusTypes.APPROVED]: 'success',
  [UniformStatusTypes.REJECTED]: 'error'
};

const UniformApprovalStatus = ({ value, handleRefresh }: Props) => {
  const api = useApi();
  const modal = useModal();

  const handleClick = () => {
    modal.open(
      ModalsList.UNIFORM_APPROVAL,
      async data => {
        if (data) {
          await api.fetchData(endpoints.EDIT_EMPLOYEE.get({ id: value.employee_id }), { uniformStatus: data.status });
          handleRefresh();
        }
      },
      {
        employeeId: value.employee_id,
        status: value.status
      }
    );
  };

  if (value?.status) {
    return (
      <Tag variant={options[value.status] as any} onClick={handleClick}>
        {capitalize(value.status)}
      </Tag>
    );
  }

  return (
    <Tag variant="secondary" onClick={handleClick}>
      Not started
    </Tag>
  );
};

export default UniformApprovalStatus;
