import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import { AlertBanner, Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const CreationNoticeModal = ({ closing, onClose }: Props) => {
  const api = useApi();
  const apiFile = useApi();
  const apiS3 = useApi();
  const formHandler = useFormHandler(schema);

  const handleSubmit = async () => {
    formHandler.setIsSubmitting(true);
    let fileObject;
    if (formHandler.data.files?.name) {
      const signedUrl = await apiFile.fetchData(endpoints.FILE_NOTICE.get(), { fileName: formHandler.data.files.name });

      apiS3.uploadFileToS3(formHandler.data.files, signedUrl.extension, signedUrl.url);

      fileObject = {
        file_name: signedUrl.fileName,
        extension: signedUrl.extension,
        url: signedUrl.url,
        size: formHandler.data.files.size.toString()
      };
    }

    onClose({
      ...formHandler.data,
      state: formHandler.data.state === 'ALL' ? null : +formHandler.data.state,
      file: fileObject
    });
  };

  useEffect(() => {
    if (api.response) {
      formHandler.setSchema({
        ...schema,
        state: {
          ...schema.state,
          disabled: false,
          options: [
            { label: 'All', value: 'ALL' },
            ...api.response.items.map((item: any) => ({ label: item.name, value: item.id }))
          ]
        }
      });
    }
  }, [api.response]);

  useEffect(() => {
    api.fetchData(endpoints.GET_ACTIVE_STATES.get());
  }, []);

  return (
    <Base
      header="Create notice"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <div className="modal-create-notice">
        <AlertBanner
          type="warning"
          message="If the new notice is marked as visible, it will appear to all the employees who are enrolled to the venues located in the states assigned for this notice."
        />
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationNoticeModal;
