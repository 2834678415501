import React from 'react';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';

const RejectApplicant = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  return (
    <Base
      header="Reject Applicant"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button
            text="Reject"
            onClick={() =>
              onClose({
                reason: formHandler.data.reason
              })
            }
          />
          <Button text="Cancel" variant="secondary" onClick={() => onClose(false)} />
        </>
      }
    >
      <div className="reject-application-modal">
        <p className="title body strong">Are sure you want to reject “{params.userName}”?</p>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default RejectApplicant;
