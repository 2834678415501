import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const CreationAdminPayrollDetailsModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = async () => {
    onClose({
      employees: formHandler.data.ids,
      payrollId: params.id
    });
  };

  useEffect(() => {
    formHandler.setSchema({
      ...formHandler.schema,
      ids: {
        ...formHandler.schema.ids,
        placeholder: params.inputPlaceholder
      }
    });
  }, []);

  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" onClick={handleSubmit} disabled={!formHandler.valid} />}
    >
      <div className="modal-create-payroll">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationAdminPayrollDetailsModal;
