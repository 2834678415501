import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import classnames from 'classnames';

type Item = string | { [key: string]: any };

type Props = {
  items: Item[];
  setSelected: (selected: any) => void;
  selected?: number | string;
  displayProperty?: string;
  valueProperty?: string;
  perPage?: number;
};

const Selector = ({
  items,
  setSelected,
  selected = 0,
  displayProperty = '',
  valueProperty = '',
  perPage = 3
}: Props) => (
  <Splide
    className="selector"
    options={{
      perPage,
      pagination: false,
      type: 'slide',
      gap: 10
    }}
  >
    {items.map((item, index) => {
      const displayValue = typeof item === 'object' && displayProperty ? item[displayProperty] : item;
      const value = typeof item === 'object' && valueProperty ? item[valueProperty] : index;

      return (
        <SplideSlide key={index}>
          <button
            onClick={() => setSelected(value)}
            className={classnames('btn', 'body-2', { selected: selected === value })}
          >
            {displayValue}
          </button>
        </SplideSlide>
      );
    })}
  </Splide>
);

export default Selector;
