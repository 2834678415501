import React from 'react';
import { TextCopy } from 'components';

type Props = {
  value: string;
};

const Email = ({ value }: Props) => {
  return <TextCopy value={value} />;
};

export default Email;
