import React from 'react';

type Props = {
  value: string;
};

const ApplicationStatus = ({ value }: Props) => {
  const getStatusClass = (value: string) => {
    switch (value) {
      case 'approved':
        return 'success';
      case 'pending':
        return 'pending';
      case 'pending_review':
        return 'pending-review';
      case 'rejected':
        return 'rejected';
      default:
        return '';
    }
  };

  return <div className={`application-status ${getStatusClass(value)}`}>{value}</div>;
};

export default ApplicationStatus;
