import React from 'react';
import classnames from 'classnames';
import { SelectProps } from 'types/dynamicInputs';

const Select = ({
  label,
  onChange,
  onBlur,
  options,
  pristine,
  error,
  value,
  required,
  disabled = false
}: SelectProps) => {
  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <select
        className="body-2 select-container"
        onChange={({ target }) => onChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
        value={value}
      >
        <option key="empty" value="">
          -
        </option>
        {options?.map((selected: any) => (
          <option key={selected?.value} value={selected.value}>
            {selected.label}
          </option>
        ))}
      </select>
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default Select;
