import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import endpoints from 'constants/endpoints';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionStateModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  useEffect(() => {
    api.fetchData(endpoints.GET_STATE.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      formHandler.setFullForm(api.response.data);
    }
  }, [api.response, api.loaded]);

  return (
    <Base
      header="Edit state"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={() => {
            formHandler.setIsSubmitting(true);
            onClose(formHandler.data);
          }}
        />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionStateModal;
