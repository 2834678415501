import joi from 'joi';
import formErrors from 'constants/formErrors';
import endpoints from 'constants/endpoints';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  position: {
    value: '',
    label: 'Position',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_POSITION,
    required: true,
    disabled: true,
    joi: joi.number().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  total: {
    value: 10,
    label: 'Total',
    input: InputsEnum.INPUT,
    type: TextInputTypes.NUMBER,
    required: true,
    joi: joi.number().min(1).required().messages({
      'number.base': formErrors.INVALID_NUMBER,
      'number.min': formErrors.MIN_1
    })
  }
};
