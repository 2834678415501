import joi from 'joi';
import endpoints from 'constants/endpoints';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import formErrors from 'constants/formErrors';

export default {
  position: {
    value: '',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_POSITION,
    joi: joi.alternatives().try(
      joi.string().required().messages({
        'string.empty': formErrors.REQUIRED
      }),
      joi.number().required().messages({
        'any.required': formErrors.REQUIRED
      })
    )
  },
  total: {
    value: 10,
    label: 'Total',
    input: InputsEnum.INPUT,
    type: TextInputTypes.NUMBER,
    required: true,
    joi: joi.number().min(1).required().messages({
      'number.base': formErrors.INVALID_NUMBER,
      'number.min': formErrors.MIN_1
    })
  }
};
