import React from 'react';
import { DateTimePicker as DefaultDateTimePicker } from 'react-datetime-picker';
import classnames from 'classnames';
import { DatePickerProps } from 'types/dynamicInputs';
import { dateTimeFormat } from 'constants/dates';

const DateTimePicker = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  required = true,
  pristine = true,
  disabled = false
}: DatePickerProps) => {
  const handleChange = (value: any) => {
    onBlur && onBlur();
    onChange(value, name);
  };

  return (
    <div className={classnames('date-time-picker-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <DefaultDateTimePicker
        disableClock={true}
        required={required}
        format={dateTimeFormat}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        locale="en-US"
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default DateTimePicker;
