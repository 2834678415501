import React from 'react';
import { Tag } from 'components';

type Props = {
  value: {
    id: number;
    url: string;
  }[];
};

const YoutubeTags = ({ value }: Props) => {
  const openVideo = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="youtube-tags">
      {value.map((item, index) => (
        <Tag key={index} variant="secondary" onClick={() => openVideo(item.url)}>
          Video {index + 1}
        </Tag>
      ))}
    </div>
  );
};

export default YoutubeTags;
