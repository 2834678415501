/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { confirmReallocateMessage } from 'constants/messages';
import useFormHandler from 'hooks/useFormHandler';
import { useModal } from 'hooks/useModal';
import { getFullName } from 'helpers/data';
import { Button, DynamicForm } from 'components';
import { ModalsList, Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';
import { JobType } from 'constants/jobType';

const AssignStandLeaderModal = ({ closing, onClose, params }: Props) => {
  const modal = useModal();
  const formHandler = useFormHandler(schema);

  const handleSave = () => {
    formHandler.setIsSubmitting(true);
    const data = formHandler.data.leaders.map((item: any) => ({
      positionId: item.id,
      employees: item.employees.map((employee: any) => +employee.value)
    }));

    const employees = formHandler.data.leaders.flatMap((item: any) => item.employees.map((item: any) => item.value));
    const assignedFlag = params.checkAlreadyAssigned('standLeader', employees);

    if (assignedFlag) {
      modal.open(
        ModalsList.CONFIRM_MODAL,
        flag => {
          flag ? onClose(data) : formHandler.setIsSubmitting(false);
        },
        confirmReallocateMessage
      );
    } else {
      onClose(data);
    }
  };

  useEffect(() => {
    formHandler.setFullForm({
      leaders: params.leaders
        .filter((e: any) => e.job_type === JobType.STAND_LEAD)
        .map((item: any) => ({
          id: item.position.id,
          name: item.position.name,
          levelAccess: item.position.level_access,
          employees: item.jobs.map((job: any) => ({
            label: `${job.employee.legal_first_name} ${job.employee.legal_last_name}`,
            value: job.employee.id
          }))
        }))
    });
  }, []);

  return (
    <Base
      header="Assign Stand Leader"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSave} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default AssignStandLeaderModal;
