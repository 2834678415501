export default {
  ADMIN_PAYROLLS: '/admin-payrolls',
  ADMIN_PAYROLLS_SALARIED: '/admin-payrolls/salaried/:id',
  ADMIN_PAYROLLS_HOURLY: '/admin-payrolls/hourly/:id',
  ADMIN_PAYROLLS_CONTRACTOR: '/admin-payrolls/contractor/:id',
  LOGIN: '/login',
  ACCOUNTS: '/',
  VENUES: '/venues',
  EVENT: '/events/:id',
  EVENTS: '/events',
  USERS: '/users',
  EMPLOYEES_ON_BOARDING: '/employees/on-boarding',
  EMPLOYEES: '/employees',
  EARNINGS: '/earnings',
  PAYROLLS: '/payrolls',
  PAYMENTS: '/payments',
  TAGS: '/tags',
  POSITIONS: '/positions',
  STATES: '/states',
  NOTICES: '/notices',
  WARNINGS: '/warnings',
  WARNINGS_ARCHIVE: '/warnings-archive',
  APPEAL_WARNING: '/appeal-warning',
  APPEALS: '/appeals',
  TRAININGS: '/trainings',
  TEMPLATES: '/templates',
  TEMPLATE: '/templates/:id',
  HUMAN_RESOURCES: '/human-resources',
  NOTIFICATIONS: '/notifications',
  APPLICATIONS: '/applications',
  MY_CHATS: '/myChats'
};
