import React, { useEffect, useState } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import { salariedSchema, hourlySchema, contractorSchema } from './schema';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { PayrollAdminTypes, PayrollSchedule } from 'types/payrollAdmin';
import { fileToBase64, getFileFormat } from 'utils/data';
import { onlyDate, removeTimeZone, utcDate } from 'helpers/date';

const CreationAdminPayrollModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(salariedSchema);
  const api = useApi();
  const [nextPaydays, setNextPaydays] = useState<any[]>([]);
  const { type } = formHandler.data;
  const isContractor = type === PayrollAdminTypes.CONTRACTOR;

  const handleSubmit = async () => {
    formHandler.setIsSubmitting(true);
    const { payday, periodStart, periodEnd, files } = formHandler.data;
    const isHourly = type === PayrollAdminTypes.HOURLY;
    let file: any = null;

    if (files?.size) {
      file = isHourly ? { data: await fileToBase64(files), format: getFileFormat(files) } : null;
    }

    formHandler.setIsSubmitting(false);
    onClose({
      type: +formHandler.data.type,
      name: formHandler.data.name,
      payday: payday,
      period_start: periodStart,
      period_end: periodEnd,
      file: file
    });
  };

  const setInitialFormValue = () => {
    formHandler.setFullForm({
      type: PayrollAdminTypes.SALARIED,
      name: '',
      payday: ''
    });
  };

  const getNextPaydays = async (type: string) => {
    const response = await api.fetchData(endpoints.GET_NEXT_PAYDAY.get({ type }));
    setNextPaydays(response.nextPaydays);
  };

  const transformNextPaydays = (nextPaydays: PayrollSchedule[]) => {
    return nextPaydays.map(item => utcDate(item.payday));
  };

  useEffect(() => {
    setInitialFormValue();
  }, []);

  useEffect(() => {
    const { type } = formHandler.data;

    switch (type) {
      case PayrollAdminTypes.SALARIED:
        getNextPaydays('salary');
        formHandler.changeSchema(salariedSchema, { type, name: '', payday: '', periodStart: '', periodEnd: '' });
        return;

      case PayrollAdminTypes.HOURLY:
        getNextPaydays('hourly');
        formHandler.changeSchema(hourlySchema, {
          type,
          name: '',
          payday: '',
          periodStart: '',
          periodEnd: '',
          files: {}
        });
        return;

      case PayrollAdminTypes.CONTRACTOR:
        formHandler.changeSchema(contractorSchema, { type, name: '', payday: '', periodStart: '', periodEnd: '' });
        return;

      default:
        return;
    }
  }, [formHandler.data.type]);

  useEffect(() => {
    if (nextPaydays.length > 0 && type !== PayrollAdminTypes.CONTRACTOR) {
      const updatedSchema = {
        ...formHandler.schema,
        payday: {
          ...formHandler.schema.payday,
          allowedDates: transformNextPaydays([...nextPaydays]),
          disabled: false
        }
      };

      formHandler.setSchema(updatedSchema);
    }
  }, [nextPaydays]);

  useEffect(() => {
    if (!isContractor) {
      if (!formHandler.data.payday) return;

      const item = nextPaydays.find(item => item.payday === onlyDate(removeTimeZone(formHandler.data.payday)));

      formHandler.setFullForm({
        ...formHandler.data,
        periodStart: utcDate(item.period_start),
        periodEnd: utcDate(item.period_end)
      });
    }
  }, [formHandler.data.payday]);

  return (
    <Base
      header="Create admin payroll"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <div className="modal-create-payroll">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationAdminPayrollModal;
