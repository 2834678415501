import React, { useEffect } from 'react';
import { Button, DownloadResume, ViewVideo } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { format, parseISO } from 'date-fns';

export const howDidYouHearAboutUsLabel = {
  existing_worker: 'Existing worker',
  flyer: 'Flyer',
  digital_ad: 'Digital ad',
  word_of_mouth: 'Word of mouth',
  other: 'Other'
};

const educationLabels = {
  'high-school': 'High school degree or equivalent (e.g., GED)',
  college: 'Currently enrolled in college, not graduated',
  'associate-bachelor': 'Associate / Bachelor degree',
  graduate: 'Graduate degree'
};

const PreviewApplicationModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const summaryData: any = api?.response?.data?.summary;
  const buttonsDisabled = params.status === 'approved' || params.status === 'rejected';

  useEffect(() => {
    api.fetchData(endpoints.GET_APPLICATION_ADMIN.get({ applicationId: String(params.applicationId) }));
  }, []);

  return (
    <Base
      header="Preview Application"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          {api.response && (
            <>
              <Button
                text="APPROVE"
                onClick={() => {
                  if (buttonsDisabled) return;
                  onClose('approve');
                }}
                disabled={buttonsDisabled}
              />
              <Button
                text="REJECT"
                variant="secondary"
                onClick={() => {
                  if (buttonsDisabled) return;
                  onClose('reject');
                }}
                disabled={buttonsDisabled}
              />
            </>
          )}
        </>
      }
    >
      {api.isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="applicant-summary">
          <div className="summary-container">
            <div className="summary-item">
              <p className="title body">First Name</p>
              <p className="value body">{summaryData?.firstName}</p>
            </div>

            <div className="summary-item">
              <p className="title body">Last Name</p>
              <p className="value body">{summaryData?.lastName}</p>
            </div>

            <div className="summary-item">
              <p className="title body">Email</p>
              <p className="value body">{summaryData?.email}</p>
            </div>

            <div className="summary-item">
              <p className="title body">Phone Number</p>
              <p className="value body">{summaryData?.phoneNumber}</p>
            </div>

            <div className="summary-item">
              <p className="title body">Date of Birth</p>
              <p className="value body">
                {summaryData?.dateOfBirth ? format(parseISO(summaryData.dateOfBirth), 'dd/MM/yyyy') : '-'}
              </p>
            </div>

            <div className="summary-divider" />

            <div className="summary-item">
              <p className="title body">State</p>
              <p className="value body">{summaryData?.['State']}</p>
            </div>

            <div className="summary-item">
              <p className="title body">Venues</p>
              {summaryData?.Venues?.map((venue: any, index: number) => (
                <p key={index} className="value body">
                  {venue}
                </p>
              ))}
            </div>
            <div className="summary-divider" />

            <div className="summary-item">
              <p className="title body">Level of Education</p>
              <p className="value body">
                {educationLabels[summaryData?.['Level of Education'] as keyof typeof educationLabels]}
              </p>
            </div>
            <div className="summary-divider" />

            <div className="summary-item">
              <p className="title body">Profile Picture</p>
              <img className="profile-picture" src={summaryData?.['Profile Picture']?.url} alt="profile" />
            </div>

            <div className="summary-item">
              <p className="title body">Video Presentation</p>
              <ViewVideo value={summaryData?.['Video Presentation']?.url} />
            </div>

            <div className="summary-item">
              <p className="title body">Resume</p>
              <DownloadResume value={summaryData?.Resume?.url} />
            </div>

            <div className="summary-item">
              <p className="title body">Bartender/Food Experience</p>
              <p className="value body">{summaryData?.['Bartender/Food Experience']}</p>
            </div>

            <div className="summary-item">
              <p className="title body">Management Experience</p>
              <p className="value body">{summaryData?.['Management Experience']}</p>
            </div>

            <div className="summary-item">
              <p className="title body">What it means to serve?</p>
              <p className="value body">{summaryData?.['What it means to serve']}</p>
            </div>
            <div className="summary-divider" />

            <div className="summary-item">
              <p className="title body">How did you hear about us?</p>
              <p className="value body">
                {' '}
                {
                  howDidYouHearAboutUsLabel[
                    summaryData?.['How did you hear about us?'] as keyof typeof howDidYouHearAboutUsLabel
                  ]
                }
              </p>
            </div>
          </div>
        </div>
      )}
    </Base>
  );
};

export default PreviewApplicationModal;
