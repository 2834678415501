export enum LeaderRolesEnum {
  SITE_LEAD = 0,
  AREA_LEAD = 1,
  STAND_LEAD = 2
}

export const ROLE_LABELS = {
  [LeaderRolesEnum.SITE_LEAD]: 'Site Lead',
  [LeaderRolesEnum.AREA_LEAD]: 'Area Lead',
  [LeaderRolesEnum.STAND_LEAD]: 'Stand Lead'
};

export const LEADER_ROLES = [
  { label: ROLE_LABELS[LeaderRolesEnum.SITE_LEAD], value: LeaderRolesEnum.SITE_LEAD },
  { label: ROLE_LABELS[LeaderRolesEnum.AREA_LEAD], value: LeaderRolesEnum.AREA_LEAD },
  { label: ROLE_LABELS[LeaderRolesEnum.STAND_LEAD], value: LeaderRolesEnum.STAND_LEAD }
];

export enum RANKING {
  UNRANKED = 'Unranked',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  MOON = 'Moon'
}

export const JOB_TYPE = {
  SITELEAD: 0,
  AREALEAD: 1,
  STANDLEAD: 2,
  STAFF: 3
};
