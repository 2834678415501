import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';
import { Flow } from 'constants/trainings';

const CreationTrainingModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = async () => {
    onClose({
      ...formHandler.data,
      venues: formHandler.data.venues.map((venue: any) => ({
        value: +venue?.value
      })),
      positions: formHandler.data.positions.map((position: any) => position.value),
      quizUrl: [formHandler.data.quizUrl]
    });
  };

  useEffect(() => {
    const { flow, global } = formHandler.data;
    const isOnboarding = flow === Flow.Onboarding;
    const isMyTrainings = flow === Flow.MyTrainings;
    const shouldDisable = isOnboarding || (global && !isOnboarding);
    const shouldEnable = (isMyTrainings && !global) || (!global && !isOnboarding);

    if (isOnboarding) {
      const updatedSchema = Object.entries(formHandler.schema).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            key === 'global' || key === 'venues' || key === 'positions' ? { ...(value as any), disabled: true } : value
        }),
        {}
      );

      formHandler.setFullSchema(updatedSchema, { venues: [], positions: [], global: false });
      return;
    }

    if (shouldDisable) {
      const updatedSchema = Object.entries(formHandler.schema).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: key === 'venues' || key === 'positions' ? { ...(value as any), disabled: true } : value
        }),
        {}
      );

      formHandler.setFullSchema(updatedSchema, { venues: [], positions: [] });
    } else if (shouldEnable) {
      const updatedSchema = Object.entries(formHandler.schema).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            key === 'venues' || key === 'positions' || key === 'global' ? { ...(value as any), disabled: false } : value
        }),
        {}
      );

      formHandler.setSchema(updatedSchema);
    }
  }, [formHandler.data.flow, formHandler.data.global]);

  return (
    <Base
      header="Create training"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" onClick={handleSubmit} />}
    >
      <div className="modal-create-training">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationTrainingModal;
