import { differenceInYears, format, isAfter, startOfMonth, subMonths } from 'date-fns';
import { RANKING } from 'constants/user';

export function isOver18(date: string): boolean {
  const diff = differenceInYears(new Date(), new Date(date));
  return diff >= 18;
}

export function isDateAfterOrEqualNow(date: string): boolean {
  const now = new Date();
  const dateObj = new Date(date);
  return isAfter(dateObj, now) || dateObj.getTime() === now.getTime();
}

export const base64toBlob = async (base64: string, type = 'application/octet-stream'): Promise<Blob> => {
  const response = await fetch(`data:${type};base64,${base64}`);
  const blob = await response.blob();
  return blob;
};

export const getLastMessageDate = (channel: any) => {
  const lastMessageDate = channel?.lastMessage?.createdAt;
  return lastMessageDate ? format(new Date(lastMessageDate), 'MMM dd') : null;
};

export function downloadBlob(blob: Blob, filename: string) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export async function downloadFileUrl(url: string, type: string, filename: string) {
  const response = await fetch(url);

  if (response.ok) {
    const fileData = await response.arrayBuffer();
    const blob = new Blob([fileData], { type: type });
    const element = document.createElement('a');

    element.href = URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element).click();
    document.body.removeChild(element);
  } else {
    console.error(`HTTP error! Status: ${response.status}`);
  }
}

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const base64 = result.split(',')[1];
      resolve(base64);
    };
    reader.onerror = error => reject(error);
  });
};

export function getURLExtension(url: string): string | null {
  if (!url) return null;
  const match = url.match(/\.([a-z0-9]+)(?:[?#]|$)/i);
  return match ? match[1] : null;
}

export function formatToUSD(number: number) {
  return number?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
}

export function getLastThreeMonths() {
  const currentDate = new Date();
  const getMonthName = (date: Date) => format(date, 'MMMM');
  const startOfCurrentMonth = startOfMonth(currentDate);

  const months = [
    getMonthName(subMonths(startOfCurrentMonth, 2)),
    getMonthName(subMonths(startOfCurrentMonth, 1)),
    getMonthName(startOfCurrentMonth)
  ];

  return `Last 3 Months: ${months[0]} - ${months[2]}`;
}

export function getRankingVariant(rank: string) {
  switch (rank) {
    case RANKING.BRONZE:
      return 'bronze';
    case RANKING.SILVER:
      return 'silver';
    case RANKING.GOLD:
      return 'gold';
    case RANKING.PLATINUM:
      return 'platinum';
    case RANKING.MOON:
      return 'primary';

    default:
      return '';
  }
}

export function parsePercentage(percentage: string) {
  if (percentage && typeof percentage === 'string') {
    return parseFloat(percentage.replace('%', ''));
  }
  return percentage;
}

export const dollarized = (value: string) => {
  return `$${value.replace('$', '')}`;
};

export const getFileFormat = (file: File) => {
  const extension = file.name.split('.').pop();
  return extension?.toLowerCase();
};

export function convertCurrencyString(input: string): number {
  let numberString = input.replace('$', '');

  if (numberString.includes(',')) {
    numberString = numberString.replace(/,/g, '');
  }

  const result = parseFloat(numberString);

  return result;
}

export const formatToFixedTwoDecimals = (numValue: string) => {
  const number = parseFloat(numValue);
  return number.toFixed(2);
};

export function trimUrlToPdf(url: string): string {
  const pdfPosition = url.indexOf('.pdf');
  if (pdfPosition !== -1 && url[pdfPosition + 4] === '?') {
    return url.substring(0, pdfPosition + 4);
  } else {
    return url;
  }
}
