import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'Preview Notification',
  endpoint: endpoints.GET_NOTIFICATION,
  schema: [
    { label: 'Date', key: 'created_at', type: ComponentList.DATE },
    { label: 'Send to', key: 'target', type: ComponentList.TEXT },
    { label: 'Title', key: 'title', type: ComponentList.TEXT },
    { label: 'Message', key: 'message', type: ComponentList.REACH_TEXT }
  ]
};
