export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toUpperAndSnakeCase(text: string) {
  const upperCaseText = text.toUpperCase();
  const snakeCaseText = upperCaseText.replace(/\s+/g, '_');

  return snakeCaseText;
}

export function formatPhoneNumber(phoneNumberString: string) {
  let cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumberString;
}
