export enum ComponentList {
  BACKGROUND_CHECK_INFO = 'BACKGROUND_CHECK_INFO',
  EVENT_TAGS = 'EVENT_TAGS',
  TEXT = 'TEXT',
  EMPLOYEE_NAME = 'EMPLOYEE_NAME',
  TEXT_COPY = 'TEXT_COPY',
  TEXT_WITH_BREAKS = 'TEXT_WITH_BREAKS',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  DOWNLOAD = 'DOWNLOAD',
  CONSULT_CHECK_PAYROLL = 'CONSULT_CHECK_PAYROLL',
  YES_OR_NO = 'YES_OR_NO',
  REACH_TEXT = 'REACH_TEXT',
  VENUES = 'VENUES',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  HTML = 'HTML',
  FILE = 'FILE',
  LINK = 'LINK',
  PERCENTAGE = 'PERCENTAGE',
  ALCOHOL_INSTRUCTIONS = 'ALCOHOL_INSTRUCTIONS',
  NOTION_LINK = 'NOTION_LINK'
}

export enum ViewList {
  ACCOUNT = 'ACCOUNT',
  VENUE = 'VENUE',
  PAYROLL = 'PAYROLL',
  PAYMENT = 'PAYMENT',
  EARNING = 'EARNING',
  EMPLOYEE = 'EMPLOYEE',
  USER = 'USER',
  EVENT = 'EVENT',
  NOTICE = 'NOTICE',
  ONBOARDING = 'ONBOARDING',
  POSITION = 'POSITION',
  TAG = 'TAG',
  VENUES = 'VENUES',
  WARNING = 'WARNING',
  WARNING_ARCHIVED = 'WARNING_ARCHIVED',
  APPEAL = 'APPEAL',
  APPEAL_ARCHIVED = 'APPEAL_ARCHIVED',
  TERMINATION = 'TERMINATION',
  TERMINATION_ARCHIVED = 'TERMINATION_ARCHIVED',
  NOTIFICATION = 'NOTIFICATION'
}
