import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { Flow } from 'constants/trainings';

const EditionTrainingModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = async () => {
    onClose({
      ...formHandler.data,
      venues: formHandler.data.venues.map((venue: any) => ({
        value: +venue?.value
      })),
      positions: formHandler.data.positions.map((position: any) => position.value),
      quizUrl: [formHandler.data.quizUrl]
    });
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_TRAINING.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const data = {
        name: api.response.data.name,
        flow: api.response.data.flow,
        venues: api.response.data.venues.map((item: any) => ({ label: item.name, value: item.id })),
        positions: api.response.data.positions,
        videoUrl: api.response.data.videos.map((item: any) => item.url),
        quizUrl: api.response.data.quiz[0]?.url,
        docs: api.response.data.files.map((item: any) => ({
          file_name: item.fileName,
          extension: item.extension,
          url: item.url
        })),
        global: api.response.data.global,
        active: api.response.data.active
      };

      formHandler.setFullForm(data);
    }
  }, [api.loaded]);

  useEffect(() => {
    const { flow, global } = formHandler.data;
    const isOnboarding = flow === Flow.Onboarding;
    const isMyTrainings = flow === Flow.MyTrainings;
    const shouldDisable = isOnboarding || (global && !isOnboarding);
    const shouldEnable = (isMyTrainings && !global) || (!global && !isOnboarding);

    if (isOnboarding) {
      const updatedSchema = Object.entries(formHandler.schema).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            key === 'global' || key === 'venues' || key === 'positions' ? { ...(value as any), disabled: true } : value
        }),
        {}
      );

      formHandler.setFullSchema(updatedSchema, { venues: [], positions: [], global: false });
      return;
    }

    if (shouldDisable) {
      const updatedSchema = Object.entries(formHandler.schema).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: key === 'venues' || key === 'positions' ? { ...(value as any), disabled: true } : value
        }),
        {}
      );

      formHandler.setFullSchema(updatedSchema, { venues: [], positions: [] });
    } else if (shouldEnable) {
      const updatedSchema = Object.entries(formHandler.schema).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            key === 'venues' || key === 'positions' || key === 'global' ? { ...(value as any), disabled: false } : value
        }),
        {}
      );

      formHandler.setSchema(updatedSchema);
    }
  }, [formHandler.data.flow, formHandler.data.global]);

  return (
    <Base
      header="Edit training"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Save changes" onClick={handleSubmit} />}
    >
      <div className="modal-create-training">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default EditionTrainingModal;
