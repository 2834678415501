import isEqual from 'lodash.isequal';
import isEqualWith from 'lodash.isequalwith';
import isPlainObject from 'lodash.isplainobject';
import omit from 'lodash.omit';

export const objectMap = (obj: Object, fn: Function) => {
  const arr = Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]);

  return arr.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {}); // Object.fromEntries
};

export const objectToArray = (object: Object) => {
  const array = Object.entries(object).map(([k, v]) => ({ ...v, id: k }));

  return array;
};

export const endpointsPrototype = {
  init() {
    for (const key of Object.keys(this)) {
      // @ts-ignore
      const item = this[key];

      Object.assign(item, {
        get(params?: any) {
          let { endpoint, method } = item;

          if (params) {
            for (const [key, value] of Object.entries(params)) {
              endpoint = endpoint.replace(`:${key}`, value as any);
            }
          }

          return { endpoint, method };
        }
      });
    }
  }
};

export const applyMask = (value: string, mask: (string | RegExp)[]): string => {
  let maskedValue = '';
  let valueIndex = 0;

  for (let i = 0; i < mask.length && valueIndex < value.length; i++) {
    const maskChar = mask[i];
    const valueChar = value.charAt(valueIndex);

    if (typeof maskChar === 'string') {
      if (maskChar === valueChar) {
        maskedValue += maskChar;
        valueIndex++;
      } else {
        maskedValue += maskChar;
      }
    } else if (maskChar instanceof RegExp) {
      if (maskChar.test(valueChar)) {
        maskedValue += valueChar;
        valueIndex++;
      }
    }
  }

  return maskedValue;
};

export function getFullName(name: {
  legal_first_name: string;
  legal_middle_name?: string | null;
  legal_last_name: string;
}) {
  const aux = [];

  aux.push(name.legal_first_name);
  name.legal_middle_name && aux.push(name.legal_middle_name);
  aux.push(name.legal_last_name);

  return aux.join(' ');
}

export function getConfirmedCounter(data: any): string {
  return data.employee_capacity === null ? '' : `[${data.employeesConfirmed}/${data.employee_capacity}]`;
}

export function compareArrays(arr1: any[], arr2: any[]) {
  return !isEqualWith(arr1, arr2, (obj1, obj2) => {
    if (isPlainObject(obj1) && isPlainObject(obj2)) {
      return isEqual(omit(obj1, ['hidden']), omit(obj2, ['hidden']));
    }
  });
}
