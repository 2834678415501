import { useEffect, useState, useRef } from 'react';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

interface Statistic {
  [key: string]: any;
}

interface UseStatsListParams {
  statsPath: string;
  itemsKey: string;
  employeeId: number;
  itemsPerPage?: number;
}

const useStatsList = ({ statsPath, itemsKey, employeeId, itemsPerPage }: UseStatsListParams) => {
  const api = useApi();
  const [items, setItems] = useState<Statistic[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const isInitialLoad = useRef(true);

  const fetchItems = async (currentPage: number) => {
    try {
      const queryBody = { employeeId, listing: { page: currentPage, limit: itemsPerPage } };
      const response = await api.fetchData(endpoints.EMPLOYEES_STATS.get(), queryBody);
      const { stats } = response;
      const data = stats[statsPath];
      const newItems = data[itemsKey];

      setItems(prev => [...prev, ...newItems]);
      setCurrentPage(data.page);
      setTotalItems(data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isInitialLoad.current) {
      fetchItems(currentPage);
      isInitialLoad.current = false;
    }
  }, []);

  const handleLoadMore = () => {
    setCurrentPage(prev => prev + 1);
    fetchItems(currentPage + 1);
  };

  return {
    items,
    total: totalItems,
    isLoading: api.isLoading,
    handleLoadMore
  };
};

export default useStatsList;
