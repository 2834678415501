import React from 'react';
import { capitalize } from 'helpers/text';
import { Tag } from 'components';
import { AlcoholCertificationsStatusTypes } from 'types/alcoholCertification';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modals';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

type Props = {
  value: {
    status: AlcoholCertificationsStatusTypes;
    employee_id: number;
  };
  handleRefresh: () => void;
};

const options = {
  [AlcoholCertificationsStatusTypes.COMPLETED]: 'success',
  [AlcoholCertificationsStatusTypes.PENDING_APPROVAL]: 'primary',
  [AlcoholCertificationsStatusTypes.INCOMPLETE]: 'secondary',
  [AlcoholCertificationsStatusTypes.REJECTED]: 'error'
};

const AlcoholCertificationStatus = ({ value, handleRefresh }: Props) => {
  const api = useApi();
  const modal = useModal();

  const handleClick = () => {
    modal.open(
      ModalsList.ALOCHOL_CERTIFICATION,
      async data => {
        if (data) {
          await api.fetchData(endpoints.EDIT_CERTIFICATION_STATUS.get(), data);
          handleRefresh();
        }
      },
      {
        employeeId: value.employee_id,
        status: value.status
      }
    );
  };

  if (value?.status) {
    return (
      <Tag variant={options[value.status] as any} onClick={handleClick}>
        {capitalize(value.status)}
      </Tag>
    );
  }

  return (
    <Tag variant="secondary" onClick={handleClick}>
      Not started
    </Tag>
  );
};

export default AlcoholCertificationStatus;
