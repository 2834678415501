import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View payment',
  endpoint: endpoints.GET_PAYMENT,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Payroll', key: 'payroll_id', type: ComponentList.TEXT },
    { label: 'Employee', key: 'employee', type: ComponentList.EMPLOYEE_NAME },
    { label: 'Check Payment Id', key: 'check_payment_id', type: ComponentList.TEXT_COPY }
  ]
};
