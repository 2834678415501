import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';
import { AlcoholCertificationsStatusTypes } from 'types/alcoholCertification';

export default {
  alcoholCertification: {
    value: '',
    label: 'Alcohol Certification',
    input: InputsEnum.SELECT,
    options: [
      { label: 'Not Started', value: AlcoholCertificationsStatusTypes.INCOMPLETE },
      { label: 'Pending Approval', value: AlcoholCertificationsStatusTypes.PENDING_APPROVAL },
      { label: 'Completed', value: AlcoholCertificationsStatusTypes.COMPLETED },
      { label: 'Rejected', value: AlcoholCertificationsStatusTypes.REJECTED }
    ],
    disabled: false,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
