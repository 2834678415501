import { combineReducers } from 'redux';
import general from './general';
import user from './user';
import tables from './table';

const index = combineReducers({
  general,
  user,
  tables
});

export default index;
