/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import useApi, { Methods } from 'hooks/useApi';
import useDebounce from 'hooks/useDebounce';
import useStateRef from 'hooks/useStateRef';
import { useTableReturn, Config, Props, Sort, sortBy } from 'types/table';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/store';
import { initializeTable, toggleColumn } from 'store/actions/table';
import { compareArrays } from 'helpers/data';

const RELOAD_DELAY = 5000;
const limits = [10, 20, 50, 100];

const useTable = ({
  options,
  selected,
  extraFilters: _extraFilters,
  onColumnChanged,
  queryParams,
  searchQuery,
  alternative = false,
  onSave
}: Props): useTableReturn => {
  const {
    columns,
    sort,
    endpoint,
    menuButtons,
    buttons,
    searchable: _searchable,
    data,
    itemsPerPage,
    selectAll,
    selectOptionColumn = [],
    hideFooter = false,
    tableKey
  } = options;
  const [config, setConfig] = useState<Config>({
    sort: sort as Sort,
    limit: itemsPerPage || limits[0],
    page: 1,
    filtersSelected: {}
  });
  const dispatch = useDispatch();
  const tableColumns = useSelector((state: RootState) => state.tables[tableKey!].columns);
  const [tableData, setTableData] = useState<any>();
  const [searchable, setSearchable] = useState<boolean>(_searchable || false);
  const [defaultFilters, setDefaultFilters] = useState<any>();
  const [reloading, setReloading] = useState(false);
  const [search, setSearch] = useState<string>(searchQuery || '');
  const [columnSelects, setColumnSelects] = useState<any>({});
  const searchDebounced = useDebounce(search);
  const extraFilters = useStateRef(_extraFilters);
  const { fetchData, loaded, isLoading } = useApi();
  const totalPages = !tableData ? -1 : Math.ceil(tableData.total / tableData.limit);
  const handleSort = (column: string) => () => {
    const order =
      config.sort.column === column ? (config.sort.order === sortBy.ASC ? sortBy.DESC : sortBy.ASC) : sortBy.ASC;

    setConfig({ ...config, sort: { column, order } });
  };

  const handleHideColumn = (columnId: string) => {
    dispatch(toggleColumn(tableKey!, columnId));
  };

  const handleFilter = (key: string, value: any) => {
    setConfig({
      ...config,
      page: 1,
      filtersSelected: {
        ...config.filtersSelected,
        [key]: value === -1 ? undefined : value
      }
    });
  };

  const getTable = async (getFilters: boolean = false) => {
    let filter = Object.entries(config.filtersSelected)
      .map(e => ({ column: e[0], value: e[1] }))
      .filter(e => e.value !== undefined);

    if (extraFilters.value) {
      filter = [...filter, ...extraFilters.value];
    }

    if (searchDebounced) {
      filter = [...filter, { column: 'all', value: searchDebounced }];
    }

    if (endpoint) {
      const response = await fetchData(
        { endpoint, method: Methods.POST },
        { getFilters, sort: config.sort, filter, page: config.page, limit: config.limit, params: queryParams }
      );
      setTableData(response);
    }
  };

  const handleSelect = (row: any, checked: boolean) => {
    if (checked) {
      selected!.change([...selected!.value, row]);
    } else {
      selected!.change([...selected!.value.filter(e => e.id !== row.id)]);
    }
  };

  const handleRefresh = () => {
    setReloading(true);
    getTable(false);

    setTimeout(() => {
      setReloading(false);
    }, RELOAD_DELAY);
  };

  const handleGoPrev = () => setConfig({ ...config, page: config.page - 1 });

  const handleGoNext = () => setConfig({ ...config, page: config.page + 1 });

  const setNewLimit = (k: number) => () => setConfig({ ...config, page: 1, limit: k });

  const handleColumnsSelect = (column: string) => (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    const aux: any = {};

    for (const col of Object.keys(columnSelects)) {
      aux[col] = column === col ? newValue : columnSelects[col] === newValue ? 'empty' : columnSelects[col];
    }

    setColumnSelects(aux);
  };

  const toggleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selected?.change(!data ? tableData.items : data);
    } else {
      selected?.change([]);
    }
  };

  const setSearchableBar = (value: boolean) => {
    setSearchable(value);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setConfig({ ...config, page: 1 });
  };

  useEffect(() => {
    if (compareArrays(tableColumns, columns)) {
      dispatch(initializeTable(tableKey!, columns));
    }
  }, []);

  useEffect(() => {
    !!endpoint && getTable(true);
  }, []);

  useEffect(() => {
    !!endpoint && loaded && getTable();
  }, [queryParams]);

  useEffect(() => {
    if (extraFilters.prev && !isEqual(extraFilters.value, extraFilters.prev)) {
      getTable();
    }
  }, [extraFilters.value]);

  useEffect(() => {
    extraFilters.update(_extraFilters);
  }, [_extraFilters]);

  useEffect(() => {
    loaded && getTable();
  }, [config]);

  useEffect(() => {
    loaded && getTable();
  }, [searchDebounced]);

  useEffect(() => {
    if (tableData && tableData.filters) {
      setDefaultFilters(tableData.filters);
    }
  }, [tableData]);

  useEffect(() => {
    if (columnSelects) {
      const aux: any = {};

      for (const { id } of columns) {
        aux[id] = 'empty';
      }

      setColumnSelects(aux);
    }
  }, []);

  useEffect(() => {
    !!onColumnChanged && onColumnChanged(columnSelects);
  }, [columnSelects]);

  return {
    alternative,
    buttons,
    config,
    columns: tableColumns as any,
    columnSelects,
    defaultFilters,
    selectOptionColumn,
    limits,
    reloading,
    search,
    setSearch: handleSearch,
    menuButtons,
    searchable,
    selected,
    loaded,
    isLoading,
    data,
    tableData,
    setTableData,
    selectAll,
    setNewLimit,
    totalPages,
    handleColumnsSelect,
    hideFooter,
    toggleSelectAll,
    handleFilter,
    handleSelect,
    handleSort,
    handleRefresh,
    handleGoPrev,
    handleGoNext,
    setSearchableBar,
    handleHideColumn,
    onSave
  };
};

export default useTable;
