import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const GetStateModal = ({ closing, onClose }: Props) => {
  const api = useApi();
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({ id: +formHandler.data.state, backgroundCheck: formHandler.data.backgroundCheck });
  };

  useEffect(() => {
    if (api.response) {
      formHandler.setSchema({
        ...schema,
        state: {
          ...schema.state,
          disabled: false,
          options: [...api.response.items.map((item: any) => ({ label: item.name, value: item.id }))]
        }
      });
    }
  }, [api.response]);

  useEffect(() => {
    api.fetchData(endpoints.GET_ACTIVE_STATES.get());
  }, []);

  return (
    <Base
      header="Select state"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Done" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default GetStateModal;
