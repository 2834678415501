import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View venue',
  endpoint: endpoints.GET_VENUE,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Name', key: 'name', type: ComponentList.TEXT },
    { label: 'Address', key: 'planeAddress', type: ComponentList.TEXT_WITH_BREAKS },
    { label: 'Active?', key: 'is_active', type: ComponentList.YES_OR_NO },
    { label: 'Account', key: 'account_id', type: ComponentList.TEXT },
    { label: 'Check workplace ID', key: 'check_workplace_id', type: ComponentList.TEXT_COPY },
    { label: 'Client ask', key: 'stats.percentageClientAsk', type: ComponentList.PERCENTAGE },
    { label: 'Confirmation rate', key: 'stats.percentageConfirmationRate', type: ComponentList.PERCENTAGE },
    { label: 'Dropout rate', key: 'stats.percentageDropOutRate', type: ComponentList.PERCENTAGE },
    { label: 'Notion', key: 'site_url', type: ComponentList.NOTION_LINK }
  ]
};
