import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { blockNonNumericCharacters } from 'helpers/input';
import { CounterInputProps } from 'types/dynamicInputs';

const Counter = ({
  placeholder,
  label,
  name,
  value,
  onChange,
  onBlur,
  limit,
  error,
  required = false,
  pristine = true,
  disabled = false
}: CounterInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (value: any, name: string) => {
    onChange(value !== '' ? +value : value, name);
  };

  useEffect(() => {
    const inputElement = inputRef.current;

    inputElement && inputElement.addEventListener('keypress', blockNonNumericCharacters);

    return () => {
      inputElement && inputElement.removeEventListener('keypress', blockNonNumericCharacters);
    };
  }, []);

  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <input
        className="body-2"
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={({ target }) => handleChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={limit}
        ref={inputRef}
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default Counter;
