import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View employee',
  endpoint: endpoints.GET_EMPLOYEE,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Legal first name', key: 'legal_first_name', type: ComponentList.TEXT },
    { label: 'Legal middle name', key: 'legal_middle_name', type: ComponentList.TEXT },
    { label: 'Legal last name', key: 'legal_last_name', type: ComponentList.TEXT },
    { label: 'Email', key: 'user.email', type: ComponentList.TEXT },
    { label: 'Phone', key: 'user.phone_number', type: ComponentList.TEXT },
    { label: 'T-shirt size', key: 'tshirt_size', type: ComponentList.TEXT },
    { label: 'Residential address', key: 'residentialAddress', type: ComponentList.TEXT_WITH_BREAKS },
    { label: 'Mailing address', key: 'mailingAddress', type: ComponentList.TEXT_WITH_BREAKS },
    { label: 'Date of birth', key: 'date_of_birth', type: ComponentList.DATE },
    {
      label: 'Truncated taxpayer identification number',
      key: 'truncated_taxpayer_identification_number',
      type: ComponentList.TEXT
    },
    { label: 'Check payment method', key: 'check_payment_method', type: ComponentList.TEXT },
    { label: 'Check employee id', key: 'check_employee_id', type: ComponentList.TEXT },
    { label: 'Background check', key: 'backgroundCheck', type: ComponentList.BACKGROUND_CHECK_INFO },
    { label: 'Venues', key: 'venues', type: ComponentList.VENUES }
  ]
};
