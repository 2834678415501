import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  reason: {
    value: '',
    label: 'Reason (Optional)',
    limit: 250,
    input: InputsEnum.ALTERNATIVE_TEXTAREA,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
