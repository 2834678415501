import React from 'react';
import classnames from 'classnames';
import { RichTextProps } from 'types/dynamicInputs';
import RichText from 'components/common/richText';

const RichTextInput = ({
  value,
  placeholder,
  onChange,
  label,
  onBlur,
  error,
  required = true,
  pristine = true
}: RichTextProps) => {
  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      <RichText
        label={label}
        placeholder={placeholder}
        onBlur={onBlur}
        required={required}
        value={value}
        onChange={onChange}
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default RichTextInput;
