import React, { useEffect } from 'react';
import { Calendar, MoneyTick, Wallet3, Warning2 } from 'iconsax-react';
import Tag, { Variants } from 'components/common/tag';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Props } from '../tabs/types';
import { formatToUSD, getRankingVariant } from 'utils/data';
import { RANKING } from 'constants/user';

type ElementProps = {
  value: string;
  text: string;
  icon: React.ReactNode;
  ranking?: string;
};

const Element = ({ icon, text, value, ranking }: ElementProps) => {
  return (
    <div className="element">
      {icon}
      <div>
        <div className="values">
          <div className="heading-lg strong">{value ?? '-'}</div>
          {ranking && ranking !== RANKING.UNRANKED && (
            <Tag variant={getRankingVariant(ranking) as Variants}>{ranking}</Tag>
          )}
        </div>
        <span className="element-text body">{text}</span>
      </div>
    </div>
  );
};

const Summary = ({ employeeId }: Props) => {
  const api = useApi();
  const response = api?.response?.stats;

  useEffect(() => {
    api.fetchData(endpoints.EMPLOYEES_STATS.get(), { employeeId, listing: { page: 1 } });
  }, []);

  return (
    <div className="summary">
      <Element
        icon={<Calendar size={24} />}
        value={response?.eventsWorked}
        text="Events Worked"
        ranking={response?.ranking}
      />
      <Element icon={<MoneyTick size={24} />} value={response?.paymentsDone} text="Payments Done" />
      <Element icon={<Warning2 size={24} />} value={response?.writtenWarnings} text="Written Warnings" />
      <Element icon={<Wallet3 size={24} />} value={formatToUSD(response?.moneyEarned)} text="Money Earned" />
    </div>
  );
};

export default Summary;
