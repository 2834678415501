import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View tag',
  endpoint: endpoints.GET_TAG,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Name', key: 'name', type: ComponentList.TEXT },
    { label: 'Created at', key: 'created_at', type: ComponentList.DATE_TIME },
    { label: 'Updated at', key: 'updated_at', type: ComponentList.DATE_TIME }
  ]
};
