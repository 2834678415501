import { LOG_IN, LOG_OUT, SET_TOKEN, UserState, UserActionTypes, SET_CHANNEL_URL, SET_HAS_CHATS } from 'types/store';

const initialState: UserState = {
  isLogged: false,
  data: {},
  token: '',
  refreshToken: '',
  channelUrl: '',
  hasChats: false
};

const user = (state: UserState = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        isLogged: true,
        data: action.data,
        token: action.token,
        refreshToken: action.refreshToken
      };
    case LOG_OUT:
      return {
        ...state,
        isLogged: false,
        token: '',
        refreshToken: '',
        verifyToken: ''
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case SET_CHANNEL_URL:
      return {
        ...state,
        channelUrl: action.channelUrl
      };
    case SET_HAS_CHATS: {
      return {
        ...state,
        hasChats: action.hasChats
      };
    }
    default:
      return state;
  }
};

export default user;
