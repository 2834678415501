import React, { useEffect } from 'react';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

const EmployeeNotes = ({ closing, onClose, params }: Props) => {
  const api = useApi();

  useEffect(() => {
    const { eventId, employeeId } = params;
    api.fetchData(endpoints.GET_EMPLOYEE_NOTES.get({ eventId, employeeId }));
  }, []);

  return (
    <Base
      header="View Note"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Close" onClick={onClose} />}
    >
      <p>{api.isLoading ? 'Loading...' : api.response?.data || 'No notes have been sent'}</p>
    </Base>
  );
};

export default EmployeeNotes;
