import joi from 'joi';
import endpoints from 'constants/endpoints';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  employees: {
    value: [],
    label: 'Employee(s)',
    input: InputsEnum.MULTI_SELECT,
    avoidFirstLoad: true,
    endpoint: endpoints.SEARCH_EMPLOYEE_FOR_REGISTRATION,
    joi: joi.array().min(1).required().messages({
      'array.base': formErrors.REQUIRED,
      'array.min': formErrors.REQUIRED
    })
  }
};
