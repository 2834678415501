import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionAreaNameModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    formHandler.setFullForm({ name: params.name });
  }, []);

  return (
    <Base
      header="Edit area name"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={() => {
            formHandler.setIsSubmitting(true);
            onClose(formHandler.data);
          }}
        />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionAreaNameModal;
