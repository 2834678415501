import React, { useEffect, useState } from 'react';
import useStateRef from '@revolt-digital/use-state-ref';
import { Button, DynamicForm, Selector } from 'components';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import endpoints from 'constants/endpoints';
import { Props } from 'types/modals';
import { PositionRate } from 'types/rates';
import Base from '../../base';
import schema from './schema';
import { dollarized } from 'utils/data';

const EditionRatesAndTipsModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const [currentPosition, setCurrentPosition, prevPosition] = useStateRef(-1);
  const [data, setData] = useState<PositionRate[]>([]);
  const api = useApi();

  const handleClose = () => {
    formHandler.setIsSubmitting(true);
    const aux = [...data];
    aux[currentPosition.current] = { ...aux[currentPosition.current], rate: formHandler.data };
    onClose(aux);
  };

  useEffect(() => {
    if (currentPosition.current > -1) {
      if (prevPosition > -1) {
        const aux = [...data];
        aux[prevPosition] = { ...aux[prevPosition], rate: formHandler.data };
        setData(aux);
      }

      const aux = data[currentPosition.current].rate;
      aux.guaranteed_tips = dollarized(aux.guaranteed_tips as string);
      aux.guaranteed_base = dollarized(aux.guaranteed_base as string);
      aux.hourly_tip_rate = dollarized(aux.hourly_tip_rate as string);
      aux.hourly_non_tip_rate = dollarized(aux.hourly_non_tip_rate as string);

      formHandler.setFullForm(aux);
    }
  }, [currentPosition.current]);

  useEffect(() => {
    formHandler.data.tip_match && formHandler.setFullForm({ tippable: false });
  }, [formHandler.data.tip_match]);

  useEffect(() => {
    formHandler.data.tippable && formHandler.setFullForm({ tip_match: false });
  }, [formHandler.data.tippable]);

  useEffect(() => {
    if (api.response) {
      setData(api.response.data);
      setCurrentPosition(0);
    }
  }, [api.response]);

  useEffect(() => {
    api.fetchData(endpoints.GET_VENUE_RATE_AND_TIPS.get(params));
  }, []);

  return (
    <Base
      header="Rates and tips"
      className="edition-rates-and-tips-modal"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Save changes" onClick={handleClose} disabled={api.isLoading || formHandler.isSubmitting} />}
    >
      {!data.length ? (
        <span>Loading..</span>
      ) : (
        <>
          <Selector
            items={data.map((item: any) => item.name)}
            selected={currentPosition.current}
            setSelected={setCurrentPosition}
          />
          <div className="form">
            <DynamicForm handler={formHandler} />
          </div>
        </>
      )}
    </Base>
  );
};

export default EditionRatesAndTipsModal;
