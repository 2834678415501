import React from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AlternativeTable } from 'components';
import useStatsList from 'hooks/useStatsList';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { PositionStatsType, columns } from './columns';
import { Props } from '../types';

const statsPath = 'positionStats';
const itemsKey = 'positionStatistics';

const PositionStats = ({ employeeId }: Props) => {
  const { items, total, isLoading, handleLoadMore } = useStatsList({
    statsPath,
    itemsKey,
    itemsPerPage: 10,
    employeeId
  });

  const table = useReactTable({
    data: items as PositionStatsType[],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const { containerRef, fetchMoreOnBottomReached } = useInfinityScroll({
    onLoadMore: handleLoadMore,
    totalDBRowCount: total,
    totalFetched: items.length,
    isFetching: isLoading
  });

  return (
    <AlternativeTable
      config={table}
      ref={containerRef}
      fetchMoreOnBottomReached={fetchMoreOnBottomReached}
      isLoading={isLoading}
    />
  );
};

export default PositionStats;
