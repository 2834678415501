import React from 'react';
import { Tag } from 'components';

type Props = {
  value: {
    jobType: number;
    jobTypeName: string;
    positionId: number;
    positionName: string;
  }[];
};

const Positions = ({ value }: Props) => {
  return (
    <div className="positions-tags">
      {value.map((item, index) => (
        <Tag key={index}>{item.jobTypeName === 'Staff' ? item.positionName : item.jobTypeName}</Tag>
      ))}
    </div>
  );
};

export default Positions;
