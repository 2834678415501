import React from 'react';

type Props = {
  value: {
    legal_first_name: string;
    legal_middle_name?: string;
    legal_last_name: string;
  };
};

const EmployeeName = ({ value }: Props) => (
  <span>{`${value.legal_first_name} ${value.legal_middle_name || ''} ${value.legal_last_name}`}</span>
);

export default EmployeeName;
