import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  variant?: 'primary' | 'secondary';
}

const SectionCard: React.FC<Props> = ({ children, variant = 'primary' }) => {
  return <div className={`section-card ${variant}`}>{children}</div>;
};

export default SectionCard;
