import { useState } from 'react';

const useDownloadImage = () => {
  const [loading, setLoading] = useState(false);

  const download = (url: string, ext: string = '') => {
    const pathArray = url.split('/');
    const fileName = pathArray[pathArray.length - 1];

    setLoading(true);

    fetch(`${url}?cacheblock=true`, {
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName + ext;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch(() => {
        alert('An error sorry');
        setLoading(false);
      });
  };

  return { download, loading };
};

export default useDownloadImage;
