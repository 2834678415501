import joi from 'joi';
import endpoints from 'constants/endpoints';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  venues: {
    value: [],
    label: 'Venues',
    input: InputsEnum.MULTI_SELECT,
    required: true,
    endpoint: endpoints.SEARCH_VENUES,
    joi: joi.array().min(1).required()
  }
};
