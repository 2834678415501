import React, { useEffect, useState, useCallback } from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AlternativeTable, Button, Selector } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import { columns } from './columns';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import classNames from 'classnames';

const VenueStatsModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const [data, setData] = useState([]);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<number>();

  const fetchData = useCallback(async (tagId: number | null = null) => {
    const res = await api.fetchData(endpoints.GET_VENUE_STATS.get(), {
      venueId: +params.id,
      tagId
    });
    return res;
  }, []);

  useEffect(() => {
    fetchData().then(res => {
      if (res) {
        setData(res.data);
        setTags(res.tags);
        setSelectedId(res.tags[0]?.id);
      }
    });
  }, [fetchData]);

  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <Base
      header="Venue Stats"
      closing={closing}
      onClose={() => onClose(false)}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose(false)} />}
    >
      <Selector
        items={tags}
        selected={selectedId?.toString()}
        displayProperty="name"
        valueProperty="id"
        setSelected={id => {
          setSelectedId(Number(id));
          fetchData(Number(id)).then(res => {
            if (res) setData(res.data);
          });
        }}
        perPage={4}
      />
      <div className="venue-stats">
        <div
          className={classNames('table-container', {
            'is-loading': api.isLoading
          })}
        >
          {api.isLoading ? (
            <div>Loading...</div>
          ) : (
            <AlternativeTable className="custom-table" config={tableInstance} layout="auto" />
          )}
        </div>
      </div>
    </Base>
  );
};

export default VenueStatsModal;
