import joi from 'joi';
import { moneyMask } from 'constants/masks';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  guaranteed_tips: {
    value: '',
    label: 'Guaranteed Tips',
    input: InputsEnum.MASKED,
    mask: moneyMask,
    joi: joi.string().required().messages({
      'string.empty': formErrors.INVALID_NUMBER
    })
  },
  guaranteed_base: {
    value: '',
    label: 'Guaranteed Base',
    input: InputsEnum.MASKED,
    mask: moneyMask,
    joi: joi.string().required().messages({
      'string.empty': formErrors.INVALID_NUMBER
    })
  },
  hourly_tip_rate: {
    value: '',
    label: 'Hourly Tip-Rate',
    input: InputsEnum.MASKED,
    mask: moneyMask,
    joi: joi.string().required().messages({
      'string.empty': formErrors.INVALID_NUMBER
    })
  },
  hourly_non_tip_rate: {
    value: '',
    label: 'Hourly Non Tip-Rate',
    input: InputsEnum.MASKED,
    mask: moneyMask,
    joi: joi.string().required().messages({
      'string.empty': formErrors.INVALID_NUMBER
    })
  },
  tip_match: {
    value: false,
    label: 'Tip Match',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  },
  tippable: {
    value: false,
    label: 'Tippable',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  }
};
