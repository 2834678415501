import classnames from 'classnames';
import React from 'react';
import { TextareaProps } from 'types/dynamicInputs';

const Textarea = ({
  label,
  value,
  name,
  onChange,
  onBlur,
  error,
  required = true,
  pristine = true,
  disabled = false
}: TextareaProps) => (
  <div className={classnames('input-wrapper', { error: !pristine && error })}>
    {!!label && (
      <label className="label">
        {label}
        {required ? ' *' : ''}
      </label>
    )}
    <textarea
      className="body-2"
      name={name}
      value={value}
      onChange={({ target }) => onChange(target.value, target.name)}
      onBlur={onBlur}
      disabled={disabled}
    />
    {!pristine && !!error && <span className="error-message body-2">{error}</span>}
  </div>
);

export default React.memo(Textarea);
