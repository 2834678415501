import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';
import { parsePercentage } from 'utils/data';

const isNullOrEmpty = (value: string) => value === null || value === '';

const isValidPercentage = (percentage: string | number) => {
  return percentage || percentage === 0;
};

const EditionAreaPoolableTipPercentage = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    formHandler.setFullForm({
      poolableTipPercentage: !isNullOrEmpty(params.percentage) ? `${String(params.percentage)}%` : ''
    });
  }, []);

  return (
    <Base
      header="Poolable Tip Percentage"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={() => {
            formHandler.setIsSubmitting(true);
            const percentage = parsePercentage(formHandler.data.poolableTipPercentage);
            onClose({ poolableTipPercentage: isValidPercentage(percentage) ? percentage : null });
          }}
        />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionAreaPoolableTipPercentage;
