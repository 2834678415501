import React from 'react';
import { Button } from 'components';
import { useModal } from 'hooks/useModal';
import { ModalsList } from 'types/modals';

type Props = {
  value: boolean;
  row: any;
};

const PayrollFeedback = ({ value, row }: Props) => {
  const modal = useModal();

  const handleClick = () => {
    modal.open(ModalsList.PAYROLL_FEEDBACK, undefined, {
      id: row.id
    });
  };

  if (value) {
    return <Button text="View" variant="secondary" small onClick={handleClick} />;
  } else {
    return <span>-</span>;
  }
};

export default PayrollFeedback;
