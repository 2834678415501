import React, { useRef } from 'react';

type Props = {
  row: any;
  columnId: string;
  value: string;
  onSave: (data: any) => void;
  setTableData: React.Dispatch<any>;
};

const EditableCell = ({ row, value, columnId, setTableData, onSave }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialValueRef = useRef<string>(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableData((prev: any) => {
      return {
        ...prev,
        items: prev.items.map((item: any) => {
          if (item.id === row.id) {
            return {
              ...item,
              [columnId]: e.target.value
            };
          }
          return item;
        })
      };
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleBlur = () => {
    if (initialValueRef.current !== value) {
      onSave(row);
      initialValueRef.current = value;
    }
  };

  return (
    <div className="editable-cell">
      <input
        ref={inputRef}
        type="text"
        value={value === null ? '' : value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default EditableCell;
