export const tableTypes = [
  'accounts',
  'appeals',
  'tags',
  'earnings',
  'venues',
  'events',
  'currentEvents',
  'pastEvents',
  'employees',
  'employeesOnBoarding',
  'users',
  'payrolls',
  'payments',
  'positions',
  'notices',
  'states',
  'eventRegistrations',
  'eventConfirmed',
  'eventDropout',
  'eventSentConfirmationTexts',
  'eventPayrolls',
  'eventTips',
  'eventEmployees',
  'allocations',
  'adminPayrolls',
  'salary',
  'hourly',
  'warnings',
  'warningsArchived',
  'trainings',
  'templates',
  'appealsArchived',
  'terminations',
  'terminationsArchived',
  'notifications',
  'applications'
];
