import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import { emptyRichText, excludeTagsRegex } from 'constants/regex';
import endpoints from 'constants/endpoints';

export const triggers = {
  ALL_EMPLOYEES: 'all_employees',
  ONLY_EMPLOYEES_FROM_ACCOUNT: 'account',
  ONLY_EMPLOYEES_FROM_VENUE: 'venue'
} as const;

export default {
  trigger: {
    value: '',
    label: 'Send to',
    limit: 80,
    input: InputsEnum.SELECT,
    options: [
      { value: triggers.ALL_EMPLOYEES, label: 'All Employees' },
      { value: triggers.ONLY_EMPLOYEES_FROM_ACCOUNT, label: 'Only Employees from Account' },
      { value: triggers.ONLY_EMPLOYEES_FROM_VENUE, label: 'Only Employees from Venue' }
    ],
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  account: {
    value: '',
    label: 'Account',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_ACCOUNT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  venue: {
    value: '',
    label: 'Venue',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_VENUE,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  title: {
    value: '',
    label: 'Title',
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  message: {
    value: '',
    label: 'Message',
    input: InputsEnum.RICH_TEXT,
    joi: joi
      .string()
      .regex(RegExp(emptyRichText), { invert: true })
      .replace(excludeTagsRegex, '')
      .max(1200)
      .required()
      .messages({
        'string.pattern.invert.base': formErrors.REQUIRED,
        'string.max': formErrors.INVALID_LENGTH
      })
  }
};
