import { JobType } from 'constants/jobType';
import { get } from 'lodash';

export const getLeaders = (data: any[], jobType?: number) => {
  const filtered = jobType !== undefined ? data.filter(element => element.job_type === jobType) : data;

  const modifiedArray = filtered.flatMap((element, index) => {
    if (element.jobs && element.jobs.length > 0) {
      return element.jobs.map((job: any) => ({
        ...element,
        id: index + 1,
        name: `${job.employee.legal_first_name} ${job.employee.legal_last_name} - ${element.position.name}`,
        hasPosition: true,
        jobsQuantity: element.jobs.length
      }));
    } else {
      return [
        {
          ...element,
          id: index + 1,
          name: element.position.name,
          hasPosition: false,
          jobsQuantity: 0,
          total: element.total
        }
      ];
    }
  });

  return modifiedArray;
};

export const getStaffPositions = (data: any[]) => {
  const staffPositions = data.filter(element => element.job_type === JobType.STAFF);
  const modifiedArray = staffPositions.map(element => ({
    ...element,
    name: element.position.name,
    hasPosition: false,
    staffQuantity: element.jobs.length,
    total: element.total
  }));

  return modifiedArray;
};

type Params = {
  data: any;
  areaIndex: number;
  standIndex: number;
  positionId?: number;
};

export const getWorkersNeeded = ({ data, areaIndex, standIndex, positionId }: Params) => {
  return get(data, `areas[${areaIndex}].stands[${standIndex}].positions`)
    .filter((item: any) => item.job_type === JobType.STAFF && item.position_id === positionId)
    .find((item: any) => item.position_id === positionId).total;
};

export const getAssignedWorkers = ({ data, areaIndex, standIndex, positionId }: Params) => {
  return get(data, `areas[${areaIndex}].stands[${standIndex}].positions`)
    .filter((item: any) => item.job_type === JobType.STAFF && item.position_id === positionId)
    .flatMap((item: any) => item.jobs.map((job: any) => job.employee));
};

export const getStandName = ({ data, areaIndex, standIndex }: Params) => {
  return get(data, `areas[${areaIndex}].stands[${standIndex}].name`);
};

export const getPositionName = ({ data, areaIndex, standIndex, positionId }: Params) => {
  return get(data, `areas[${areaIndex}].stands[${standIndex}].positions`).find(
    (item: any) => item.position_id === positionId
  ).position.name;
};
