import React from 'react';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import useFormHandler from 'hooks/useFormHandler';
import Base from '../../base';
import schema from './schema';

const CreationStandModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  return (
    <Base
      header="Add stand"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Create"
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={() => {
            formHandler.setIsSubmitting(true);
            onClose(formHandler.data);
          }}
        />
      }
    >
      <div className="modal-tag">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationStandModal;
