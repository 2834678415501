import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  statuses: {
    value: [],
    label: 'Status(es)',
    input: InputsEnum.MULTI_SELECT,
    staticOptions: [
      { label: 'Welcome', value: 'welcome' },
      { label: 'Identity', value: 'identity' },
      { label: 'Address', value: 'address' },
      { label: 'Venues', value: 'venues' },
      { label: 'Notices', value: 'notices' },
      { label: 'Payment', value: 'payment' },
      { label: 'Taxes', value: 'taxes' },
      { label: 'i9 section one', value: 'i9_section_one' },
      { label: 'i9 section two', value: 'i9_section_two' },
      { label: 'Complete', value: 'complete' }
    ],
    avoidFirstLoad: true,
    joi: joi.array().min(1).required().messages({
      'array.base': formErrors.REQUIRED,
      'array.min': formErrors.REQUIRED
    })
  }
};
