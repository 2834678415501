import React, { useEffect } from 'react';
import { Button, DateTimeFormat } from 'components';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Props } from 'types/modals';
import Base from '../../base';

const IncidentReportModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const isLoading = !api.loaded;
  const reports: { created_at: string; description: string }[] = api.response?.items || [];

  useEffect(() => {
    api.fetchData(endpoints.GET_INCIDENT_REPORTS.get({ id: params.id }));
  }, []);

  return (
    <Base
      header="Incident Report"
      className="incident-report-modal"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose(false)} />}
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : reports.length > 0 ? (
        <>
          {reports.map((item, index) => (
            <div className="item body-1" key={index}>
              <span className="body-2">
                <DateTimeFormat value={item.created_at} />
              </span>
              <div>{item.description}</div>
            </div>
          ))}
        </>
      ) : (
        <div>Nothing reported...</div>
      )}
    </Base>
  );
};

export default IncidentReportModal;
