import joi from 'joi';
import endpoints from 'constants/endpoints';
import { InputsEnum } from 'types/dynamicInputs';
import formErrors from 'constants/formErrors';

export default {
  leaders: {
    value: [],
    input: InputsEnum.MULTI_SELECT,
    endpoint: endpoints.SEARCH_POSITION,
    avoidFirstLoad: true,
    joi: joi.array().messages({
      'array.base': formErrors.REQUIRED,
      'array.min': formErrors.REQUIRED
    })
  }
};
