import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View event',
  timezone: true,
  endpoint: endpoints.GET_EVENT,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Name', key: 'name', type: ComponentList.TEXT },
    { label: 'Venue', key: 'venue.name', type: ComponentList.TEXT },
    { label: 'Venue ID', key: 'venue_id', type: ComponentList.TEXT },
    { label: 'Account', key: 'venue.account.name', type: ComponentList.TEXT },
    { label: 'Held on', key: 'held_on', type: ComponentList.DATE_TIME },
    { label: 'Allow registrations at', key: 'allow_registrations_at', type: ComponentList.DATE_TIME },
    { label: 'Report time at', key: 'report_time', type: ComponentList.DATE_TIME },
    { label: 'Tags', key: 'tags', type: ComponentList.EVENT_TAGS }
  ]
};
