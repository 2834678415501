import React from 'react';

const Percentage = ({ value }: { value: string }) => {
  if (!value) {
    return <span className="body-2">-</span>;
  }

  return (
    <div className="percentage">
      <span className="body-2">{value}%</span>
    </div>
  );
};

export default Percentage;
