import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Select, { SingleValue } from 'react-select';
import useApi from 'hooks/useApi';
import useDebounce from 'hooks/useDebounce';
import { AutocompleteProps, Option } from 'types/dynamicInputs';

const Autocomplete = forwardRef(
  (
    {
      label,
      value,
      endpoint,
      defaultLabel,
      limit = 10,
      onChange,
      onBlur,
      disabled,
      pristine,
      error,
      timezone,
      fullValue = false,
      isClearable
    }: AutocompleteProps,
    ref
  ) => {
    const [query, setQuery] = useState('');
    const qDebounced = useDebounce(query);
    const [currentValue, setCurrentValue] = useState<SingleValue<Option> | null>(null);
    const [options, setOptions] = useState<Option[]>([]);
    const portalRef = useRef(null);
    const api = useApi();

    const handleChange = (value: SingleValue<Option>) => {
      setCurrentValue(value);
    };

    useEffect(() => {
      if (endpoint) {
        api.fetchData(endpoint.get(), { q: qDebounced, limit, timezone });
      }
    }, [qDebounced, endpoint]);

    useEffect(() => {
      if (api.response) {
        setOptions(api.response.items);
      }
    }, [api.response]);

    useEffect(() => {
      if (defaultLabel) {
        setCurrentValue({ value, label: defaultLabel });
      }
    }, [defaultLabel]);

    useEffect(() => {
      if (currentValue) {
        if (fullValue) {
          onChange(currentValue);
        } else {
          onChange(currentValue.value);
        }
      }
    }, [currentValue, fullValue]);

    return (
      <div className="input-wrapper" ref={portalRef}>
        {!!label && <label className="label">{label}</label>}
        <Select
          ref={ref as any}
          className={classnames('autocomplete', { error: !pristine && error })}
          classNamePrefix="search"
          isDisabled={disabled}
          isLoading={api.isLoading}
          isSearchable
          onBlur={onBlur}
          onInputChange={value => setQuery(value)}
          onChange={value => handleChange(value)}
          defaultInputValue={value}
          value={currentValue}
          options={options}
          placeholder={null}
          menuPosition="fixed"
          menuPortalTarget={portalRef.current}
          isClearable={isClearable}
        />
        {!pristine && !!error && <span className="error-message body-2">{error}</span>}
      </div>
    );
  }
);

export default Autocomplete;
