import React, { useEffect, useState } from 'react';
import { toZonedTime } from 'date-fns-tz';
import endpoints from 'constants/endpoints';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { Button, DynamicForm } from 'components';
import { changeTimezoneAndConvertToUTC } from 'helpers/date';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionEventModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();
  const templateApi = useApi();
  const [venueId, setVenueId] = useState(null);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);

    let processedData;
    const { data } = formHandler;

    const { id, timezone } = data.venue;
    const isCapacityExceeded = +data.employeeCapacity < +api.response.data.employeesAllocated;

    if (!params.finishedAt) {
      processedData = {
        ...data,
        venueId: id,
        allowRegistrationsAt: changeTimezoneAndConvertToUTC(data.allowRegistrationsAt, timezone).toISO(),
        heldOn: changeTimezoneAndConvertToUTC(data.heldOn, timezone).toISO(),
        reportTimeAt: data.reportTimeAt && changeTimezoneAndConvertToUTC(data.reportTimeAt, timezone).toISO(),
        employeeCapacity: data.employeeCapacity ? +data.employeeCapacity : null,
        buffer: data.buffer ? +data.buffer : null,
        templateId: +data.templateId ? +data.templateId : null,
        isCapacityExceeded
      };

      delete processedData.venue;
    } else {
      processedData = {
        tags: data.tags.map((item: any) => +item.value),
        employeeCapacity: data.employeeCapacity ? +data.employeeCapacity : null
      };
    }

    onClose(processedData);
  };

  const getTemplates = async (venueId: string) => {
    const resp = templateApi.fetchData(endpoints.SEARCH_TEMPLATES.get({ venueId }));
    return resp;
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_EVENT.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const isDisabled = params?.finishedAt ? true : false;

      const {
        name,
        venue_id,
        allow_registrations_at,
        held_on,
        report_time,
        paymodel_url,
        event_logistics_url,
        event_training_url,
        tags,
        venue,
        employee_capacity,
        buffer,
        automate_roster,
        template_id
      } = api.response.data;

      const data = {
        name: name,
        venue: { id: venue_id, timezone: venue.timezone },
        allowRegistrationsAt: toZonedTime(allow_registrations_at, venue.timezone),
        heldOn: toZonedTime(held_on, venue.timezone),
        employeeCapacity: employee_capacity && employee_capacity > 0 ? String(employee_capacity) : '',
        buffer: buffer && buffer > 0 ? String(buffer) : '',
        reportTimeAt: report_time ? toZonedTime(report_time, venue.timezone) : null,
        payModelUrl: paymodel_url,
        eventLogisticsUrl: event_logistics_url,
        eventTrainingUrl: event_training_url,
        automateRoster: automate_roster,
        tags: tags.map((item: any) => ({ label: item.name, value: item.id })),
        templateId: template_id ? String(template_id) : ''
      };

      getTemplates(formHandler.data.venue.id ? formHandler.data.venue.id : venue_id).then(res => {
        formHandler.setFullSchema(
          {
            ...schema,
            name: { ...schema.name, disabled: isDisabled },
            venue: { ...schema.venue, defaultLabel: venue.name, disabled: isDisabled },
            templateId: { ...schema.templateId, disabled: isDisabled, options: res.items },
            tags: { ...schema.tags, disabled: false },
            heldOn: { ...schema.heldOn, disabled: isDisabled },
            allowRegistrationsAt: { ...schema.allowRegistrationsAt, disabled: isDisabled },
            employeeCapacity: { ...schema.employeeCapacity, disabled: false },
            buffer: { ...schema.buffer, disabled: isDisabled },
            reportTimeAt: { ...schema.reportTimeAt, disabled: isDisabled },
            payModelUrl: { ...schema.payModelUrl, disabled: isDisabled },
            eventLogisticsUrl: { ...schema.eventLogisticsUrl, disabled: isDisabled },
            eventTrainingUrl: { ...schema.eventTrainingUrl, disabled: isDisabled },
            automateRoster: { ...schema.automateRoster, disabled: isDisabled }
          },
          data
        );
        setVenueId(venue_id);
      });
    }
  }, [api.loaded, params?.finishedAt]);

  useEffect(() => {
    if (formHandler.data.venue.id !== venueId) {
      const venueId = formHandler.data.venue.id;

      if (venueId) {
        getTemplates(venueId).then(res => {
          const schema = {
            ...formHandler.schema,
            templateId: { ...formHandler.schema.templateId, options: res.items }
          };
          const data = { ...formHandler.data, templateId: '' };
          formHandler.setFullSchema(schema, data);
          setVenueId(venueId);
        });
      }
    }
  }, [formHandler.data.venue.id]);

  return (
    <Base
      header="Edit event"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionEventModal;
