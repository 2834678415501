import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import endpoints from 'constants/endpoints';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { AlertBanner } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionNoticeModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();
  const apiStates = useApi();
  const apiFile = useApi();
  const apiS3 = useApi();

  const handleSubmit = async () => {
    formHandler.setIsSubmitting(true);
    let fileObject;
    if (formHandler.data.files?.name) {
      const signedUrl = await apiFile.fetchData(endpoints.FILE_NOTICE.get(), { fileName: formHandler.data.files.name });

      apiS3.uploadFileToS3(formHandler.data.files, signedUrl.extension, signedUrl.url);

      fileObject = {
        file_name: signedUrl.fileName,
        extension: signedUrl.extension,
        url: signedUrl.url,
        size: formHandler.data.files.size.toString()
      };
    }

    onClose({
      ...formHandler.data,
      state: formHandler.data.state === null ? 'ALL' : +formHandler.data.state,
      file: fileObject
    });
  };

  useEffect(() => {
    if (api.response) {
      formHandler.setFullForm({
        ...api.response.data,
        state: api.response.data.state ? api.response.data.state.id.toString() : 'ALL'
      });
    }
  }, [api.response]);

  useEffect(() => {
    if (apiStates.response) {
      formHandler.setSchema({
        ...schema,
        state: {
          ...schema.state,
          disabled: false,
          options: [
            { label: 'All', value: 'ALL' },
            ...apiStates.response.items.map((item: any) => ({ label: item.name, value: item.id }))
          ]
        }
      });
    }
  }, [apiStates.response]);

  useEffect(() => {
    api.fetchData(endpoints.GET_NOTICE.get(params));
    apiStates.fetchData(endpoints.GET_ACTIVE_STATES.get());
  }, []);

  return (
    <Base
      header="Edit notice"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <>
        <AlertBanner
          type="warning"
          message="In case it is marked as active, any update you do here will require a confirmation for all the employees that require this notice by the system."
        />
        <DynamicForm handler={formHandler} />
      </>
    </Base>
  );
};

export default EditionNoticeModal;
