import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View archived appeal',
  endpoint: endpoints.GET_APPEAL_ARCHIVED,
  schema: [
    { key: 'id', label: 'ID', type: ComponentList.TEXT },
    { key: 'message', label: 'Message', type: ComponentList.HTML },
    { key: 'file_one', label: 'File #1', type: ComponentList.FILE },
    { key: 'file_two', label: 'File #2', type: ComponentList.FILE },
    { key: 'recipient_name', label: 'Recipient Name', type: ComponentList.TEXT },
    { key: 'recipient_email', label: 'Recipient Email', type: ComponentList.EMAIL },
    { key: 'recipient_phone_number', label: 'Recipient Phone Number', type: ComponentList.PHONE_NUMBER },
    { key: 'warning_type', label: 'Warning Type', type: ComponentList.TEXT },
    { key: 'warning', label: 'Warning', type: ComponentList.TEXT },
    { key: 'objective_status', label: 'Objective Status', type: ComponentList.TEXT },
    { key: 'created_at', label: 'Created At', type: ComponentList.DATE },
    { key: 'event_name', label: 'Event Name', type: ComponentList.TEXT },
    { key: 'event_date', label: 'Event Date', type: ComponentList.DATE },
    { key: 'source_name', label: 'Source Name', type: ComponentList.TEXT },
    { key: 'source_email', label: 'Source Email', type: ComponentList.EMAIL },
    { key: 'source_phone_number', label: 'Source Phone Number', type: ComponentList.PHONE_NUMBER },
    { key: 'accepted', label: 'Accepted', type: ComponentList.YES_OR_NO }
  ]
};
