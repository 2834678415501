import React from 'react';
import { downloadFileUrl } from 'utils/data';

type Props = {
  children: React.ReactNode;
  donwloadUrl: string;
};

const DownloadFile = ({ children, donwloadUrl }: Props) => {
  const handleDownloadFile = () => {
    downloadFileUrl(donwloadUrl, 'application/pdf', 'resume.pdf');
  };

  return (
    <div className="file-download" onClick={handleDownloadFile}>
      {children}
    </div>
  );
};

export default DownloadFile;
