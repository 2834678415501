import React from 'react';
import { Badge } from 'components';

type Props = {
  value: string;
};

const PaymentMethod = ({ value }: Props) => {
  return value === 'manual' ? <Badge value={value} variant="danger" /> : <span>{value}</span>;
};

export default PaymentMethod;
