/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputsEnum } from 'types/dynamicInputs';
import joi from 'joi';
import formErrors from 'constants/formErrors';

export default {
  ids: {
    value: [],
    placeholder: '',
    input: InputsEnum.TAGS_INPUT,
    required: true,
    joi: joi.array().items(joi.string()).min(1).required()
  }
};
