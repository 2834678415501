import joi from 'joi';
import formErrors from 'constants/formErrors';
import { emailRegex, passwordRegex, phoneNumberRegex } from 'constants/regex';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import { phoneNumberMask } from 'constants/masks';
import { ADMIN_ROLES } from 'constants/adminRoles';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  email: {
    value: '',
    label: 'Email',
    limit: 60,
    input: InputsEnum.INPUT,
    type: TextInputTypes.EMAIL,
    joi: joi.string().regex(emailRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_EMAIL,
      'string.empty': formErrors.REQUIRED
    })
  },
  phoneNumber: {
    value: '',
    label: 'Phone number',
    required: true,
    input: InputsEnum.MASKED,
    mask: phoneNumberMask,
    joi: joi.string().regex(phoneNumberRegex).required().messages({
      'string.base': formErrors.INVALID_NUMBER,
      'string.empty': formErrors.REQUIRED,
      'string.length': formErrors.INVALID_LENGTH,
      'string.pattern.base': formErrors.INVALID_LENGTH
    })
  },
  password: {
    value: '',
    label: 'Password',
    required: false,
    input: InputsEnum.INPUT,
    type: TextInputTypes.PASSWORD,
    joi: joi.string().regex(passwordRegex).empty('').messages({
      'string.pattern.base': formErrors.INVALID_PASSWORD
    })
  },
  adminAccess: {
    value: '',
    label: 'Admin Access',
    input: InputsEnum.SELECT,
    options: [
      {
        label: 'Admin Access',
        value: ADMIN_ROLES.ADMIN
      },
      {
        label: 'Superadmin Leaders App Access',
        value: ADMIN_ROLES.SUPER_ADMIN
      }
    ],
    required: true,
    joi: joi.optional()
  }
};
