import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View onboarding',
  endpoint: endpoints.GET_ONBOARDING,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Welcome', key: 'welcome', type: ComponentList.YES_OR_NO },
    { label: 'Identity', key: 'identity', type: ComponentList.YES_OR_NO },
    { label: 'Address', key: 'address', type: ComponentList.YES_OR_NO },
    { label: 'Venues', key: 'venues', type: ComponentList.YES_OR_NO },
    { label: 'Notices', key: 'notices', type: ComponentList.YES_OR_NO },
    { label: 'Payment', key: 'payment', type: ComponentList.YES_OR_NO },
    { label: 'Taxes', key: 'taxes', type: ComponentList.YES_OR_NO },
    { label: 'I9 section one', key: 'i9_section_one', type: ComponentList.YES_OR_NO },
    { label: 'I9 section two', key: 'i9_section_two', type: ComponentList.YES_OR_NO },
    { label: 'Complete', key: 'complete', type: ComponentList.YES_OR_NO }
  ]
};
