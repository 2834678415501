export default {
  AGE_ERROR: 'You must be at least 18 years',
  BEFORE_DATE: 'The date must be after now',
  REQUIRED: 'Required field',
  MISSING_DATA: 'Missing data',
  PASSWORD_MUST_MATCH: 'The password must match',
  DATE_MUST_BE_GREATER: (comparingName: string): string => `The date must be greater than ${comparingName}`,
  DATE_MUST_BE_GREATER_OR_EQUAL_THAN: (comparingName: string): string =>
    `The date must be greater or equal than ${comparingName}`,
  MIN_1: 'The field must be at least 1',
  MAX: (max: number) => `The value can't be greater than ${max}`,
  INVALID_LENGTH: 'Invalid field length',
  INVALID_NUMBER: 'The field must be a number',
  INVALID_EMAIL: 'Wrong email format',
  INVALID_EXTENSION: 'Invalid file extension',
  INVALID_DATE: 'Wrong date format',
  INVALID_PHONE_NUMBER: 'Invalid phone number format. Ex: +11234567890',
  INVALID_PASSWORD:
    'Minimum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
  INVALID_ZIP: 'Wrong zip code format',
  INVALID_URL: 'Wrong URL format',
  INVALID_PERCENTAGE: 'Please enter a valid percentage',
  REPORT_TIME_TOO_FAR_FROM_HELD_ON_DATE: 'The report time must be within 6 hours of the held on date'
};
