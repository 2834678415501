import joi from 'joi';
import formErrors from 'constants/formErrors';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
