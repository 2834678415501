import React from 'react';
import { BackgroundCheckStatus, Button, DateTimeFormat } from 'components';
import { BackgroundCheckStatusTypes } from 'types/backgroundCheck';

type Props = {
  value: {
    status: BackgroundCheckStatusTypes;
    invitation_expires_at?: string;
    report_url?: string;
  };
};

const BackgroundCheckInfo = ({ value }: Props) => {
  const handleInvitation = () => {
    window.open(value.report_url);
  };

  if (value) {
    return (
      <div className="background-check-info">
        <BackgroundCheckStatus value={value.status} />
        {value.report_url ? (
          <Button text="Open background check report" variant="primary" tiny onClick={handleInvitation} />
        ) : (
          value.invitation_expires_at && (
            <div>
              Invitation expires: <DateTimeFormat value={value?.invitation_expires_at} />
            </div>
          )
        )}
      </div>
    );
  }

  return <>Not started</>;
};

export default BackgroundCheckInfo;
