import React from 'react';
import { default as MaskInput } from 'react-text-mask';
import classnames from 'classnames';
import { MaskedInputProps } from 'types/dynamicInputs';

const MaskedInput = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      onBlur,
      onKeyDown,
      disabled,
      error,
      placeholder,
      pristine = true,
      required = false,
      clearOnDelete = false,
      mask
    }: MaskedInputProps,
    ref: React.LegacyRef<MaskInput>
  ) => {
    const fullClear = (event: any) => {
      if (clearOnDelete && event.key === 'Backspace') {
        onChange('');
      }
    };

    return (
      <div className={classnames('input-wrapper', { error: !pristine && error })}>
        {!!label && (
          <label className="label">
            {label}
            {required ? ' *' : ''}
          </label>
        )}
        <MaskInput
          ref={ref}
          mask={mask}
          className="body-2"
          placeholder={placeholder}
          guide={false}
          keepCharPositions
          showMask
          id="mask-id"
          value={value}
          onChange={({ target }) => onChange(target.value, target.name)}
          onKeyDown={clearOnDelete ? fullClear : onKeyDown}
          onBlur={onBlur}
          disabled={disabled}
        />
        {!pristine && !!error && <span className="error-message body-2">{error}</span>}
      </div>
    );
  }
);

export default MaskedInput;
