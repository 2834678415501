/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const AssignTemplateLeaderPositions = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    onClose(formHandler.data.leaders.map((item: any) => item.value));
  };

  useEffect(() => {
    const newSchema = {
      ...schema,
      leaders: {
        ...schema.leaders,
        label: params.label
      }
    };

    const data = {
      leaders:
        params.value.length > 0
          ? params.value.map((item: any) => {
              return {
                value: item.position.id,
                label: item.position.name
              };
            })
          : []
    };

    formHandler.setFullSchema(newSchema, data);
  }, []);

  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save Changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <div>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default AssignTemplateLeaderPositions;
