import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { Button } from 'components';

type Props = {
  value: number | string;
};

const ConsultCheckPayroll = ({ value }: Props) => {
  const api = useApi();

  const handleConsult = () => {
    api.fetchData(endpoints.CONSULT_PAYROLL.get({ id: value }));
  };

  useEffect(() => {
    if (api.response?.url) {
      window.open(api.response.url, '_blank');
    }
  }, [api.response]);

  return <Button text="Consult payroll" onClick={handleConsult} disabled={api.isLoading} tiny />;
};

export default ConsultCheckPayroll;
