import React, { useEffect } from 'react';
import endpoints from 'constants/endpoints';
import { utcDate, onlyDate, removeTimeZone } from 'helpers/date';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionEmployeeModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({
      ...formHandler.data,
      dateOfBirth: onlyDate(removeTimeZone(formHandler.data.dateOfBirth)),
      jobType: formHandler.data.jobType.map((e: any) => e.value)
    });
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_EMPLOYEE.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const r = api.response.data;
      const data = {
        legalFirstName: r.legal_first_name,
        legalMiddleName: r.legal_middle_name || '',
        legalLastName: r.legal_last_name,
        dateOfBirth: utcDate(r.date_of_birth),
        checkEmployeeId: r.check_employee_id,
        alcoholCertification: r.alcoholCertification.status,
        jobType: r.jobType
      };
      formHandler.setFullForm(data);
    }
  }, [api.loaded, formHandler.schema]);

  useEffect(() => {
    if (api.loaded) {
      const { user } = api.response.data;
      const newSchema = {
        ...schema,
        checkEmployeeId: {
          ...schema.checkEmployeeId,
          defaultLabel: user.email
        }
      };

      formHandler.setSchema(newSchema);
    }
  }, [api.loaded]);

  return (
    <Base
      header="Edit employee"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionEmployeeModal;
