import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View user',
  endpoint: endpoints.GET_USER,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Name', key: 'name', type: ComponentList.TEXT },
    { label: 'Email', key: 'email', type: ComponentList.TEXT },
    { label: 'Phone number', key: 'phone_number', type: ComponentList.TEXT },
    { label: 'Admin Access', key: 'adminAccess', type: ComponentList.YES_OR_NO }
  ]
};
