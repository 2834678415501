import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import useFormHandler from 'hooks/useFormHandler';
import schema from './schema';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

const UniformApproval = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();
  const employeeId = String(params?.employeeId);

  useEffect(() => {
    api.fetchData(endpoints.GET_EMPLOYEE_UNIFORMS.get({ employeeId: params.employeeId }));
  }, []);

  useEffect(() => {
    formHandler.setFullForm({ uniformStatus: params.status });
  }, []);

  return (
    <Base
      header="Uniform"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={
        <Button
          text="Save changes"
          onClick={() => onClose({ employeeId, status: formHandler.data.uniformStatus })}
          disabled={!formHandler.valid}
        />
      }
    >
      <div className="uniform-viewer-container">
        {api?.response?.uniform?.files?.map((url: string, index: number) => (
          <div key={index} className="viewer-img">
            <img src={url} className="image" />
          </div>
        ))}
        <div className="form-wrapper">
          <DynamicForm handler={formHandler} />
        </div>
      </div>
    </Base>
  );
};

export default UniformApproval;
