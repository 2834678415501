import { useState, useRef } from 'react';

type HookReturn = { value: any; update: (value: any) => void; prev: any };

export default (initialValue: any = undefined): HookReturn => {
  const [state, setState] = useState(initialValue);
  const [prev, setPrev] = useState<any>(undefined);
  const ref = useRef(state);

  const update = (value: any) => {
    setPrev(ref.current);
    ref.current = value;
    setState(value);
  };

  return { value: ref?.current, update, prev };
};
