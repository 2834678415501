import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View notice',
  endpoint: endpoints.GET_NOTICE,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Content', key: 'content', type: ComponentList.REACH_TEXT },
    { label: 'State', key: 'state_name', type: ComponentList.TEXT },
    { label: 'Active', key: 'active', type: ComponentList.YES_OR_NO },
    { label: 'Updated at', key: 'updated_at', type: ComponentList.DATE_TIME },
    { label: 'File', key: 'file', type: ComponentList.DOWNLOAD }
  ]
};
