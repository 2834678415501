import { tableTypes } from 'constants/table';
import { INITIALIZE_TABLE, RESET_TABLES, TOGGLE_COLUMN, TablesState } from 'types/store';

const createInitialTableState = () => {
  return { columns: [] };
};

const initialState: TablesState = tableTypes.reduce((acc, type) => {
  return {
    ...acc,
    [type]: createInitialTableState()
  };
}, {});

export const table = (state = initialState, action: any) => {
  switch (action.type) {
    case INITIALIZE_TABLE: {
      const { tableKey, columns } = action.payload;
      return {
        ...state,
        [tableKey]: {
          ...(state[tableKey] || {}),
          columns
        }
      };
    }
    case TOGGLE_COLUMN: {
      const { tableKey, columnId } = action.payload;
      return {
        ...state,
        [tableKey]: {
          ...state[tableKey],
          columns: state[tableKey].columns.map(col => {
            if (col.id === columnId) {
              return { ...col, hidden: !col.hidden };
            }
            return col;
          })
        }
      };
    }
    case RESET_TABLES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default table;
