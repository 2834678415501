import React from 'react';
import { TextCopy } from 'components';
import { formatPhoneNumber } from 'helpers/text';

type Props = {
  value: string;
};

const PhoneNumber = ({ value }: Props) => {
  return <TextCopy value={formatPhoneNumber(value)} />;
};

export default PhoneNumber;
