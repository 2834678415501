import React from 'react';
import Tag from '../tag';

type Props = {
  value: string;
};

const DownloadImage = ({ value }: Props) => {
  const handleDownload = async () => {
    try {
      const response = await fetch(value);
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = urlBlob;
      a.download = value.split('/').pop() || 'image.jpg';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(urlBlob);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };

  return value ? (
    <Tag variant="primary" onClick={handleDownload}>
      Download
    </Tag>
  ) : null;
};

export default DownloadImage;
