import joi from 'joi';
import endpoints from 'constants/endpoints';
import { percentageMask } from 'constants/masks';
import formErrors from 'constants/formErrors';
import { zipCodeRegex, percentageRegex } from 'constants/regex';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  isActive: {
    value: false,
    label: 'Is active',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  },
  accountId: {
    value: '',
    label: 'Account',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_ACCOUNT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  lineOne: {
    value: '',
    label: 'Address Line one',
    limit: 150,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  lineTwo: {
    value: '',
    label: 'Address Line two',
    limit: 150,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.any().optional()
  },
  postalCode: {
    value: '',
    label: 'Postal code',
    limit: 5,
    input: InputsEnum.INPUT,
    joi: joi.string().regex(zipCodeRegex).required().messages({
      'string.base': formErrors.INVALID_LENGTH,
      'string.empty': formErrors.REQUIRED,
      'string.pattern.base': formErrors.INVALID_ZIP
    })
  },
  timezone: {
    value: '',
    options: [],
    label: 'Timezone',
    input: InputsEnum.SELECT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  poolableTipPercentage: {
    value: '',
    label: 'Poolable Tip Percentage',
    input: InputsEnum.MASKED,
    mask: percentageMask,
    required: true,
    clearOnDelete: true,
    joi: joi.string().regex(percentageRegex).required().messages({
      'string.pattern.base': formErrors.INVALID_NUMBER,
      'string.empty': formErrors.REQUIRED
    })
  },
  positions: {
    value: [],
    label: 'Entry Levels',
    input: InputsEnum.MULTI_SELECT,
    endpoint: endpoints.SEARCH_ALL_POSITIONS,
    joi: joi.array().optional()
  },
  siteUrl: {
    value: '',
    label: 'Notion Site URL',
    input: InputsEnum.INPUT,
    joi: joi.string().uri().allow(null).allow('').optional().messages({
      'string.uri': formErrors.INVALID_URL
    })
  },
  alcoholCertification: {
    value: false,
    label: 'Alcohol Certificate Required',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  },
  uniformRequired: {
    value: false,
    label: 'Uniform Approval Required',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  }
};
