import React from 'react';
import { Tag } from 'components';
import { trimUrlToPdf } from 'utils/data';

type Props = {
  value: {
    id: number;
    url: string;
  }[];
};

const DocsTags = ({ value }: Props) => {
  const openFile = (url: string) => {
    const finalUrl = trimUrlToPdf(url);
    window.open(finalUrl, '_blank');
  };

  return (
    <div className="docs-tags">
      {value.map((item, index) => (
        <Tag key={index} variant="secondary" onClick={() => openFile(item.url)}>
          File {index + 1}
        </Tag>
      ))}
    </div>
  );
};

export default DocsTags;
