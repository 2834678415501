import React from 'react';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';

const ConfirmModal = ({ closing, onClose, params }: Props) => {
  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Cancel" variant="secondary" onClick={() => onClose(false)} />
          <Button text="Confirm" onClick={() => onClose(true)} />
        </>
      }
    >
      {params.message}
    </Base>
  );
};

export default ConfirmModal;
