import {
  CreateAccountModal,
  CreateAreaModal,
  CreationEventModal,
  CreationNoticeModal,
  CreationPayrollModal,
  CreationStandPositionModal,
  CreationStandModal,
  CreationPositionModal,
  CreationTagModal,
  CreationVenueModal,
  CreationWarningModal,
  EditionAccountModal,
  EditionAreaNameModal,
  EditionEmployeeModal,
  EditionEventModal,
  EditionNoticeModal,
  EditionPayrollModal,
  EditionRatesAndTipsModal,
  EditionSalesEndedAtModal,
  EditionSalesStartedAtModal,
  EditionStandNameModal,
  EditionStateModal,
  EditionTagModal,
  EditionTotalTipsModal,
  EditionUserModal,
  EditionVenueModal,
  EditionVenuesForEmployeeModal,
  ViewModal,
  AddRegisteredEmployeesModal,
  AllocateInStandPositionModal,
  AssignAreaLeaderModal,
  AssignSiteLeaderModal,
  AssignStandLeaderModal,
  CheckInOutTimeModal,
  ConfirmModal,
  GetStateModal,
  GetVenueModal,
  GetAllModal,
  IncidentReportModal,
  InventoryModal,
  EmployeeNotes,
  AlcoholCertification,
  StatsModal,
  EditionAreaPoolableTipPercentage,
  VenueStatsModal,
  CreationAdminPayrollModal,
  DownloadEmployeeOnboardingDataModal,
  CreationEventPayroll,
  CreationAdminPayrollDetailsModal,
  EditionAdminPayrollModal,
  RespondToAppealModal,
  CreationTrainingModal,
  EditionTrainingModal,
  UniformApproval,
  TemplatePreview,
  CreationTemplateModal,
  AssignTemplateLeaderPositions,
  AssignTemplatePositions,
  EditTemplateDetailsModal,
  EditTemplatePosition,
  TerminationWarningsModal,
  CreationTerminationModal,
  CustomConfirmModal,
  CreationNotificatioModal,
  ResentNotificationModal,
  ViewVideoModal,
  PreviewApplicationModal,
  RejectApplicantModal,
  EnableApplicationsModal,
  DisableApplicationsModal
} from 'components';

import EditionPositionModal from 'components/modals/edition/position';
import PayrollFeedback from 'components/modals/others/payrollFeedback';

export enum ModalsList {
  CREATE_ACCOUNT_MODAL = 'CREATE_ACCOUNT_MODAL',
  CREATE_AREA_MODAL = 'CREATE_AREA_MODAL',
  CREATE_EVENT_MODAL = 'CREATE_EVENT_MODAL',
  CREATE_NOTICE_MODAL = 'CREATE_NOTICE_MODAL',
  CREATE_PAYROLL_MODAL = 'CREATE_PAYROLL_MODAL',
  CREATE_ADMIN_PAYROLL_MODAL = 'CREATE_ADMIN_PAYROLL_MODAL',
  CREATE_ADMIN_PAYROLL_DETAILS_MODAL = 'CREATE_ADMIN_PAYROLL_DETAILS_MODAL',
  CREATE_STAND_POSITION_MODAL = 'CREATE_STAND_POSITION_MODAL',
  CREATE_STAND_MODAL = 'CREATE_STAND_MODAL',
  CREATE_POSITION_MODAL = 'CREATE_POSITION_MODAL',
  CREATE_TAG_MODAL = 'CREATE_TAG_MODAL',
  CREATE_VENUE_MODAL = 'CREATE_VENUE_MODAL',
  CREATE_EVENT_PAYROLL = 'CREATE_EVENT_PAYROLL',
  CREATE_TRAINING_MODAL = 'CREATE_TRAINING_MODAL',
  EDITION_ADMIN_PAYROLL_MODAL = 'EDITION_ADMIN_PAYROLL_MODAL',
  CREATE_WARNING_MODAL = 'CREATE_WARNING_MODAL',
  CREATE_TERMINATION_MODAL = 'CREATE_TERMINATION_MODAL',
  EDITION_ACCOUNT_MODAL = 'EDITION_ACCOUNT_MODAL',
  EDITION_AREA_NAME_MODAL = 'EDITION_AREA_NAME_MODAL',
  EDITION_EMPLOYEE_MODAL = 'EDITION_EMPLOYEE_MODAL',
  EDITION_EVENT_MODAL = 'EDITION_EVENT_MODAL',
  EDITION_NOTICES_MODAL = 'EDITION_NOTICES_MODAL',
  EDITION_PAYROLL_MODAL = 'EDITION_PAYROLL_MODAL',
  EDITION_SALES_ENDED_AT_MODAL = 'EDITION_SALES_ENDED_AT_MODAL',
  EDITION_SALES_STARTED_AT_MODAL = 'EDITION_SALES_STARTED_AT_MODAL',
  EDITION_STAND_NAME_MODAL = 'EDITION_STAND_NAME_MODAL',
  EDITION_RATES_AND_TIPS_MODAL = 'EDITION_RATES_AND_TIPS_MODAL',
  EDITION_STATE_MODAL = 'EDITION_STATE_MODAL',
  EDITION_TAGS_MODAL = 'EDITION_TAGS_MODAL',
  EDITION_TOTAL_TIPS_MODAL = 'EDITION_TOTAL_TIPS_MODAL',
  EDITION_USER_MODAL = 'EDITION_USER_MODAL',
  EDITION_VENUE_MODAL = 'EDITION_VENUE_MODAL',
  EDITION_VENUES_FOR_EMPLOYEE = 'EDITION_VENUES_FOR_EMPLOYEE',
  EDITION_AREA_POOLABLE_TIP_PERCENTAGE_MODAL = 'EDITION_AREA_POOLABLE_TIP_PERCENTAGE_MODAL',
  EDITION_TRAINING_MODAL = 'EDITION_TRAINING_MODAL',
  VIEW_MODAL = 'VIEW_ACCOUNT_MODAL',
  ADD_REGISTERED_EMPLOYEES_MODAL = 'ADD_REGISTERED_EMPLOYEES_MODAL',
  ALLOCATE_IN_STAND_POSITION = 'ALLOCATE_IN_STAND_POSITION',
  ASSIGN_AREA_LEADER_MODAL = 'ASSIGN_AREA_LEADER_MODAL',
  ASSIGN_SITE_LEADER_MODAL = 'ASSIGN_SITE_LEADER_MODAL',
  ASSIGN_STAND_LEADER_MODAL = 'ASSIGN_STAND_LEADER_MODAL',
  CHECK_IN_OUT_TIME_MODAL = 'CHECK_IN_OUT_TIME_MODAL',
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  GET_STATE_MODAL = 'GET_STATE_MODAL',
  GET_VENUE_MODAL = 'GET_VENUE_MODAL',
  GET_ALL_MODAL = 'GET_ALL_MODAL',
  INCIDENT_REPORT_MODAL = 'INCIDENT_REPORT_MODAL',
  INVENTORY_MODAL = 'INVENTORY_MODAL',
  EMPLOYEE_NOTES = 'EMPLOYEE_NOTES',
  ALOCHOL_CERTIFICATION = 'ALOCHOL_CERTIFICATION',
  STATS_MODAL = 'STATS_MODAL',
  VENUE_STATS_MODAL = 'VENUE_STATS_MODAL',
  DOWNLOAD_EMPLOYEE_ONBOARDING_DATA_MODAL = 'DOWNLOAD_EMPLOYEE_ONBOARDING_DATA_MODAL',
  RESPOND_TO_APPEAL_MODAL = 'RESPOND_TO_APPEAL_MODAL',
  UNIFORM_APPROVAL = 'UNIFORM_APPROVAL',
  PAYROLL_FEEDBACK = 'PAYROLL_FEEDBACK',
  TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW',
  CREATE_TEMPLATE_MODAL = 'CREATE_TEMPLATE_MODAL',
  ASSIGN_TEMPLATE_LEADER_POSITIONS = 'ASSIGN_TEMPLATE_LEADER_POSITIONS',
  ASSIGN_TEMPLATE_POSITION = 'ASSIGN_TEMPLATE_POSITION',
  EDIT_TEMPLATE_DETAILS_MODAL = 'EDIT_TEMPLATE_DETAILS_MODAL',
  EDIT_TEMPLATE_POSITION = 'EDIT_TEMPLATE_POSITION',
  TERMINATION_WARNINGS_MODAL = 'TERMINATION_WARNINGS_MODAL',
  EDIT_POSITION = 'EDIT_POSITION',
  CUSTOM_CONFIRM = 'CUSTOM_CONFIRM',
  CREATE_NOTIFICATION_MODAL = 'CREATE_NOTIFICATION_MODAL',
  RESENT_NOTIFICATION_MODAL = 'RESENT_NOTIFICATION_MODAL',
  VIEW_VIDEO_MODAL = 'VIEW_VIDEO_MODAL',
  PREVIEW_APPLICATION_MODAL = 'PREVIEW_APPLICATION_MODAL',
  REJECT_APPLICATION_MODAL = 'REJECT_APPLICATION_MODAL',
  ENABLE_APPLICATIONS_MODAL = 'ENABLE_APPLICATIONS_MODAL',
  DISABLE_APPLICATIONS_MODAL = 'DISABLE_APPLICATIONS_MODAL'
}

export const modals = {
  [ModalsList.CREATE_ACCOUNT_MODAL]: CreateAccountModal,
  [ModalsList.CREATE_AREA_MODAL]: CreateAreaModal,
  [ModalsList.CREATE_EVENT_MODAL]: CreationEventModal,
  [ModalsList.CREATE_NOTICE_MODAL]: CreationNoticeModal,
  [ModalsList.CREATE_PAYROLL_MODAL]: CreationPayrollModal,
  [ModalsList.CREATE_ADMIN_PAYROLL_MODAL]: CreationAdminPayrollModal,
  [ModalsList.CREATE_ADMIN_PAYROLL_DETAILS_MODAL]: CreationAdminPayrollDetailsModal,
  [ModalsList.CREATE_STAND_POSITION_MODAL]: CreationStandPositionModal,
  [ModalsList.CREATE_STAND_MODAL]: CreationStandModal,
  [ModalsList.CREATE_POSITION_MODAL]: CreationPositionModal,
  [ModalsList.CREATE_TAG_MODAL]: CreationTagModal,
  [ModalsList.CREATE_VENUE_MODAL]: CreationVenueModal,
  [ModalsList.CREATE_EVENT_PAYROLL]: CreationEventPayroll,
  [ModalsList.EDITION_ADMIN_PAYROLL_MODAL]: EditionAdminPayrollModal,
  [ModalsList.CREATE_WARNING_MODAL]: CreationWarningModal,
  [ModalsList.EDITION_ACCOUNT_MODAL]: EditionAccountModal,
  [ModalsList.EDITION_AREA_NAME_MODAL]: EditionAreaNameModal,
  [ModalsList.EDITION_EMPLOYEE_MODAL]: EditionEmployeeModal,
  [ModalsList.EDITION_EVENT_MODAL]: EditionEventModal,
  [ModalsList.EDITION_NOTICES_MODAL]: EditionNoticeModal,
  [ModalsList.EDITION_PAYROLL_MODAL]: EditionPayrollModal,
  [ModalsList.EDITION_RATES_AND_TIPS_MODAL]: EditionRatesAndTipsModal,
  [ModalsList.EDITION_SALES_ENDED_AT_MODAL]: EditionSalesEndedAtModal,
  [ModalsList.EDITION_SALES_STARTED_AT_MODAL]: EditionSalesStartedAtModal,
  [ModalsList.EDITION_STAND_NAME_MODAL]: EditionStandNameModal,
  [ModalsList.EDITION_STATE_MODAL]: EditionStateModal,
  [ModalsList.EDITION_TAGS_MODAL]: EditionTagModal,
  [ModalsList.EDITION_TOTAL_TIPS_MODAL]: EditionTotalTipsModal,
  [ModalsList.EDITION_USER_MODAL]: EditionUserModal,
  [ModalsList.EDITION_VENUE_MODAL]: EditionVenueModal,
  [ModalsList.EDITION_AREA_POOLABLE_TIP_PERCENTAGE_MODAL]: EditionAreaPoolableTipPercentage,
  [ModalsList.EDITION_VENUES_FOR_EMPLOYEE]: EditionVenuesForEmployeeModal,
  [ModalsList.EDITION_TRAINING_MODAL]: EditionTrainingModal,
  [ModalsList.VIEW_MODAL]: ViewModal,
  [ModalsList.ADD_REGISTERED_EMPLOYEES_MODAL]: AddRegisteredEmployeesModal,
  [ModalsList.ALLOCATE_IN_STAND_POSITION]: AllocateInStandPositionModal,
  [ModalsList.ASSIGN_AREA_LEADER_MODAL]: AssignAreaLeaderModal,
  [ModalsList.ASSIGN_SITE_LEADER_MODAL]: AssignSiteLeaderModal,
  [ModalsList.ASSIGN_STAND_LEADER_MODAL]: AssignStandLeaderModal,
  [ModalsList.CHECK_IN_OUT_TIME_MODAL]: CheckInOutTimeModal,
  [ModalsList.CONFIRM_MODAL]: ConfirmModal,
  [ModalsList.GET_STATE_MODAL]: GetStateModal,
  [ModalsList.GET_VENUE_MODAL]: GetVenueModal,
  [ModalsList.GET_ALL_MODAL]: GetAllModal,
  [ModalsList.INCIDENT_REPORT_MODAL]: IncidentReportModal,
  [ModalsList.INVENTORY_MODAL]: InventoryModal,
  [ModalsList.EMPLOYEE_NOTES]: EmployeeNotes,
  [ModalsList.ALOCHOL_CERTIFICATION]: AlcoholCertification,
  [ModalsList.STATS_MODAL]: StatsModal,
  [ModalsList.VENUE_STATS_MODAL]: VenueStatsModal,
  [ModalsList.DOWNLOAD_EMPLOYEE_ONBOARDING_DATA_MODAL]: DownloadEmployeeOnboardingDataModal,
  [ModalsList.RESPOND_TO_APPEAL_MODAL]: RespondToAppealModal,
  [ModalsList.CREATE_TRAINING_MODAL]: CreationTrainingModal,
  [ModalsList.PAYROLL_FEEDBACK]: PayrollFeedback,
  [ModalsList.UNIFORM_APPROVAL]: UniformApproval,
  [ModalsList.TEMPLATE_PREVIEW]: TemplatePreview,
  [ModalsList.CREATE_TEMPLATE_MODAL]: CreationTemplateModal,
  [ModalsList.ASSIGN_TEMPLATE_LEADER_POSITIONS]: AssignTemplateLeaderPositions,
  [ModalsList.ASSIGN_TEMPLATE_POSITION]: AssignTemplatePositions,
  [ModalsList.EDIT_TEMPLATE_DETAILS_MODAL]: EditTemplateDetailsModal,
  [ModalsList.EDIT_TEMPLATE_POSITION]: EditTemplatePosition,
  [ModalsList.TERMINATION_WARNINGS_MODAL]: TerminationWarningsModal,
  [ModalsList.CREATE_TERMINATION_MODAL]: CreationTerminationModal,
  [ModalsList.EDIT_POSITION]: EditionPositionModal,
  [ModalsList.CUSTOM_CONFIRM]: CustomConfirmModal,
  [ModalsList.CREATE_NOTIFICATION_MODAL]: CreationNotificatioModal,
  [ModalsList.RESENT_NOTIFICATION_MODAL]: ResentNotificationModal,
  [ModalsList.VIEW_VIDEO_MODAL]: ViewVideoModal,
  [ModalsList.PREVIEW_APPLICATION_MODAL]: PreviewApplicationModal,
  [ModalsList.REJECT_APPLICATION_MODAL]: RejectApplicantModal,
  [ModalsList.ENABLE_APPLICATIONS_MODAL]: EnableApplicationsModal,
  [ModalsList.DISABLE_APPLICATIONS_MODAL]: DisableApplicationsModal
};

export type Props = {
  closing: boolean;
  onClose: (data?: any) => void;
  params: any;
};
