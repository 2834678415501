import { LOG_IN, LOG_OUT, SET_TOKEN, UserActionTypes, SET_CHANNEL_URL, SET_HAS_CHATS } from 'types/store';

export function login(data: any, token: string, refreshToken: string): UserActionTypes {
  return { type: LOG_IN, data, token, refreshToken };
}

export function logout(): UserActionTypes {
  return { type: LOG_OUT };
}

export function setToken(token: string): UserActionTypes {
  return { type: SET_TOKEN, token };
}

export function setChannelUrl(channelUrl?: string): UserActionTypes {
  return { type: SET_CHANNEL_URL, channelUrl };
}

export function setHasChats(hasChats: boolean): UserActionTypes {
  return { type: SET_HAS_CHATS, hasChats };
}
