/* eslint-disable @typescript-eslint/no-unused-vars */
import joi from 'joi';
import formErrors from 'constants/formErrors';
import { DateInputTypes, InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import { payrollTypeList } from 'types/payrollAdmin';

export const salariedSchema = {
  type: {
    value: '',
    label: 'Type',
    input: InputsEnum.SELECT,
    disabled: true,
    options: payrollTypeList,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  payday: {
    value: '',
    label: 'Payday',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    disabled: true,
    allowedDates: [],
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  },
  periodStart: {
    value: '',
    label: 'Period start',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    disabled: true,
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  },
  periodEnd: {
    value: '',
    label: 'Period end',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    disabled: true,
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  }
};

export const hourlySchema = {
  type: {
    value: '',
    label: 'Type',
    input: InputsEnum.SELECT,
    disabled: true,
    options: payrollTypeList,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  payday: {
    value: '',
    label: 'Payday',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    disabled: true,
    allowedDates: [],
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  },
  periodStart: {
    value: '',
    label: 'Period start',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    disabled: true,
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  },
  periodEnd: {
    value: '',
    label: 'Period end',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    disabled: true,
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  }
};

export const contractorSchema = {
  type: {
    value: '',
    label: 'Type',
    input: InputsEnum.SELECT,
    disabled: true,
    options: payrollTypeList,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  periodStart: {
    value: '',
    label: 'Period start',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi.date().required().messages({
      'date.empty': formErrors.REQUIRED,
      'date.base': formErrors.REQUIRED
    })
  },
  periodEnd: {
    value: '',
    label: 'Period end',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi
      .date()
      .required()
      .min(joi.ref('periodStart'))
      .messages({
        'date.empty': formErrors.REQUIRED,
        'date.base': formErrors.REQUIRED,
        'any.ref': formErrors.DATE_MUST_BE_GREATER_OR_EQUAL_THAN('period start'),
        'date.min': formErrors.DATE_MUST_BE_GREATER_OR_EQUAL_THAN('period start')
      })
  },
  payday: {
    value: '',
    label: 'Payday',
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi
      .date()
      .required()
      .greater(joi.ref('periodEnd'))
      .messages({
        'date.empty': formErrors.REQUIRED,
        'date.base': formErrors.REQUIRED,
        'any.ref': formErrors.DATE_MUST_BE_GREATER('period end'),
        'date.greater': formErrors.DATE_MUST_BE_GREATER('period end')
      })
  }
};
