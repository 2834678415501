/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import endpoints from 'constants/endpoints';

import { AssignLeaderInputProps } from 'types/dynamicInputs';
import { Autocomplete, Button, MultiSelect } from 'components';
import { Trash } from 'iconsax-react';

const AssignLeaderInput = ({ value, onChange, label }: AssignLeaderInputProps) => {
  const [selected, setSelected] = useState('' as any);
  const selectRef = useRef(null as any);

  useEffect(() => {
    const leaderExists = value.find((leader: any) => leader.id === selected.value);

    if (selected && !leaderExists) {
      onChange([
        ...value,
        {
          name: selected.label,
          levelAccess: selected.levelAccess,
          id: selected.value,
          employees: []
        }
      ]);
      setSelected('');
      selectRef.current.clearValue();
    }
  }, [selected]);

  return (
    <div className="assign-leader-input">
      <Autocomplete
        ref={selectRef as any}
        label="Leader Position"
        value={selected}
        endpoint={endpoints.SEARCH_POSITION}
        avoidFirstLoad
        limit={10}
        fullValue
        onChange={setSelected}
      />
      <div className="leaders-list">
        {value?.length > 0 &&
          value?.map((leader: any, index: number) => (
            <div key={index} className="leader">
              <div className="employees-container">
                <div className="title">
                  <span>{leader.name}</span>
                  <span className="body-2">Level {leader.levelAccess} Access</span>
                </div>

                <MultiSelect
                  label={label}
                  value={leader.employees}
                  endpoint={endpoints.SEARCH_EMPLOYEE}
                  avoidFirstLoad
                  limit={10}
                  onChange={employee => {
                    onChange(
                      value.map((item: any) => (item.id === leader.id ? { ...item, employees: employee } : item))
                    );
                    selectRef.current.clearValue();
                  }}
                />
              </div>
              <div className="remove-button">
                <Button
                  icon={<Trash />}
                  variant="alternative"
                  onClick={() => {
                    onChange(value.filter((item: any) => item.id !== leader.id));
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AssignLeaderInput;
