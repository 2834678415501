import joi from 'joi';
import endpoints from 'constants/endpoints';
import formErrors from 'constants/formErrors';
import { isOver18 } from 'utils/data';
import { DateInputTypes, InputsEnum, TextInputTypes } from 'types/dynamicInputs';

export default {
  checkEmployeeId: {
    value: '',
    label: 'User',
    input: InputsEnum.AUTOCOMPLETE,
    disabled: true,
    endpoint: endpoints.SEARCH_USER,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  legalFirstName: {
    value: '',
    label: 'Legal First Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  legalMiddleName: {
    value: '',
    label: 'Legal Middle Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.optional()
  },
  legalLastName: {
    value: '',
    label: 'Legal Last Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    required: true,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  dateOfBirth: {
    value: '',
    label: 'Date of Birth',
    required: true,
    input: InputsEnum.DATE,
    type: DateInputTypes.DATE,
    joi: joi
      .date()
      .required()
      .custom((value, helpers) => (isOver18(value) ? value : helpers.error('date.age')))
      .messages({
        'date.empty': formErrors.REQUIRED,
        'date.base': formErrors.REQUIRED,
        'date.age': formErrors.AGE_ERROR
      })
  },
  jobType: {
    value: [],
    label: 'Positions',
    input: InputsEnum.MULTI_SELECT,
    endpoint: endpoints.SEARCH_ALL_POSITIONS,
    joi: joi.array().optional()
  }
};
