import joi from 'joi';
import formErrors from 'constants/formErrors';
import { percentageRegex } from 'constants/regex';
import { percentageMask } from 'constants/masks';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  poolableTipPercentage: {
    value: '',
    label: 'Poolable Tip Percentage',
    input: InputsEnum.MASKED,
    mask: percentageMask,
    required: true,
    clearOnDelete: true,
    joi: joi.string().regex(percentageRegex).allow(null, '').messages({
      'string.pattern.base': formErrors.INVALID_PERCENTAGE
    })
  }
};
