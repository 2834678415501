import React from 'react';
import { Tag } from 'components';

type Props = {
  value: { id: number; name: string }[];
};

const EventTags = ({ value }: Props) => {
  let tags;

  if (!value) {
    return null;
  }

  tags = value.map(item => item.name);

  return (
    <>
      {tags.map((tag, index) => (
        <Tag key={index} variant="secondary">
          {tag}
        </Tag>
      ))}
    </>
  );
};

export default EventTags;
