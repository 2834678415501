import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View payroll',
  endpoint: endpoints.GET_PAYROLL,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Event', key: 'event_id', type: ComponentList.TEXT },
    { label: 'Period start', key: 'period_start', type: ComponentList.DATE },
    { label: 'Period end', key: 'period_end', type: ComponentList.DATE },
    { label: 'Payday', key: 'payday', type: ComponentList.DATE },
    { label: 'Approve by', key: 'approve_by', type: ComponentList.DATE },
    { label: 'Approved on', key: 'approved_on', type: ComponentList.DATE },
    { label: 'Check Payroll Id', key: 'check_payroll_id', type: ComponentList.TEXT_COPY },
    { label: 'Check Payroll', key: 'id', type: ComponentList.CONSULT_CHECK_PAYROLL }
  ]
};
