import React from 'react';
// @ts-ignore
import { Portal } from 'react-portal';
import { SearchNormal } from 'iconsax-react';
import { useRefs } from 'hooks/useRefs';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const SearchInToolbar = ({ value, onChange }: Props) => {
  const { toolbarMiddleRef } = useRefs();

  return (
    <Portal node={toolbarMiddleRef.current}>
      <div className="search-in-toolbar">
        <input type="search" placeholder="Search" value={value} onChange={({ target }) => onChange(target.value)} />
        <SearchNormal color="rgb(100, 100, 107)" />
      </div>
    </Portal>
  );
};

export default SearchInToolbar;
