import { MultiValue } from 'react-select';

export enum InputsEnum {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  INPUT = 'INPUT',
  INPUT_FILE = 'INPUT_FILE',
  COUNTER = 'COUNTER',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  CHECK = 'CHECK',
  DATE = 'DATE',
  TAGS_INPUT = 'TAGS_INPUT',
  MASKED = 'MASKED',
  RICH_TEXT = 'RICH_TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  TIME_RANGE_PICKER = 'TIME_RANGE_PICKER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  TRAINING_INPUT_FILE = 'TRAINING_INPUT_FILE',
  ASSIGN_LEADER_INPUT = 'ASSIGN_LEADER_INPUT',
  ALTERNATIVE_TEXTAREA = 'ALTERNATIVE_TEXTAREA'
}

export enum TextInputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  PHONE = 'phone',
  PASSWORD = 'password',
  EMAIL = 'email'
}

export enum DateInputTypes {
  DATE = 'date',
  DATE_TIME = 'datetime-local'
}

export type CommonProps = {
  onChange: (value: any, name?: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (e: any) => void;
  setDirtyError?: (flag: boolean) => void;
  label?: string;
  placeholder?: string;
  error?: string;
  pristine?: boolean;
  required?: boolean;
  disabled?: boolean;
  hide?: boolean;
};

export type Option = {
  label: string;
  value: string;
};

export type TextInputProps = {
  value: string | number;
  type: TextInputTypes;
  name?: string;
  placeholder?: string;
  limit?: number;
} & CommonProps;

export type CounterInputProps = {
  value: string | number;
  name?: string;
  limit?: number;
} & CommonProps;

export type SelectProps = {
  value: any;
  options: Option[];
} & CommonProps;

export type TagsInputProps = {
  value: string[];
} & CommonProps;

export type AutocompleteProps = {
  value: string;
  options?: Option[];
  endpoint: any;
  detail?: any;
  limit?: number;
  defaultLabel?: string;
  timezone?: boolean;
  fullValue?: boolean;
  isClearable?: boolean;
  avoidFirstLoad?: boolean;
} & CommonProps;

export type MultiSelectProps = {
  value: MultiValue<Option>;
  staticOptions?: Option[];
  endpoint: any;
  limit?: number;
  avoidFirstLoad?: boolean;
  endpointParams?: any;
} & CommonProps;

export type TextareaProps = {
  value: string;
  name?: string;
  limit?: number;
  placeholder?: string;
} & CommonProps;

export type CheckProps = {
  value: boolean;
  options: Option[];
} & CommonProps;

export type DatePickerProps = {
  type: DateInputTypes;
  name?: string;
  value: Date;
  allowedDates?: Date[];
} & CommonProps;

export type TimeRangePickerProps = {
  value: [string | null, string | null];
  name?: string;
} & CommonProps;

export type MaskedInputProps = {
  value: string | number | any[];
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  mask: (string | RegExp)[];
  clearOnDelete?: boolean;
} & CommonProps;

export type RichTextProps = {
  value: string;
  placeholder?: string;
  limit?: number;
} & CommonProps;

export type AssignLeaderInputProps = {
  value: any[];
} & CommonProps;
