import React from 'react';
import { capitalize } from 'helpers/text';
import { Tag } from 'components';
import { AlcoholCertificationsStatusTypes } from 'types/alcoholCertification';

type Props = {
  value: string;
};

const options = {
  [AlcoholCertificationsStatusTypes.COMPLETED]: 'success',
  [AlcoholCertificationsStatusTypes.PENDING_APPROVAL]: 'primary',
  [AlcoholCertificationsStatusTypes.INCOMPLETE]: 'secondary',
  [AlcoholCertificationsStatusTypes.REJECTED]: 'error'
};

const AlcoholCertificationStatusTag = ({ value }: Props) => {
  if (value) {
    return <Tag variant={options[value as AlcoholCertificationsStatusTypes] as any}>{capitalize(value)}</Tag>;
  }

  return <Tag variant="secondary">Not started</Tag>;
};

export default AlcoholCertificationStatusTag;
