import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const VenuesForEmployeeModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  useEffect(() => {
    api.fetchData(endpoints.GET_EMPLOYEE.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const r = api.response.data;
      const data = {
        venues: r.venues.map((item: any) => ({ label: item.name, value: item.id }))
      };
      formHandler.setFullForm(data);
    }
  }, [api.loaded, formHandler.schema]);

  return (
    <Base
      header="Employee's venues"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={() => {
            formHandler.setIsSubmitting(true);
            onClose(formHandler.data);
          }}
        />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default VenuesForEmployeeModal;
