import React from 'react';
import OpenUrl from '../openUrl';

type Props = {
  value: string;
};

const File = ({ value }: Props) => {
  return value === null ? <></> : <OpenUrl label="File" url={value} />;
};

export default File;
