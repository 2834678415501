import { INITIALIZE_TABLE, RESET_TABLES, TOGGLE_COLUMN } from 'types/store';

export function initializeTable(tableKey: string, columns: any) {
  return { type: INITIALIZE_TABLE, payload: { tableKey, columns } };
}

export function toggleColumn(tableKey: string, columnId: string) {
  return { type: TOGGLE_COLUMN, payload: { tableKey, columnId } };
}

export function resetTables() {
  return { type: RESET_TABLES };
}
