import React, { useEffect } from 'react';
import { phoneNumberMask } from 'constants/masks';
import endpoints from 'constants/endpoints';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { applyMask } from 'helpers/data';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionUserModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({
      ...formHandler.data,
      phoneNumber: formHandler.data.phoneNumber.replace(/[^\d+]/g, '')
    });
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_USER.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const { phone_number: phoneNumber } = api.response.data;
      formHandler.setFullForm({
        ...api.response.data,
        password: '',
        phoneNumber: applyMask(phoneNumber, phoneNumberMask)
      });
    }
  }, [api.response, api.loaded]);

  return (
    <Base
      header="Edit user"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionUserModal;
