import React, { useEffect } from 'react';
import get from 'lodash/get';
import useApi from 'hooks/useApi';
import {
  BackgroundCheckInfo,
  Button,
  EmployeeName,
  ConsultCheckPayroll,
  TextCopy,
  DateFormat,
  DateTimeFormat,
  DownloadButton,
  EventTags,
  YesOrNo,
  ReachText,
  TextWithBreaks,
  Venues,
  PhoneNumber,
  Email,
  File,
  Link,
  Percentage,
  AlcoholInstructions,
  NotionLink
} from 'components';
import { Props } from 'types/modals';
import { ComponentList } from 'types/visualizer';
import * as viewers from './viewers';
import Base from '../base';

const ViewModal = ({ closing, onClose, params }: Props) => {
  const { viewer } = params;

  // @ts-ignore
  const { endpoint, title, schema, timezone } = viewers[viewer];
  const api = useApi();

  useEffect(() => {
    api.fetchData(endpoint.get(params));
  }, []);

  return (
    <Base
      className="modal-view"
      header={title}
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose()} />}
    >
      <>
        {schema.map((item: any, index: number) => (
          <div className="item-wrapper" key={index}>
            <span>{item.label}</span>
            <div className="body">
              {api.loaded ? (
                <>
                  {
                    {
                      [ComponentList.TEXT]: <span>{get(api.response.data, item.key)}</span>,
                      [ComponentList.TEXT_COPY]: <TextCopy value={get(api.response.data, item.key)} />,
                      [ComponentList.BACKGROUND_CHECK_INFO]: (
                        <BackgroundCheckInfo value={get(api.response.data, item.key)} />
                      ),
                      [ComponentList.TEXT_WITH_BREAKS]: (
                        <TextWithBreaks>{get(api.response.data, item.key)}</TextWithBreaks>
                      ),
                      [ComponentList.EVENT_TAGS]: <EventTags value={get(api.response.data, item.key)} />,
                      [ComponentList.DATE]: <DateFormat value={get(api.response.data, item.key)} />,
                      [ComponentList.DATE_TIME]: (
                        <DateTimeFormat
                          value={get(api.response.data, item.key)}
                          timezone={!!timezone && get(api?.response?.data, 'venue.timezone')}
                        />
                      ),
                      [ComponentList.DOWNLOAD]: <DownloadButton value={get(api.response.data, item.key)} />,
                      [ComponentList.YES_OR_NO]: <YesOrNo value={get(api.response.data, item.key)} />,
                      [ComponentList.REACH_TEXT]: <ReachText value={get(api.response.data, item.key)} readOnly />,
                      [ComponentList.VENUES]: <Venues value={get(api.response.data, item.key)} />,
                      [ComponentList.EMPLOYEE_NAME]: <EmployeeName value={get(api.response.data, item.key)} />,
                      [ComponentList.CONSULT_CHECK_PAYROLL]: (
                        <ConsultCheckPayroll value={get(api.response.data, item.key)} />
                      ),
                      [ComponentList.PHONE_NUMBER]: <PhoneNumber value={get(api.response.data, item.key)} />,
                      [ComponentList.EMAIL]: <Email value={get(api.response.data, item.key)} />,
                      [ComponentList.HTML]: (
                        <span dangerouslySetInnerHTML={{ __html: get(api.response.data, item.key) }} />
                      ),
                      [ComponentList.FILE]: <File value={get(api.response.data, item.key)} />,
                      [ComponentList.LINK]: <Link value={get(api.response.data, item.key)} />,
                      [ComponentList.PERCENTAGE]: <Percentage value={get(api.response.data, item.key)} />,
                      [ComponentList.ALCOHOL_INSTRUCTIONS]: (
                        <AlcoholInstructions value={get(api.response.data, item.key)} />
                      ),
                      [ComponentList.NOTION_LINK]: <NotionLink value={get(api.response.data, item.key)} />
                    }[item.type as ComponentList]
                  }
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        ))}
      </>
    </Base>
  );
};

export default ViewModal;
