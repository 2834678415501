import React, { ChangeEvent } from 'react';
import { Checkbox } from 'components';
import { CheckProps } from 'types/dynamicInputs';

const Check = ({ value, label, onChange, disabled }: CheckProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className="input-wrapper">
      <Checkbox label={label} checked={value} onChange={handleChange} disabled={disabled} />
    </div>
  );
};

export default Check;
