import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const GetVenueModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({ id: +formHandler.data.venue, backgroundCheck: formHandler.data.backgroundCheck });
  };

  return (
    <Base
      header="Select venue"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Done" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default GetVenueModal;
