import joi from 'joi';
import endpoints from 'constants/endpoints';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  venue: {
    value: '',
    label: 'Venue',
    input: InputsEnum.AUTOCOMPLETE,
    endpoint: endpoints.SEARCH_VENUE,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  },
  backgroundCheck: {
    value: 'ALL',
    label: 'Background Check Completed',
    input: InputsEnum.SELECT,
    options: [
      { label: 'All', value: 'ALL' },
      { label: 'Yes', value: 'YES' },
      { label: 'No', value: 'NO' }
    ],
    disabled: false,
    joi: joi.string().required().messages({
      'string.empty': formErrors.REQUIRED
    })
  }
};
