import React, { useEffect } from 'react';
import { DocumentDownload } from 'iconsax-react';
import classnames from 'classnames';
import endpoints from 'constants/endpoints';
import useApi from 'hooks/useApi';
import useDownloadImage from 'hooks/useDownloadImage';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';

const InventoryModal = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const downloadImage = useDownloadImage();
  const isLoading = !api.loaded;
  const images: string[] = api.response?.data || [];

  const handleDownload = (url: string) => () => {
    downloadImage.download(url);
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_INVENTORY.get({ id: params.id }));
  }, []);

  return (
    <Base
      header="Inventory"
      className="inventory-modal"
      closing={closing}
      onClose={onClose}
      size="lg"
      footer={<Button text="Close" onClick={() => onClose(false)} disabled={downloadImage.loading} />}
    >
      <div className={classnames('items', { single: images.length === 1 })}>
        {isLoading ? (
          <div>Loading...</div>
        ) : images.length > 0 ? (
          <>
            {images.map((item, index) => (
              <div className="item" key={index}>
                <div>
                  <img src={item} />
                  <Button icon={<DocumentDownload />} onClick={handleDownload(item)} disabled={downloadImage.loading} />
                </div>
              </div>
            ))}
          </>
        ) : (
          <div>Nothing reported...</div>
        )}
      </div>
    </Base>
  );
};

export default InventoryModal;
