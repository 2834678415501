import React from 'react';
import { OpenUrl } from 'components';

const AlcoholInstructions = ({ value }: { value: string }) => {
  if (!value) {
    return null;
  }

  return <OpenUrl label="Alcohol Instructions" url={value} />;
};

export default AlcoholInstructions;
