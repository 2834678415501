import React from 'react';
import { Tag } from 'components';
import { BackgroundCheckStatusTypes } from 'types/backgroundCheck';

type Props = {
  value: BackgroundCheckStatusTypes | null;
};

const options = {
  [BackgroundCheckStatusTypes.COMPLETED]: 'primary',
  [BackgroundCheckStatusTypes.PENDING]: 'secondary',
  [BackgroundCheckStatusTypes.INVALID]: 'light'
};

const prettyNames = {
  [BackgroundCheckStatusTypes.COMPLETED]: 'Completed',
  [BackgroundCheckStatusTypes.PENDING]: 'Pending',
  [BackgroundCheckStatusTypes.INVALID]: 'Invalid'
};

const BackgroundCheckStatus = ({ value }: Props) => {
  if (value) {
    return <Tag variant={options[value] as any}>{prettyNames[value]}</Tag>;
  }

  return <Tag variant="light">Not Started</Tag>;
};

export default BackgroundCheckStatus;
