/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import { ArrowDown2, ArrowUp2, Buildings2, Shop } from 'iconsax-react';
import classNames from 'classnames';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';
import { JOB_TYPE } from 'constants/user';

const TemplatePreview = ({ closing, onClose, params }: Props) => {
  const [openDropdowns, setOpenDropdowns] = useState({} as any);
  const api = useApi();
  const template = api?.response?.template;

  const toggleDropdown = (index: number) => {
    setOpenDropdowns((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_TEMPLATE.get({ id: params.id }));
  }, []);

  return (
    <Base
      header="Template Preview"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Close" onClick={() => onClose(false)} />}
    >
      {api.isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="template-preview">
          <h6 className="event-name strong">{template?.name}</h6>
          <span className="stadium body">{template?.venue?.name}</span>
          <div className="separator-line" />

          <div className="site">
            <div className="title">
              <Buildings2 />
              <span className="body strong">Site</span>
            </div>

            <div className="leaders">
              {template?.siteLeadPositions?.map((item: any, index: number) => (
                <span key={index} className="text body-2">
                  {item.position.name}
                </span>
              ))}
            </div>
          </div>

          {template?.areas?.map((area: any, index: number) => (
            <div className="areas" key={index}>
              <div className="dropdown">
                <div
                  className={classNames('actionable', {
                    selected: openDropdowns[index]
                  })}
                >
                  <div className="title">
                    <Shop />
                    <div className="body strong">
                      {area.name} <span className="body-2">({area.stands.length} Stands)</span>
                    </div>
                  </div>
                  <Button
                    className="arrow"
                    icon={<ArrowDown2 />}
                    variant="alternative"
                    onClick={() => toggleDropdown(index)}
                  />
                </div>

                {openDropdowns[index] && (
                  <div className="dropdown-content">
                    <div className="area-leaders">
                      {area?.locationsPositions?.map((item: any, index: number) => (
                        <span key={index} className="text body-2">
                          {item.position.name}
                        </span>
                      ))}
                    </div>

                    {area.stands.map((stand: any, index: number) => (
                      <div className="stand" key={index}>
                        <span className="body strong title">{stand.name}</span>
                        <div>
                          <span className="body-2 description">Leaders</span>
                          <div className="stand-content">
                            {stand?.positions
                              .filter((element: any) => element.job_type === JOB_TYPE.STANDLEAD)
                              .map((item: any, index: number) => (
                                <span key={index} className="text body-2">
                                  {item.position.name}
                                </span>
                              ))}
                          </div>
                        </div>
                        <div>
                          <span className="body-2 description">Positions</span>
                          <div className="stand-content">
                            {stand.positions
                              .filter((element: any) => element.job_type === JOB_TYPE.STAFF)
                              .map((item: any, index: number) => (
                                <span key={index} className="text body-2">
                                  {item.position.name} {item.total && `(${item.total})`}
                                </span>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </Base>
  );
};

export default TemplatePreview;
