import React from 'react';
import { TextareaProps } from 'types/dynamicInputs';

const AlternativeTextArea = ({ label, value, name, limit, onChange }: TextareaProps) => (
  <div className="alternative-text-area">
    <label className="text-area-label">{label}</label>
    <textarea
      className=""
      name={name}
      value={value}
      onChange={({ target }) => onChange(target.value, target.name)}
      maxLength={limit}
    />
    {limit && <small className="text-area-limit">{`${value.length}/${limit}`}</small>}
  </div>
);

export default AlternativeTextArea;
