import React from 'react';
import { Badge } from 'components';

type Props = {
  value: boolean;
};

const YesOrNo = ({ value }: Props) => {
  return value ? <Badge value="yes" variant="success" /> : <Badge value="no" variant="danger" />;
};

export default YesOrNo;
