export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// Minimum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[^\n\r]{12,}$/;

// (###) ###-#### - format for a standard US type phone number
export const phoneNumberRegex = /^\+1 \([1-9]\d{2}\) \d{3}-\d{4}$/;

export const emptyRichText = `^<p><br></p>$`;

export const excludeTagsRegex = `/<[^>]*>/gi`;

// US Zip Code support 5 digits (12345)
export const zipCodeRegex = /^\d{5}$/;

export const percentageRegex = /^([1-9][0-9]?|0|100)%$/;

export const onlyNumbersRegex = /^\d*$/;
