import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { removeTimeZone, onlyDate } from 'helpers/date';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const CreationPayrollModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({
      ...formHandler.data,
      eventId: +formHandler.data.eventId,
      periodStart: onlyDate(removeTimeZone(formHandler.data.periodStart)),
      periodEnd: onlyDate(removeTimeZone(formHandler.data.periodEnd)),
      payday: onlyDate(removeTimeZone(formHandler.data.payday))
    });
  };

  useEffect(() => {
    formHandler.setFullForm({
      eventId: String(params.eventId)
    });
  }, [formHandler.schema]);

  useEffect(() => {
    formHandler.setSchema({
      ...schema,
      eventId: {
        ...schema.eventId,
        defaultLabel: params.eventName
      }
    });
  }, []);

  return (
    <Base
      header="Create payroll"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <div className="modal-create-payroll">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationPayrollModal;
