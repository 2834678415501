import React from 'react';
import {
  Autocomplete,
  Counter,
  Check,
  DatePicker,
  Input,
  InputFile,
  MaskedInput,
  MultiSelect,
  RichTextInput,
  Select,
  Textarea,
  TimeRangePicker,
  DateTimePicker,
  TrainingInputFile,
  AssignLeaderInput,
  AlternativeTextArea
} from 'components';
import { InputsEnum } from 'types/dynamicInputs';
import TagsInputComponent from 'components/dynamicInputs/tagsInput';

interface Props {
  handler: any;
}

const DynamicForm = ({ handler }: Props) => {
  if (!handler || handler.data === null) {
    return null;
  }

  return (
    <>
      {Object.keys(handler.schema).map(key => (
        <React.Fragment key={key}>
          {
            {
              [InputsEnum.INPUT]: (
                <Input
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.INPUT_FILE]: (
                <InputFile
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.TRAINING_INPUT_FILE]: (
                <TrainingInputFile
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.COUNTER]: (
                <Counter
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.SELECT]: (
                <Select
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.TEXTAREA]: (
                <Textarea
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.CHECK]: (
                <Check
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.DATE]: (
                <DatePicker
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.MASKED]: (
                <MaskedInput
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.AUTOCOMPLETE]: handler.schema[key].hide ? null : (
                <Autocomplete
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.RICH_TEXT]: (
                <RichTextInput
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.MULTI_SELECT]: (
                <MultiSelect
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.TIME_RANGE_PICKER]: (
                <TimeRangePicker
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  setDirtyError={handler.setDirtyError}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.DATE_TIME_PICKER]: (
                <DateTimePicker
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  setDirtyError={handler.setDirtyError}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.TAGS_INPUT]: (
                <TagsInputComponent
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  setDirtyError={handler.setDirtyError}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.ASSIGN_LEADER_INPUT]: (
                <AssignLeaderInput
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  setDirtyError={handler.setDirtyError}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              ),
              [InputsEnum.ALTERNATIVE_TEXTAREA]: (
                <AlternativeTextArea
                  {...handler.schema[key]}
                  value={handler.data[key]}
                  error={handler.errors[key]}
                  pristine={handler.pristine[key]}
                  onBlur={handler.blurred(key)}
                  onChange={handler.setFormData(key)}
                />
              )
            }[handler.schema[key].input as InputsEnum]
          }
        </React.Fragment>
      ))}
    </>
  );
};

export default DynamicForm;
