import { createColumnHelper } from '@tanstack/react-table';
import { dollarized } from 'utils/data';

type VenueStats = {
  event: string;
  role: string;
  averageHours: number;
  averageTips: number;
  averageHourlyPay: number;
  shiftTime: number;
  nonTippedWagesHours: number;
  tippedWagesHours: number;
};

const columnHelper = createColumnHelper<VenueStats>();

export const columns = [
  columnHelper.accessor('role', {
    header: 'Position',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('averageHours', {
    header: 'Average Hours',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('averageTips', {
    header: 'Average Tips',
    cell: info => dollarized(String(info.renderValue()))
  }),
  columnHelper.accessor('averageHourlyPay', {
    header: 'Hourly Pay',
    cell: info => dollarized(String(info.renderValue()))
  }),
  columnHelper.accessor('nonTippedWagesHours', {
    header: 'Non tipped wages',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('tippedWagesHours', {
    header: 'Tipped wages',
    cell: info => info.renderValue()
  })
];
