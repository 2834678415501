import React from 'react';
import DownloadFile from '../downloadFile';

type Props = {
  value: string;
};

const DownloadResume = ({ value }: Props) => {
  return <DownloadFile donwloadUrl={value}>Download Resume</DownloadFile>;
};

export default DownloadResume;
