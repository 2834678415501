import { CONTRACT_SIDE_MENU, TOGGLE_SIDE_MENU, GeneralState, GeneralActionTypes } from 'types/store';

const initialState: GeneralState = {
  mobileSideMenuOpen: false
};

const general = (state: GeneralState = initialState, action: GeneralActionTypes) => {
  switch (action.type) {
    case CONTRACT_SIDE_MENU:
      return {
        ...state,
        mobileSideMenuOpen: false
      };
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        mobileSideMenuOpen: !state.mobileSideMenuOpen
      };
    default:
      return state;
  }
};

export default general;
