import React from 'react';
import useFormHandler from 'hooks/useFormHandler';
import { Button, DynamicForm } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const AddRegisteredEmployeesModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler({
    employees: {
      ...schema.employees,
      endpointParams: { id: params.id }
    }
  });

  const handleSave = () => {
    formHandler.setIsSubmitting(true);
    const employees = formHandler.data.employees.map((item: any) => +item.value);
    onClose(employees);
  };

  return (
    <Base
      header="Add registered employees"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSave} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default AddRegisteredEmployeesModal;
