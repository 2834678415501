import React, { useRef } from 'react';
import MaskedInput from 'components/dynamicInputs/maskedInput';
import { hoursMask } from 'constants/masks';

type Props = {
  row: any;
  columnId: string;
  value: string;
  onSave: (data: any) => void;
  setTableData: React.Dispatch<any>;
};

const EditableHourCell = ({ row, value, columnId, setTableData, onSave }: Props) => {
  const inputRef = useRef<any>(null);
  const initialValueRef = useRef<string>(value);

  const handleChange = (newValue: string) => {
    setTableData((prev: any) => {
      return {
        ...prev,
        items: prev.items.map((item: any) => {
          if (item.id === row.id) {
            return {
              ...item,
              [columnId]: newValue
            };
          }
          return item;
        })
      };
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.inputElement.blur();
      }
    }
  };

  const handleBlur = () => {
    if (initialValueRef.current !== value) {
      onSave(row);
      initialValueRef.current = value;
    }
  };

  return (
    <div className="editable-hour-cell">
      <MaskedInput
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        mask={hoursMask}
      />
    </div>
  );
};

export default EditableHourCell;
