import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import endpoints from 'constants/endpoints';
import { timeZones } from 'constants/timeZones';
import useApi from 'hooks/useApi';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const CreationVenueModal = ({ closing, onClose }: Props) => {
  const formHandler = useFormHandler(schema);
  const apiStates = useApi();

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    const { lineOne, lineTwo, city, state, postalCode } = formHandler.data;
    onClose({
      ...formHandler.data,
      address: {
        lineOne,
        lineTwo,
        city,
        state,
        postalCode
      }
    });
  };

  useEffect(() => {
    if (apiStates.response) {
      formHandler.setSchema({
        ...schema,
        state: {
          ...schema.state,
          options: [...apiStates.response.items.map((item: any) => ({ label: item.name, value: item.id }))]
        },
        timezone: {
          ...schema.timezone,
          options: [...timeZones.map((item: any) => ({ label: item, value: item }))]
        }
      });
    }
  }, [apiStates.response]);

  useEffect(() => {
    apiStates.fetchData(endpoints.GET_VALID_STATES.get());
  }, []);

  return (
    <Base
      header="Create venue"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={<Button text="Create" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />}
    >
      <div className="modal-create-venue">
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default CreationVenueModal;
