import React from 'react';
import { Venue } from 'components';

type Props = {
  value: {
    id: string | number;
    name: string;
  }[];
};

const Venues = ({ value }: Props) => (
  <div className="venues">
    {value.map(venue => (
      <Venue key={venue.id} value={venue} />
    ))}
  </div>
);

export default Venues;
