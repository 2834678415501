import React from 'react';
import classnames from 'classnames';

export type Variants = 'primary' | 'secondary' | 'light';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: Variants;
};

const Tag = ({ children, onClick, variant = 'primary' }: Props) => (
  <span className={classnames('tag', 'body-2', variant, { clickable: !!onClick })} onClick={onClick}>
    {children}
  </span>
);

export default Tag;
