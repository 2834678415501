import formErrors from 'constants/formErrors';
import joi from 'joi';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  workersNeeded: {
    value: 10,
    label: 'Workers needed',
    limit: 3,
    input: InputsEnum.COUNTER,
    joi: joi
      .number()
      .max(250)
      .required()
      .messages({
        'number.base': formErrors.REQUIRED,
        'number.max': formErrors.MAX(250)
      })
  }
};
