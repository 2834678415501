import joi from 'joi';
import { InputsEnum, TextInputTypes } from 'types/dynamicInputs';
import endpoints from 'constants/endpoints';

export default {
  name: {
    value: '',
    label: 'Name',
    limit: 80,
    input: InputsEnum.INPUT,
    type: TextInputTypes.TEXT,
    joi: joi.string().allow('').optional()
  },
  flow: {
    value: '',
    label: 'Flow',
    input: InputsEnum.SELECT,
    options: [
      { label: 'Onboarding', value: 'Onboarding' },
      { label: 'My Trainings', value: 'MyTrainings' }
    ],
    disabled: false,
    joi: joi.string().allow('').optional()
  },
  venues: {
    value: [],
    label: 'Venues',
    input: InputsEnum.MULTI_SELECT,
    endpoint: endpoints.SEARCH_VENUES,
    joi: joi.array().optional()
  },
  positions: {
    value: [],
    label: 'Positions',
    input: InputsEnum.MULTI_SELECT,
    endpoint: endpoints.SEARCH_ALL_POSITIONS,
    joi: joi.array().optional()
  },
  videoUrl: {
    value: [],
    label: 'Video URL',
    placeholder: '',
    input: InputsEnum.TAGS_INPUT,
    joi: joi.array().optional()
  },
  quizUrl: {
    value: '',
    label: 'Quiz URL',
    placeholder: '',
    type: TextInputTypes.TEXT,
    input: InputsEnum.INPUT,
    joi: joi.string().allow('').optional()
  },
  docs: {
    value: [],
    label: 'Docs',
    disabled: false,
    multiple: true,
    input: InputsEnum.TRAINING_INPUT_FILE,
    joi: joi.optional(),
    extension: 'application/pdf'
  },
  global: {
    value: false,
    label: 'General Training',
    input: InputsEnum.CHECK,
    disabled: false,
    joi: joi.boolean().optional()
  },
  active: {
    value: false,
    label: 'Active',
    input: InputsEnum.CHECK,
    joi: joi.boolean().optional()
  }
};
