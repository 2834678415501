import joi from 'joi';
import { emptyRichText, excludeTagsRegex } from 'constants/regex';
import formErrors from 'constants/formErrors';
import { InputsEnum } from 'types/dynamicInputs';

export default {
  message: {
    value: '',
    label: 'Message',
    limit: 100,
    input: InputsEnum.RICH_TEXT,
    joi: joi
      .string()
      .regex(RegExp(emptyRichText), { invert: true })
      .replace(excludeTagsRegex, '')
      .max(600)
      .required()
      .messages({
        'string.pattern.invert.base': formErrors.REQUIRED,
        'string.max': formErrors.INVALID_LENGTH
      })
  }
};
