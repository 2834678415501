import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View earning',
  endpoint: endpoints.GET_EARNING,
  schema: [
    { label: 'ID', key: 'id', type: ComponentList.TEXT },
    { label: 'Payment', key: 'payment_id', type: ComponentList.TEXT },
    { label: 'Description', key: 'description', type: ComponentList.TEXT },
    { label: 'Hours', key: 'hours', type: ComponentList.TEXT },
    { label: 'Amount', key: 'amount', type: ComponentList.TEXT },
    { label: 'Type', key: 'type', type: ComponentList.TEXT }
    //todo: readOnly - we need see what type is this field and if we need to make a component for it
    // { label: 'Metadata', key: 'metadata', type: ComponentList.TEXT }
  ]
};
