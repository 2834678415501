import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { extendedDateFormat } from 'constants/dates';

type TerminationWarningsFields = {
  id: string;
  warning_type: string;
  warning: string;
  objective_status: string;
  created_at: string;
  event_name: string;
  event_date: string;
};

const columnHelper = createColumnHelper<TerminationWarningsFields>();

export const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('warning_type', {
    header: 'Warning Type',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('warning', {
    header: 'Warning',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('objective_status', {
    header: 'Objective Status',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('created_at', {
    header: 'Created At',
    cell: info => format(new Date(info.getValue()), extendedDateFormat)
  }),
  columnHelper.accessor('event_name', {
    header: 'Event Name',
    cell: info => info.renderValue()
  }),
  columnHelper.accessor('event_date', {
    header: 'Event Date',
    cell: info => format(new Date(info.getValue()), extendedDateFormat)
  })
];
