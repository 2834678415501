import React from 'react';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';

const CustomConfirmModal = ({ closing, onClose, params }: Props) => {
  return (
    <Base
      header={params.title}
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text={params.confirm} onClick={() => onClose(true)} />
          <Button text={params.cancel} variant="secondary" onClick={() => onClose(false)} />
        </>
      }
    >
      {params.message}
    </Base>
  );
};

export default CustomConfirmModal;
