export enum PayrollAdminTypes {
  HOURLY = '1',
  SALARIED = '2',
  CONTRACTOR = '3'
}

export const PayrollAdmineEnum = {
  1: 'hourly',
  2: 'salary',
  3: 'contractor'
} as const;

export type PayrollSchedule = {
  payday: string;
  period_start: string;
  period_end: string;
  approval_deadline: string;
  impacted_by_weekend_or_holiday: boolean;
};

export const payrollTypeList = [
  { value: PayrollAdminTypes.SALARIED, label: 'Salaried' },
  { value: PayrollAdminTypes.HOURLY, label: 'Hourly' },
  { value: PayrollAdminTypes.CONTRACTOR, label: 'Contractor' }
];

export enum PayrollStatus {
  Draft = 1,
  Pending = 2,
  Processing = 3,
  Paid = 4,
  PartiallyPaid = 5,
  Failed = 6,
  Cancelled = 7
}
