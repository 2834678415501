import React from 'react';
import classnames from 'classnames';
import { TextInputProps, TextInputTypes } from 'types/dynamicInputs';

const Text = ({
  type,
  placeholder,
  label,
  name,
  value,
  onChange,
  onBlur,
  limit,
  error,
  required = false,
  pristine = true,
  disabled = false
}: TextInputProps) => {
  const handleChange = (value: any, name: string) => {
    if (type === TextInputTypes.EMAIL) {
      value = value.toLowerCase();
    }

    onChange(type === TextInputTypes.NUMBER ? +value : value, name);
  };

  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <input
        className="body-2"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={({ target }) => handleChange(target.value, target.name)}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={limit}
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default Text;
