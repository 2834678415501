import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import useApi from 'hooks/useApi';
import useDebounce from 'hooks/useDebounce';
import { MultiSelectProps, Option } from 'types/dynamicInputs';

const MultiSelect = ({
  label,
  endpoint,
  value,
  staticOptions,
  onChange,
  onBlur,
  disabled,
  pristine,
  error,
  limit = 10,
  endpointParams = {},
  avoidFirstLoad: _avoidFirstLoad = false
}: MultiSelectProps) => {
  const [query, setQuery] = useState('');
  const [avoidFirstLoad, setAvoidFirstLoad] = useState(_avoidFirstLoad);
  const qDebounced = useDebounce(query);
  const [options, setOptions] = useState<Option[]>([]);
  const ref = useRef(null);
  const api = useApi();

  const isStatic = !!staticOptions && staticOptions.length > 0;

  useEffect(() => {
    if (endpoint && !avoidFirstLoad) {
      api.fetchData(endpoint.get(endpointParams), { q: qDebounced, limit: 10 });
    }
  }, [qDebounced, endpoint]);

  useEffect(() => {
    if (!isStatic && api.response) {
      setOptions(api.response.items);
    } else if (isStatic) {
      setOptions(staticOptions);
    }
  }, [api.response, isStatic]);

  useEffect(() => {
    setAvoidFirstLoad(false);
  }, []);

  return (
    <div className="input-wrapper" ref={ref}>
      {!!label && <label className="label">{label}</label>}
      <Select
        className={classnames('multi-select', { error: !pristine && error })}
        classNamePrefix="multi"
        isDisabled={disabled}
        isLoading={api.isLoading}
        isSearchable
        isClearable={false}
        onBlur={onBlur}
        isOptionDisabled={() => (limit ? value.length >= limit : false)}
        onInputChange={value => setQuery(value)}
        onChange={value => onChange(value)}
        value={value}
        options={options}
        placeholder={null}
        menuPosition="fixed"
        menuPortalTarget={ref.current}
        isMulti
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default MultiSelect;
