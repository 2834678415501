import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionTotalTipsModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({
      totalTips: formHandler.data.totalTips.replace('$', '')
    });
  };

  useEffect(() => {
    formHandler.setFullForm({ totalTips: params.totalTips.toString() });
  }, []);

  return (
    <Base
      header="Edit total tips"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionTotalTipsModal;
