import React from 'react';
import { Button } from 'components';
import { downloadFileUrl } from 'utils/data';

interface IDownloadButton {
  value: IFile;
}

interface IFile {
  file_name: string;
  url: string;
  type: string;
}

const DownloadButton = ({ value }: IDownloadButton) => {
  return (
    <div className="download-wrapper">
      {value?.url && (
        <Button
          onClick={() => downloadFileUrl(value.url, value.type, value.file_name)}
          text="DOWNLOAD"
          variant="primary"
          tiny
        />
      )}
      <div className="download-label">{value?.file_name}</div>
    </div>
  );
};

export default DownloadButton;
