import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import DefaultTimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { TimeRangePickerProps } from 'types/dynamicInputs';

const TimeRangePicker = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  setDirtyError,
  required = true,
  pristine = true,
  disabled = false
}: TimeRangePickerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inputOpen, setInputOpen] = useState<boolean | null>(null);

  const handleChange = (value: any) => {
    onBlur && onBlur();
    onChange(value, name);
    setDirtyError!(false);
  };

  const handleInvalidChange = () => {
    setDirtyError!(true);
  };

  useEffect(() => {
    if (inputOpen === false) {
      setDirtyError!(false);
    }
  }, [inputOpen]);

  useEffect(() => {
    let observer: MutationObserver;

    if (ref && ref.current) {
      const rangePicker = ref.current.lastChild;
      observer = new MutationObserver((mutationList: MutationRecord[]) => {
        mutationList.forEach((mutation: any) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const closed = mutation.target.className.includes('--closed');
            setInputOpen(!closed);
          }
        });
      });
      observer.observe(rangePicker!, { attributes: true });
    }

    return () => {
      observer && observer.disconnect();
    };
  }, [ref]);

  return (
    <div ref={ref} className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <DefaultTimeRangePicker
        value={value}
        onChange={handleChange}
        onInvalidChange={handleInvalidChange}
        onBlur={onBlur}
        disabled={disabled}
        disableClock={true}
        rangeDivider="to"
        maxDetail="minute"
        locale="en-EU"
        required={required}
      />
      {!pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default TimeRangePicker;
