import React from 'react';
import { Copy, Link as Link2 } from 'iconsax-react';
import { useToast } from 'hooks/useToast';
import { Button } from 'components';

type Props = {
  value: string | number;
};

const Link = ({ value }: Props) => {
  const notify = useToast();

  const handleClick = async () => {
    window.open(value as string, '_blank');
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value as string);
    notify('Copied to clipboard');
  };

  return (
    <div className="link-item">
      <Button variant="primary" icon={<Link2 />} small onClick={handleClick} />
      <Button variant="secondary" icon={<Copy />} small onClick={handleCopy} />
    </div>
  );
};

export default Link;
