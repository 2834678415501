import endpoints from 'constants/endpoints';
import { ComponentList } from 'types/visualizer';

export default {
  title: 'View termination',
  endpoint: endpoints.GET_TERMINATION,
  schema: [
    { key: 'id', label: 'ID', type: ComponentList.TEXT },
    { key: 'user_id', label: 'User ID', type: ComponentList.TEXT },
    { key: 'user_name', label: 'User Name', type: ComponentList.TEXT },
    { key: 'user_email', label: 'User Email', type: ComponentList.EMAIL },
    { key: 'user_phone_number', label: 'User Phone Number', type: ComponentList.PHONE_NUMBER },
    { key: 'created_at', label: 'Created At', type: ComponentList.DATE },
    { key: 'num_warnings', label: '# Warnings', type: ComponentList.TEXT },
    { key: 'num_venue_complaint_warnings', label: '# Venue Complaint Warnings', type: ComponentList.TEXT }
  ]
};
