import React, { useEffect } from 'react';
import { Props } from 'types/modals';
import Base from '../../base';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

const PayrollFeedback = ({ closing, onClose, params }: Props) => {
  const api = useApi();
  const payrollId = String(params?.id);

  useEffect(() => {
    api.fetchData(endpoints.GET_PAYROLL_FEEDBACK.get({ id: payrollId }));
  }, []);

  return (
    <Base header="Payroll Feedback" closing={closing} onClose={onClose} size="lg">
      <div>
        {api?.response?.feedback && (
          <div className="form-wrapper">
            <div className="form-header">
              <div className="form-title">
                <span>{api?.response?.feedback.error_message}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Base>
  );
};

export default PayrollFeedback;
