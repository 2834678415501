/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const AssignTemplatePosition = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose(formHandler.data);
  };

  return (
    <Base
      header="Assign Positions"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save Changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <div>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default AssignTemplatePosition;
