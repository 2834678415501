import React from 'react';

type Props = {
  value: string | number;
};

const NotificationTrigger = ({ value }: Props) => {
  return <div>{value === 'all_employees' ? 'All Employees' : `Employees for ${value}`}</div>;
};

export default NotificationTrigger;
