import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import endpoints from 'constants/endpoints';
import { utcDate, onlyDate, removeTimeZone } from 'helpers/date';
import useFormHandler from 'hooks/useFormHandler';
import useApi from 'hooks/useApi';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditionPayrollModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);
  const api = useApi();

  const handleSubmit = () => {
    formHandler.setIsSubmitting(true);
    onClose({
      ...formHandler.data,
      periodStart: onlyDate(removeTimeZone(formHandler.data.periodStart)),
      periodEnd: onlyDate(removeTimeZone(formHandler.data.periodEnd)),
      payday: onlyDate(removeTimeZone(formHandler.data.payday)),
      approveBy: removeTimeZone(formHandler.data.approveBy),
      approvedOn: formHandler.data.approvedOn ? removeTimeZone(formHandler.data.approvedOn) : null
    });
  };

  useEffect(() => {
    api.fetchData(endpoints.GET_PAYROLL.get(params));
  }, []);

  useEffect(() => {
    if (api.loaded) {
      const data = {
        eventId: api.response.data.event_id,
        periodStart: utcDate(api.response.data.period_start),
        periodEnd: utcDate(api.response.data.period_end),
        payday: utcDate(api.response.data.payday),
        approveBy: utcDate(api.response.data.approve_by),
        approvedOn: api.response.data.approved_on ? utcDate(api.response.data.approved_on) : null,
        checkPayrollId: api.response.data.check_payroll_id
      };

      formHandler.setFullForm(data);
    }
  }, [api.loaded, formHandler.schema]);

  useEffect(() => {
    if (api.loaded) {
      formHandler.setSchema({
        ...schema,
        eventId: {
          ...schema.eventId,
          defaultLabel: api.response.data.event.name
        }
      });
    }
  }, [api.loaded]);

  return (
    <Base
      header="Edit payroll"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button text="Save changes" disabled={!formHandler.valid || formHandler.isSubmitting} onClick={handleSubmit} />
      }
    >
      <DynamicForm handler={formHandler} />
    </Base>
  );
};

export default EditionPayrollModal;
