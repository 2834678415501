import React, { useEffect } from 'react';
import classnames from 'classnames';
import useWindowSize from '@revolt-digital/use-window-size';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Building,
  Card,
  Cards,
  Calendar,
  DocumentText1,
  DollarCircle,
  Global,
  Map,
  Profile2User,
  Tag,
  UserMinus,
  UserTick,
  UserOctagon,
  CardPos,
  Medal,
  Element2,
  People,
  Notification,
  Note,
  Message2
} from 'iconsax-react';
import { TABLET_BREAKPOINT } from 'constants/breakpoints';
import { contractSideMenu } from 'store/actions/general';
import routes from 'constants/routes';
import { RootState } from 'types/store';

const groups = [
  {
    groupName: 'Accounts',
    pages: [
      { pageName: 'Accounts', path: routes.ACCOUNTS, icon: <Building color="rgb(172, 159, 255)" /> },
      { pageName: 'Venues', path: routes.VENUES, icon: <Map color="rgb(172, 159, 255)" /> },
      { pageName: 'Templates', path: routes.TEMPLATES, icon: <Element2 color="rgb(172, 159, 255)" /> },
      { pageName: 'Tags', path: routes.TAGS, icon: <Tag color="rgb(172, 159, 255)" /> }
    ]
  },
  {
    groupName: 'Events',
    pages: [{ pageName: 'Events', path: routes.EVENTS, icon: <Calendar color="rgb(172, 159, 255)" /> }]
  },
  {
    groupName: 'Users',
    pages: [
      { pageName: 'Users', path: routes.USERS, icon: <Profile2User color="rgb(172, 159, 255)" /> },
      {
        pageName: 'Employee Onboarding',
        path: routes.EMPLOYEES_ON_BOARDING,
        icon: <UserMinus color="rgb(172, 159, 255)" />
      },
      { pageName: 'Employees', path: routes.EMPLOYEES, icon: <UserTick color="rgb(172, 159, 255)" /> },
      { pageName: 'Trainings', path: routes.TRAININGS, icon: <Medal color="rgb(172, 159, 255)" /> },
      { pageName: 'Push Notifications', path: routes.NOTIFICATIONS, icon: <Notification color="rgb(172, 159, 255)" /> },
      { pageName: 'My Chats', path: routes.MY_CHATS, icon: <Message2 color="rgb(172, 159, 255)" /> }
    ]
  },
  {
    groupName: 'Accounting',
    pages: [
      { pageName: 'Earnings', path: routes.EARNINGS, icon: <DollarCircle color="rgb(172, 159, 255)" /> },
      { pageName: 'Admin Payrolls', path: routes.ADMIN_PAYROLLS, icon: <CardPos color="rgb(172, 159, 255)" /> },
      { pageName: 'Payrolls', path: routes.PAYROLLS, icon: <Cards color="rgb(172, 159, 255)" /> },
      { pageName: 'Payments', path: routes.PAYMENTS, icon: <Card color="rgb(172, 159, 255)" /> }
    ]
  },
  {
    groupName: 'Configurations',
    pages: [
      { pageName: 'Positions', path: routes.POSITIONS, icon: <UserOctagon color="rgb(172, 159, 255)" /> },
      { pageName: 'Notices', path: routes.NOTICES, icon: <DocumentText1 color="rgb(172, 159, 255)" /> },
      { pageName: 'States', path: routes.STATES, icon: <Global color="rgb(172, 159, 255)" /> }
    ]
  },
  {
    groupName: 'Human Resources',
    pages: [
      { pageName: 'Applications', path: routes.APPLICATIONS, icon: <Note color="rgb(172, 159, 255)" /> },
      { pageName: 'Human Resources', path: routes.HUMAN_RESOURCES, icon: <People color="rgb(172, 159, 255)" /> }
    ]
  }
];

const SideMenu = () => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mobileSideMenuOpen } = useSelector((state: RootState) => state.general);

  const handleCloseSideMenu = () => {
    dispatch(contractSideMenu());
  };

  const handleNavigate = (path: string) => () => {
    navigate(path);
    dispatch(contractSideMenu());
  };

  useEffect(() => {
    if (mobileSideMenuOpen && windowSize.width > TABLET_BREAKPOINT) {
      dispatch(contractSideMenu());
    }
  }, [windowSize.width]);

  return (
    <>
      <div
        className={classnames('side-menu', {
          open: mobileSideMenuOpen
        })}
      >
        {groups.map(({ pages }, i) => (
          <React.Fragment key={i}>
            {pages.map(({ icon, pageName, path }, j) => (
              <div
                key={j}
                onClick={handleNavigate(path)}
                className={classnames('link', { selected: path === pathname })}
              >
                {icon}
                <div className="body-2">{pageName}</div>
              </div>
            ))}
            <hr />
          </React.Fragment>
        ))}
      </div>
      <div className="side-menu-backdrop" onClick={handleCloseSideMenu} />
    </>
  );
};

export default SideMenu;
