import React, { ChangeEvent, useRef } from 'react';
import classnames from 'classnames';
import Button from 'components/common/button';
import { CloseCircle } from 'iconsax-react';
import useApi from 'hooks/useApi';
import endpoints from 'constants/endpoints';

interface TrainingInputProps {
  label?: string;
  value?: any;
  name: string;
  onChange: (file: File | File[]) => void;
  onBlur?: () => void;
  error?: string;
  required?: boolean;
  pristine?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  extension?: string;
}

const TrainingInputFile = ({
  value,
  label,
  name,
  onChange,
  onBlur,
  error,
  required = false,
  pristine = true,
  disabled = false,
  extension
}: TrainingInputProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const apiFile = useApi();
  const apiS3 = useApi();

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }

    const fileList = Array.from(files);
    uploadDocsAndGenerateFileObjects(fileList).then(fileObjects => {
      onChange([...value, ...fileObjects]);
    });
  };

  const handleRemove = (index: number) => {
    onChange(
      value.filter((_: any, i: number) => {
        return i !== index;
      })
    );

    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
  };

  async function uploadDocsAndGenerateFileObjects(files: File[]) {
    const fileObjects = [];

    for (const file of files) {
      try {
        const signedUrl = await apiFile.fetchData(endpoints.FILE_TRAINING.get(), { fileName: file.name });
        await apiS3.uploadFileToS3(file, signedUrl.extension, signedUrl.url);

        const fileObject = {
          file_name: signedUrl.fileName,
          extension: signedUrl.extension,
          url: signedUrl.url
        };

        fileObjects.push(fileObject);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    return fileObjects;
  }

  return (
    <div className={classnames('input-wrapper', { error: !pristine && error })}>
      {!!label && (
        <label className="label">
          {label}
          {required ? ' *' : ''}
        </label>
      )}
      <div className="training-input-file">
        <Button
          text={apiFile.isLoading || apiS3.isLoading ? 'Uploading...' : 'Choose File'}
          onClick={handleClick}
          small
          disabled={apiFile.isLoading || apiS3.isLoading}
        />
        <input
          ref={hiddenFileInput}
          type="file"
          name={name}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          multiple
          accept={extension}
        />
      </div>
      {value.length > 0 && (
        <div className="file-name-container">
          {value.map((file: any, index: number) => (
            <div key={index} className="file-name">
              <span className="body-2"> - {file.file_name}</span>
              <CloseCircle className="close-icon" size={20} onClick={() => handleRemove(index)} />
            </div>
          ))}
        </div>
      )}
      {pristine && !!error && <span className="error-message body-2">{error}</span>}
    </div>
  );
};

export default TrainingInputFile;
