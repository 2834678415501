/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Button, DynamicForm } from 'components';
import useFormHandler from 'hooks/useFormHandler';
import { Props } from 'types/modals';
import Base from '../../base';
import schema from './schema';

const EditTemplateDetailsModal = ({ closing, onClose, params }: Props) => {
  const formHandler = useFormHandler(schema);

  useEffect(() => {
    formHandler.setFullForm({
      name: params.name,
      venue: params.venue.id
    });
  }, [formHandler.schema]);

  useEffect(() => {
    formHandler.setSchema({
      ...schema,
      venue: {
        ...schema.venue,
        defaultLabel: params.venue.name
      }
    });
  }, []);

  return (
    <Base
      header="Edit details"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <Button
          text="Save changes"
          disabled={!formHandler.valid || formHandler.isSubmitting}
          onClick={() => {
            formHandler.setIsSubmitting(true);
            onClose(formHandler.data);
          }}
        />
      }
    >
      <div>
        <DynamicForm handler={formHandler} />
      </div>
    </Base>
  );
};

export default EditTemplateDetailsModal;
