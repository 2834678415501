import React from 'react';
import { Button } from 'components';
import { Props } from 'types/modals';
import Base from '../../base';

const DisableApplications = ({ closing, onClose }: Props) => {
  return (
    <Base
      header="Disable Applications"
      closing={closing}
      onClose={onClose}
      size="md"
      footer={
        <>
          <Button text="Disable" onClick={() => onClose(true)} />
          <Button text="Cancel" variant="secondary" onClick={() => onClose(false)} />
        </>
      }
    >
      <div className="reject-application-modal">
        <p className="title body strong">Would you like to disable Staff Application System?</p>
      </div>
    </Base>
  );
};

export default DisableApplications;
